import { Component } from 'react';

export const DocumentInfo = {
    'Company': 'DEFAULT COMPANY',
    'AIG': 'DEFAULT COMPANY',
    'CreatedBy': 'Mrs.',
    'CreatedDate': '19/10/2020 11:05:59'}





class packageItemServiceMock extends Component {
   
   constructor(props){
    super(props);
    this.Companies = {
        "caption" : "Company:",
        "placeholder": "Select Company...",
        "displayExpr": "Code",
        "valueExpr": "ID",
        "defaultValue": 5,
        "dataSource" : [
            {
                "CO_ID":5,                   
                "CO_Code":"R01",                
                "CO_Name":"DEFAULT COMPANY",    
                "CO_AIG": "DEFAULT COMPANY"
            },
            {
                "CO_ID":7,
                "CO_Code":"R02",
                "CO_Name":"YAN COMPANY 2",
                "CO_AIG": "YAN COMPANY 2"
            },   
            {
                "CO_ID":8,
                "CO_Code":"SHANQA3",
                "CO_Name":"SHANTHI QA BIZ 3",
                "CO_AIG": "SHANTHI QA BIZ 3"
            },                           
            {
                "CO_ID":9,
                "CO_Code":"RE001",
                "CO_Name":"BA Trading Sdn Bhd",
                "CO_AIG": "BA Trading Sdn Bhd"
            }
        ]
    }
    this.PackageItems = {
        "total": 100,
        "dataSource": [
            {
                "PI_ParentItemID": 98580 ,
                "IM_Code": "000007",
                "IM_Description":"Package Item 中文",
                "IM_Description2":"",
                "IC_Code":"General",
                "IG_Code":"PACKAGE"
            },
            {
                "PI_ParentItemID": 98656,
                "IM_Code": "000012",
                "IM_Description":"Package Item 1",
                "IM_Description2":"",
                "IC_Code":"",
                "IG_Code":"PACKAGE"
            },
            {
                "PI_ParentItemID": 98697,
                "IM_Code": "000020",
                "IM_Description":"Package Item (Serial and Stock)",
                "IM_Description2":"",
                "IC_Code":"",
                "IG_Code":"PACKAGE"
            },
            {
                "PI_ParentItemID": 100225,
                "IM_Code": "000071",
                "IM_Description":"Package Item (Selialize Item)",
                "IM_Description2":"",
                "IC_Code":"",
                "IG_Code":"PACKAGE"
            },
            {
                "PI_ParentItemID": 99503,
                "IM_Code": "000057",
                "IM_Description":"Package Item 2",
                "IM_Description2":"",
                "IC_Code":"",
                "IG_Code":"OTHERS"
            },
            {
                "PI_ParentItemID": 100476,
                "IM_Code": "000078",
                "IM_Description":"Package Batch Item",
                "IM_Description2":"",
                "IC_Code":"",
                "IG_Code":"BATCH"
            }
        ] 
    }
   
    this.PackageParentItem = {
        "PI_ParentItemID": 98580 ,
        "IM_Code": "000007",
        "IM_Description":"Package Item",
    }

    this.PackageChildItems2 = {
        "total": 20,
        "dataSource": [
            {
                "PI_ID": "1081",
                "IM_Code": "000010",
                "IM_Description": "Item C",
                "PI_Qty": "1.000000",
                "PI_SalesPrice1": "53.000000",
                "PI_SalesPrice2": "53.000000",
                "PI_SalesPrice3": "53.000000",
                "PI_SalesPrice4": "53.000000",
                "PI_SalesPrice5": "53.000000",
                "PI_SalesPrice6": "53.000000",
                "PI_PurchasePrice": "0.000000",
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":2
            },
            
            {
                "PI_ID": "1080",
                "IM_Code": "000010",
                "IM_Description": "Item Z",
                "PI_Qty": "1.000000",
                "PI_SalesPrice1": "53.000000",
                "PI_SalesPrice2": "53.000000",
                "PI_SalesPrice3": "53.000000",
                "PI_SalesPrice4": "53.000000",
                "PI_SalesPrice5": "53.000000",
                "PI_SalesPrice6": "53.000000",
                "PI_PurchasePrice": "0.000000",
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":2
            },

            {
                "PI_ID": "1077",
                "IM_Code": "000016",
                "IM_Description": "Item ABC",
                "PI_Qty": "1.000000",
                "PI_SalesPrice1": "53.000000",
                "PI_SalesPrice2": "53.000000",
                "PI_SalesPrice3": "53.000000",
                "PI_SalesPrice4": "53.000000",
                "PI_SalesPrice5": "53.000000",
                "PI_SalesPrice6": "53.000000",
                "PI_PurchasePrice": "0.000000",
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":2
            }
        ]
    }
   
    this.PackageChildItems = {
        "total": 100,
        "dataSource": [ 
            {
                "PI_ID": "551",
                "IM_Code": "000002",
                "IM_Description": "Item A",
                "PI_Qty": 2.000000,
                "PI_SalesPrice1": 53.330000,
                "PI_SalesPrice2": 53.000000,
                "PI_SalesPrice3": 53.000000,
                "PI_SalesPrice4": 53.000000,
                "PI_SalesPrice5": 53.000000,
                "PI_SalesPrice6": 53.000000,
                "PI_PurchasePrice": 1.020000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":1
            },
            {
                "PI_ID": "562",
                "IM_Code": "000004",
                "IM_Description": "Item C",
                "PI_Qty": 2.000000,
                "PI_SalesPrice1": 53.000000,
                "PI_SalesPrice2": 53.000000,
                "PI_SalesPrice3": 53.000000,
                "PI_SalesPrice4": 53.000000,
                "PI_SalesPrice5": 53.000000,
                "PI_SalesPrice6": 53.000000,
                "PI_PurchasePrice": 5.010000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":2
            },
            {
                "PI_ID": "572",
                "IM_Code": "000003",
                "IM_Description": "Item B",
                "PI_Qty": 1.000000,
                "PI_SalesPrice1": 53.000000,
                "PI_SalesPrice2": 53.000000,
                "PI_SalesPrice3": 53.000000,
                "PI_SalesPrice4": 53.000000,
                "PI_SalesPrice5": 53.000000,
                "PI_SalesPrice6": 53.000000,
                "PI_PurchasePrice": 2.330000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":3
            },
            {
                "PI_ID": "661",
                "IM_Code": "000027",
                "IM_Description": "SERIALIZE ITEM TEST A",
                "PI_Qty": 2.000000,
                "PI_SalesPrice1": 2.000000,
                "PI_SalesPrice2": 2.000000,
                "PI_SalesPrice3": 0.000000,
                "PI_SalesPrice4": 3.000000,
                "PI_SalesPrice5": 3.000000,
                "PI_SalesPrice6": 3.000000,
                "PI_PurchasePrice": 1.330000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":4
            },
            {
                "PI_ID": "1034",
                "IM_Code": "000030",
                "IM_Description": "Batch Item Y",
                "PI_Qty": 2.000000,
                "PI_SalesPrice1": 10.000000,
                "PI_SalesPrice2": 10.000000,
                "PI_SalesPrice3": 10.000000,
                "PI_SalesPrice4": 10.000000,
                "PI_SalesPrice5": 10.000000,
                "PI_SalesPrice6": 10.000000,
                "PI_PurchasePrice": 2.330000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":5
            },
            {
                "PI_ID": "1035",
                "IM_Code": "000005",
                "IM_Description": "BATCH ITEM",
                "PI_Qty": 2.000000,
                "PI_SalesPrice1": 60.000000,
                "PI_SalesPrice2": 100.000000,
                "PI_SalesPrice3": 100.000000,
                "PI_SalesPrice4": 100.000000,
                "PI_SalesPrice5": 100.000000,
                "PI_SalesPrice6": 100.000000,
                "PI_PurchasePrice": 5.010000,
                "PI_ModifierNonRepeat_YN": "0",
                "PI_Sequence":6
            }
        ]
    }
    
   }
   
}

export default packageItemServiceMock;
  
