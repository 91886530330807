import React, { PureComponent } from 'react'
import { Column, ColumnChooser, DataGrid, FilterRow, Grouping, GroupPanel, LoadPanel, Pager, Paging, RowDragging, Scrolling, SearchPanel, Selection, StateStoring } from 'devextreme-react/data-grid'
import { DefaultStateStoringIgnore } from '../../utils/default-data-grid-settings';
import { ChildShowFilterRow } from '../';
import { PostUserLayout } from '../../api/user-preferred-layout';
import { CompanyID, UserID, ClientID, Token } from '../../utils/default-cookies';
import loadingBar from '../image/loadingBar.gif';
import Mousetrap from 'mousetrap';
import dxDataGrid from "devextreme/ui/data_grid";

export default class DataGridDefault extends PureComponent {
    constructor(props) {
        super(props);

        this.childShowFilterRowRef = React.createRef();

        this.state = {
            selectedRowKeys: [],
            showFilterRow: false,
            showGroupPanel: false,
            showPageSizeSelector: false,
        };

        this.moduleItemID = null;

        this.focusedRowUp = this.focusedRowUp.bind(this);
        this.focusedRowDown = this.focusedRowDown.bind(this);

        this.addMenuItems = this.addMenuItems.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        this.onReorder = this.onReorder.bind(this);
        this.onFocusedCellChanging = this.onFocusedCellChanging.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onRowDblClick = this.onRowDblClick.bind(this);

        this.loadLayout = this.loadLayout.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.restoreLayout = this.restoreLayout.bind(this);

        this.toggleSelectionBox = this.toggleSelectionBox.bind(this);

        this.handleShowFilterRow = this.handleShowFilterRow.bind(this);
        this.handleShowGroupPanel = this.handleShowGroupPanel.bind(this);

        this.initDataGrid();
        this.initMouseTrap();
    }

    componentDidMount() {
        Mousetrap.bind("up", this.focusedRowUp);
        Mousetrap.bind("down", this.focusedRowDown);
    }

    componentWillUnmount() {
        Mousetrap.unbind("up");
        Mousetrap.unbind("down");
    }

    componentDidUpdate(prevProps) {
        if (prevProps.preferedLayout !== this.props.preferedLayout) {
            // load user preferred layout after props change
            this.dataGrid.state(this.loadLayout());
        }
    }

    initDataGrid() {
        // Set default allowEditing to false
        dxDataGrid.defaultOptions({
            options: {
                commonColumnSettings: {
                    allowEditing: false
                },
                navigateToFirst: false, // custom option to trigger navigation (refer code at onContentReady)
                navigateToLast: false, // custom option to trigger navigation (refer code at onContentReady)
            }
        })
    }

    initMouseTrap() {
        Mousetrap.prototype.stopCallback = function (event, element, combo) {
            // This is only for event triggered outside of DevExtreme component
            // Event triggered in DevExtreme component will be captured within and can only be overriden with their API (onKeydown/onRowDblClick/etc)

            // Keys Region
            const denyDeleteWithValue = 'del'; // deny callback for delete key when element has value
            const proxyAlt = 'alt'; // allow callback if combo starts with alt
            const proxyCombo = ['up', 'down', 'ins', 'del']; // allow callback for up, down, ins key
            const proxyClass = ' mousetrap '; // allow callback if elements have "mousetrap" className
            const denyRole = ['menuitem', 'menubar', 'combobox'] // deny callback for all elements with "denyRole" role attribute
            const denyTag = ['INPUT', 'SELECT', 'TEXTAREA'] // deny callback for all elements with "denyTag" tag
            const denyEditable = element.contentEditable && element.contentEditable === 'true'; // deny callback for editable elements

            // Logic Region
            if (element.value && !element.readOnly && combo === denyDeleteWithValue) return true;
            if (combo.split('+')[0] === proxyAlt) return false;
            if (proxyCombo.indexOf(combo) > -1) return false;
            if ((` ${element.className} `).indexOf(proxyClass) > -1) return false;
            if (denyRole.indexOf(element.getAttribute("role")) > -1) return true;
            if (denyTag.indexOf(element.tagName) > -1 && !element.readOnly) return true;
            if (denyEditable) return true;

            return false;
        }
    }

    get dataGrid() {
        // `current.instance` points to the UI component instance
        return this.props.dataGridRef.current.instance;
    }

    dataGridNullCheck() {
        return this.props.dataGridRef.current != null;
    }

    focusedRowUp() {
        const newFocusRowIndex = this.dataGrid.option('focusedRowIndex') - 1;
        const newRowElement = this.dataGrid.getRowElement(newFocusRowIndex);

        if (newFocusRowIndex > -1 && newRowElement) {
            this.dataGrid.option('focusedRowIndex', newFocusRowIndex);

            if (newFocusRowIndex === 0) {
                // scroll to top if first row
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'end', inline: 'nearest' });
            } else {
                // scroll nearest
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
            }
        }
    }

    focusedRowDown() {
        const newFocusRowIndex = this.dataGrid.option('focusedRowIndex') + 1;
        const newRowElement = this.dataGrid.getRowElement(newFocusRowIndex);

        if (newFocusRowIndex < this.dataGrid.getVisibleRows().length && newRowElement) {
            this.dataGrid.option('focusedRowIndex', newFocusRowIndex);

            if (newFocusRowIndex === this.dataGrid.getVisibleRows().length - 1) {
                // scroll to bottom if last row
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
            } else {
                // scroll nearest
                newRowElement[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
            }
        }
    }

    addMenuItems(event) {
        this.childShowFilterRowRef.current.DataGridContextMenuItemsService(event, this.dataGrid);
    }

    onContentReady(event) {
        if (this.dataGrid.option("navigateToFirst")) {
            // Function: navigate to first row first page when repaint is triggered
            // Invoke: this.dataGrid.option('navigateToFirst', true)
            // Note, may need to manually call "this.datagrid.repaint()" after invoking if no repaint is triggered

            this.dataGrid.option("navigateToFirst", false);

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(0));
            event.component.option("focusedRowIndex", 0);
            event.component.pageIndex(0);
        }

        if (this.dataGrid.option("navigateToLast")) {
            // Function: navigate to last row last page when repaint is triggered
            // Invoke: this.dataGrid.option('navigateToLast', true)
            // Note, may need to manually call "this.datagrid.repaint()" after invoking if no repaint is triggered

            this.dataGrid.option("navigateToLast", false);

            const lastRowIndex = this.dataGrid.pageSize() - 1;
            const lastPage = this.dataGrid.pageCount() - 1;

            event.component.option("focusedRowKey", event.component.getKeyByRowIndex(lastRowIndex));
            event.component.option('focusedRowIndex', lastRowIndex);
            event.component.pageIndex(lastPage);
        }

        this.setState({
            showPageSizeSelector: event.component.totalCount() > event.component.pageSize()
        })

        this.props.onContentReady && this.props.onContentReady(event);
    }

    onReorder(event) {
        const visibleRows = event.component.getVisibleRows();
        const dataSource = [...this.props.dataSource];
        const toIndex = dataSource.indexOf(visibleRows[event.toIndex].data);
        const fromIndex = dataSource.indexOf(event.itemData);
        const sequenceKey = this.props.customRowDragging.sequenceKey;

        dataSource.splice(fromIndex, 1);
        dataSource.splice(toIndex, 0, event.itemData);

        for (var i = 0; i < dataSource.length; i++) {
            dataSource[i][sequenceKey] = i + 1;
        }

        this.props.customRowDragging.updateDataSource(dataSource);

        this.props.onReorder && this.props.onReorder(event);
    }

    onFocusedCellChanging(event) {
        if (event.event) {
            // Prevent highlight changing onto non-editable cell
            if (event.event.pointerType) {
                event.isHighlighted = false;
            } else if (event.columns[event.prevColumnIndex] && event.columns[event.prevColumnIndex].allowEditing) {
                if (!event.columns[event.newColumnIndex].allowEditing) {
                    event.newColumnIndex = event.prevColumnIndex;
                }
            } else {
                event.isHighlighted = false;
                event.newColumnIndex = event.prevColumnIndex;
            }
        }

        this.props.onFocusedCellChanging && this.props.onFocusedCellChanging(event);
    }

    onOptionChanged(event) {
        if (event.fullName.includes("sortOrder") || event.fullName.includes("filterValue")) {
            this.dataGrid.option("navigateToFirst", true);
        }

        if (event.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: event.value
            });
        }

        this.props.onOptionChanged && this.props.onOptionChanged(event);
    }

    onRowDblClick(event) {
        if (this.props.customSelectionBox) {
            this.toggleSelectionBox(event);
        }

        this.props.onRowDblClick && this.props.onRowDblClick(event);
    }

    loadLayout() {
        //load datagrid custom layout state
        if (this.props.preferedLayout && this.props.preferedLayout.length > 0) {
            this.moduleItemID = this.props.preferedLayout[0].UPL_ModuleItemID;

            if (this.dataGridNullCheck() && this.props.preferedLayout[0].UPL_Layout) {
                let userlayout = JSON.parse(this.props.preferedLayout[0].UPL_Layout);

                if (typeof userlayout !== 'object') return;

                userlayout['pageIndex'] = 0;
                delete userlayout['focusedRowKey'];
                return userlayout;
            }
        }
    }

    saveLayout(state) {
        //save datagrid custom layout state 
        if (!this.props.allowSaveGridLayout) return;

        if (this.moduleItemID) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), this.moduleItemID, this.props.defaultSMI.controlID, this.props.defaultSMI.moduleURL, "storageKey", state);
        }
    }

    restoreLayout() {
        //data-grid-context-menu to restore datagrid default layout 
        if (this.moduleItemID) {
            PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), this.moduleItemID, this.props.defaultSMI.controlID, this.props.defaultSMI.moduleURL, "storageKey", " ");
        }

        if (this.dataGridNullCheck()) {
            this.dataGrid.state("");
        }
    }

    toggleSelectionBox(event) {
        if (event.rowType === "data") {
            if (!event.isSelected) {
                this.setState(prevState => ({
                    selectedRowKeys: [...prevState.selectedRowKeys, event.data[this.props.keyExpr]]
                }));
            }
            else {
                let selectedRowKeys = [...this.state.selectedRowKeys];
                selectedRowKeys.forEach((item) => {
                    const index = selectedRowKeys.indexOf(item);
                    if (event.data[this.props.keyExpr] === item) {
                        selectedRowKeys.splice(index, 1);
                    }
                });
                this.setState({
                    selectedRowKeys: selectedRowKeys
                });
            }
        }
    }

    handleShowFilterRow() {
        // set child - data-grid-context-menu to show filter row
        this.setState(prevState => ({
            showFilterRow: !prevState.showFilterRow
        }));
        this.handleClearFilter();
    }

    handleShowGroupPanel() {
        // set child - data-grid-context-menu to show group panel
        this.setState(prevState => ({
            showGroupPanel: !prevState.showGroupPanel
        }));
    }

    handleClearFilter() {
        this.dataGrid.clearFilter();
    }

    render() {
        return (
            <>
                <DataGrid
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    className={this.props.className}
                    columnAutoWidth={true}
                    columnHidingEnabled={false}
                    columnResizingMode={"widget"}
                    dataSource={this.props.dataSource}
                    defaultFocusedRowIndex={0}
                    focusedRowEnabled={this.props.focusedRowEnabled}
                    id={this.props.defaultSMI.controlID}
                    keyExpr={this.props.keyExpr}
                    noDataText={this.props.noDataText}
                    onCellPrepared={this.props.onCellPrepared}
                    onContextMenuPreparing={this.addMenuItems}
                    onContentReady={this.onContentReady}
                    onEditingStart={this.props.onEditingStart}
                    onFocusedCellChanging={this.onFocusedCellChanging}
                    onKeyDown={this.props.onKeyDown}
                    onOptionChanged={this.onOptionChanged}
                    onRowDblClick={this.onRowDblClick}
                    ref={this.props.dataGridRef}
                    rowAlternationEnabled={true}
                    selectedRowKeys={this.state.selectedRowKeys}
                    showBorders={true}
                    showRowLines={true}
                >
                    <ColumnChooser enabled={false} mode="select" />
                    <FilterRow visible={this.state.showFilterRow} />
                    <Grouping contextMenuEnabled={true} />
                    <GroupPanel visible={this.state.showGroupPanel} />
                    <LoadPanel indicatorSrc={loadingBar} />
                    <SearchPanel visible={false} text={""} />
                    <Paging defaultPageSize={this.props.defaultPageSize} />
                    <Pager
                        showPageSizeSelector={this.state.showPageSizeSelector}
                        allowedPageSizes={this.props.allowedPageSizes}
                        defaultPageIndex={0}
                        showNavigationButtons={true}
                        showInfo={true}
                    />
                    <Scrolling
                        mode="standard"
                        useNative={false}
                        scrollByContent={true}
                        scrollByThumb={true}
                        showScrollbar="always"
                    />
                    <StateStoring
                        enabled={true}
                        type="custom"
                        customSave={this.saveLayout}
                        ignoreColumnOptionNames={DefaultStateStoringIgnore}
                        savingTimeout={1000} //default is 2000  
                    />
                    {
                        this.props.customRowDragging &&
                        <RowDragging
                            allowReordering={true}
                            onReorder={this.onReorder}
                        />
                    }
                    {
                        this.props.customSelectionBox &&
                        <Selection
                            mode={"multiple"}
                            allowSelectAll={true}
                            showCheckBoxesMode={'always'}
                        />
                    }
                    {
                        this.props.customSelectionBox &&
                        <Column
                            type="selection"
                            fixed={true}
                            fixedPosition={'left'}
                            showInColumnChooser={false}
                        />
                    }
                    {this.props.children}
                </DataGrid>
                <ChildShowFilterRow
                    displayColumnChooser={this.props.allowDisplayColumnChooser}
                    displayExportGrid={this.props.allowExportGrid}
                    displayRestoreLayout={this.props.allowRestoreLayout}
                    ref={this.childShowFilterRowRef}
                    showRestoreMsg={this.props.showMsgHandler}
                    getChildFilterRow={this.handleShowFilterRow}
                    getChildGroupPanel={this.handleShowGroupPanel}
                    restoreLayout={this.restoreLayout}
                />
            </>
        )
    }
}