import React, { Component } from 'react'
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

export const deleteBtn = ({ onClick, enabled }) => function (props) {
    return (
        <DeleteBtn
            {...props}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class DeleteBtn extends Component {
    render() {
        return (
            <div>
                {
                    this.props.enabled ?
                        (
                            <div data-tip={formatMessage("Delete[Delete]")} data-for="delete" onClick={this.props.onClick} className="icon-delete-icon">
                                <ReactTooltip id="delete" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
                            </div>
                        )
                        :
                        (
                            <div className="disable-delete">
                                <div data-tip={formatMessage("Delete[Delete]")} data-for="delete" className="icon-delete-icon">
                                    <ReactTooltip id="delete" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}
