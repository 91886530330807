import React from "react";
import TextArea from "devextreme-react/text-area";
import PropTypes from "prop-types";
import "./text-area.scss";

export default function DefaultTextArea(props) {
  const {
    displayStar,
    caption,
    displayValue,
    value,
    disabled,
    placeholder,
    onValueChanged,
    stylingMode = "outlined",
    maxLength,
    direction = "vertical",
    labelWidth,
    isResetStyle,
    className,
    labelClassName,
    hideColon,
    ...rest
  } = props;

  const resetStyle = React.useMemo(
    () => (isResetStyle ? "reset " : ""),
    [isResetStyle]
  );

  const ta_onChangeHandler = (event) => {
    onValueChanged?.(event.value);
  };

  return (
    <div className={`text-area-container ${className ?? ""} ${direction}`}>
      <div
        className={`${resetStyle}dx-fieldset-text`}
        style={{ width: labelWidth }}
      >
        <div className="dx-field">
          <div className="dx-field-label">
            <span className={["label", labelClassName].join(" ")}>
              {caption}
            </span>
            <>
              {displayStar ? <span style={{ color: "red" }}> * </span> : ""}
              {!hideColon && ":"}
            </>
            {displayValue === "" ? (
              <span className="required-field">Required</span>
            ) : null}
          </div>
        </div>
      </div>
      <div className={`${resetStyle}dx-fieldset field`}>
        <div className="dx-field">
          <div className="dx-field-value">
            <TextArea
              {...rest}
              className="text-area"
              value={value}
              readOnly={disabled}
              placeholder={placeholder}
              onValueChanged={ta_onChangeHandler}
              stylingMode={stylingMode}
              autoResizeEnabled
              // minHeight={100}
              // maxHeight={100}
              maxLength={maxLength}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultTextArea.propTypes = {
  displayStar: PropTypes.bool,
  caption: PropTypes.string,
  displayValue: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  stylingMode: PropTypes.oneOf(["outlined", "underlined", "filled"]),
  onValueChanged: PropTypes.func,
  maxLength: PropTypes.number,
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  labelWidth: PropTypes.number,
  isResetStyle: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  hideColon: PropTypes.bool,
};
