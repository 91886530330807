import React, { useState } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'rsuite';
import "rsuite/dist/styles/rsuite-default.css";
import "./calendar-selection-dropdown.scss";

// const { combine, allowedMaxDays, afterToday } = DateRangePicker;
const CalenderSelectionDropdown = (props) => {
  
  const getDate = (date) => {
    var day  = ("0" + (date.getDate())).slice(-2);
    return day;
  }

  var getMonth = (date) => {
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    return month;
  }

  const last7Days = new Date();
  last7Days.setDate(last7Days.getDate() - 7);

  const [startDate, setStartDate] = useState(getDate(last7Days));
  const [startMonth, setStartMonth] = useState(getMonth(last7Days));
  const [startYear, setStartYear] = useState(last7Days.getFullYear());

  const [endDate, setEndDate] =  useState(getDate(new Date()));
  const [endMonth, setEndMonth] = useState(getMonth(new Date()));
  const [endYear, setEndYear] = useState(new Date().getFullYear());

  async function onChange(event){

    var fromDate = new Date(event[0]);
    var toDate = new Date(event[1])

    document.getElementById('fromDateField').value = getDate(fromDate); 
    document.getElementById('fromMonthField').value = getMonth(fromDate);
    document.getElementById('fromYearField').value = fromDate.getFullYear();

    document.getElementById('toDateField').value = getDate(toDate); 
    document.getElementById('toMonthField').value = getMonth(toDate); 
    document.getElementById('toYearField').value = toDate.getFullYear();

    updateDate()
  }

  function updateDate(){
    var typeFromDate = document.getElementById('fromYearField').value + '-' +
                      document.getElementById('fromMonthField').value + '-' +
                      document.getElementById('fromDateField').value + "T00:00:00";

    var typeToDate = document.getElementById('toYearField').value + '-' +
                   document.getElementById('toMonthField').value + '-' +
                   document.getElementById('toDateField').value + "T23:59:00";

    setStartDate(document.getElementById('fromDateField').value);
    setStartMonth(document.getElementById('fromMonthField').value);
    setStartYear(document.getElementById('fromDateField').value);

    setEndDate(document.getElementById('toDateField').value);
    setEndMonth(document.getElementById('toMonthField').value);
    setEndYear(document.getElementById('toYearField').value);

    props.parentCallback(typeFromDate, typeToDate);
  }

  const re = /^[0-9\b]+$/;

  function onInputDate(e){

    if (e.target.value === '' || re.test(e.target.value)) {

      if(document.getElementById('fromDateField').value > 31){
        document.getElementById('fromDateField').value = '31';       
      } 

      if(document.getElementById('toDateField').value > 31){
        document.getElementById('toDateField').value = '31';       
      } 
    }
    else{
      document.getElementById('fromDateField').value = '';   
      document.getElementById('toDateField').value = '';        
    }
  }

  function onInputMonth(e){
    if (e.target.value === '' || re.test(e.target.value)) {

      if(document.getElementById('fromMonthField').value > 12){
        document.getElementById('fromMonthField').value = '12'; 
      }

      if(document.getElementById('toMonthField').value > 12){
        document.getElementById('toMonthField').value = '12'; 
      }
    }
    else{
      document.getElementById('fromMonthField').value = '';
      document.getElementById('toMonthField').value = '' 
    }
  }

  function onInputYear(e){
    if (e.target.value === '' || re.test(e.target.value)) {

      if(document.getElementById('fromYearField').value > 9999){
        document.getElementById('fromYearField').value = '2021'; 
      }

      if(document.getElementById('toYearField').value > 9999){
        document.getElementById('toYearField').value = '2021'; 
      }
    }
    else{
      document.getElementById('fromYearField').value = '';
      document.getElementById('toYearField').value = '' 
    }
  }
  
  return (
      <div>
        <div className="dx-fieldset-text">
          <div className="dx-field">
            <div className="dx-field-label">
              <span style={{color: 'red'}}>* </span> 
              <span>{props.caption}</span>
              {startDate == '' && startMonth == '' && startYear == '' && endDate == '' && endMonth == '' && endYear == '' ? <span className="required-field">Required</span>: null }
            </div>
          </div>
        </div>  
        
        <div className="dx-fieldset" id="calendar-splitter-indicator">
          <div className="dx-field">
            <div className="dx-field-value" >
              <div className="input-date-wrapper">
                <div id="fromColumn">
                
                    {props.dateFormat == '{dd}/{MM}/{yyyy}' ? 
                      <span>
                        <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                        <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth}/>/</span>
                        <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} /></span>
                      </span>
                    
                    : props.dateFormat == '{dd}/{yyyy}/{MM}' ?
                      <span>
                        <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                        <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>
                        <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth}/></span>
                      </span>

                    : props.dateFormat == '{MM}/{dd}/{yyyy}' ? 
                    <span>
                      <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span>
                      <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                      <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} /></span>                    
                    </span> 

                    : props.dateFormat == '{MM}/{yyyy}/{dd}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span>
                      <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>   
                      <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} /></span>                 
                    </span> 
                    
                    :props.dateFormat == '{yyyy}/{dd}/{MM}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>   
                      <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>      
                      <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth} /></span>    
                    </span> 

                    : props.dateFormat == '{yyyy}/{MM}/{dd}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={startYear} id="fromYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>  
                      <span><input type="text" className="input-date" defaultValue={startMonth} id="fromMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span> 
                      <span><input type="text" className="input-date" defaultValue={startDate} id="fromDateField" onChange={updateDate} onKeyUp={onInputDate} /></span>                 
                    </span>: "" }

                </div>

                <div id="toColumn">
                  <div className="input-date-separator">-</div>

                    {props.dateFormat == '{dd}/{MM}/{yyyy}' ? 
                      <span>
                        <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                        <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth}/>/</span>
                        <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} /></span>
                      </span>
                    
                    : props.dateFormat == '{dd}/{yyyy}/{MM}' ?
                      <span>
                        <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                        <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>
                        <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth}/></span>
                      </span>

                    : props.dateFormat == '{MM}/{dd}/{yyyy}' ? 
                    <span>
                      <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span>
                      <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>
                      <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} /></span>                    
                    </span> 

                    : props.dateFormat == '{MM}/{yyyy}/{dd}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span>
                      <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>   
                      <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} /></span>                 
                    </span> 
                    
                    :props.dateFormat == '{yyyy}/{dd}/{MM}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>   
                      <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} />/</span>      
                      <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth} /></span>    
                    </span> 

                    : props.dateFormat == '{yyyy}/{MM}/{dd}' ?
                    <span>
                      <span><input type="text" className="input-date" defaultValue={endYear} id="toYearField" onChange={updateDate} onKeyUp={onInputYear} />/</span>  
                      <span><input type="text" className="input-date" defaultValue={endMonth} id="toMonthField" onChange={updateDate} onKeyUp={onInputMonth} />/</span> 
                      <span><input type="text" className="input-date" defaultValue={endDate} id="toDateField" onChange={updateDate} onKeyUp={onInputDate} /></span>                 
                    </span>: "" }
                    
                  </div>
              </div>

              <div className="calendar-icon-separator"/>
             
              <DateRangePicker 
                  className="calendar-wrapper"
                  onChange={onChange}
                  //default Value = 7 days
                  //remove x button
                  //allow user to type date
                  ranges={[
                  {
                    label: 'Today',
                    value: [new Date(), new Date()]
                  }, {
                    label: 'This Month',
                    value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
                  }, {
                    label: 'Previous Month',
                    value: [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()]
                  }, {
                    label: 'Last 30 Days',
                    value: [ moment().subtract(30, 'days').toDate(), moment().subtract(1, 'days').toDate()]
                  }]}
                />
            </div>
          </div>
        </div>
      </div>
  )
}

export default CalenderSelectionDropdown;