import Popup from "devextreme-react/popup";
import React from "react";
import { useController, useForm } from "react-hook-form";
import { Form } from "./app-editor/form";
import Button from "./app-editor/components/button/button";
import DefaultTextBox from "../../components/text-box/text-box";
import { SaveUdfApp, SaveUdfTable } from "../../api/user-defined-field";
import { Token } from "../../utils/default-cookies";
import DefaultSelectBox from "../../components/select-box/select-box";

function Input({ name, label, placeholder, displayStar }) {
  const { field } = useController({ name });
  return (
    <DefaultTextBox
      onTbvalueChange={field.onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={displayStar}
    />
  );
}
function SelectInput({ label, placeholder }) {
  const { field } = useController({ name: "table.MenuType" });
  return (
    <DefaultSelectBox
      onValueChanged={field.onChange}
      value={field.value}
      displayStar
      caption={label}
      placeholder={placeholder}
      items={[
        {
          id: 1,
          label: "Blank",
        },
        {
          id: 2,
          label: "Master",
        },
        {
          id: 3,
          label: "Document",
        },
      ]}
      displayExpr="label"
      valueExpr="id"
    />
  );
}

export default function CreateNewAppPopup(props) {
  const { onHiding, visible, onSuccess } = props;

  const methods = useForm({
    defaultValues: {
      app: {
        Name: "",
        Caption: "",
        RibbonSeq: 0,
        Active_YN: true,
      },
      table: {
        TableName: "",
        // "MenuType valid value is either 1 = blank, 2 = master, or 3 = document"
        MenuType: 1,
        DocNoPrefix: "",
        Caption: "",
        Layout: "",
        ListLayout: "",
        MenuSeq: 0,
        AppId: undefined,
        ExistingFields: [{}],
      },
    },
  });
  const { reset } = methods;

  const onSubmit = async (values) => {
    try {
      const response = await SaveUdfApp({ token: Token(), body: values.app });
      if (!response.result) {
        throw Error(response.message);
      }
      const createTableResponse = await SaveUdfTable({
        token: Token(),
        body: {
          //   TableId: response.data.UA_id,
          //   Table,
          ...values.table,
          AppId: response.data.AppId,
        },
      });
      if (!createTableResponse.result) {
        throw Error(createTableResponse.message);
      }
      console.log("Success");
      reset();
      onHiding(false);
      onSuccess?.();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup
      className="item-popup"
      title="Create New App"
      visible={visible}
      dragEnabled
      showTitle
      width={400}
      height={520}
      closeOnOutsideClick
      onHiding={onHiding}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <div>
          <Input name="app.Name" label="Name" displayStar />
          <Input name="app.Caption" label="Caption" displayStar />
          <Input name="table.TableName" label="Table Name" displayStar />
          <Input name="table.DocNoPrefix" label="Doc No Prefix" displayStar />
          <Input name="table.Caption" label="Table Caption" displayStar />
          <SelectInput label="Table Menu Type" placeholder="Table Menu Type" />
        </div>
        <div style={{ display: "flex", gap: 8, margin: "24px 0 8px" }}>
          <Button type="submit">Save</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </Popup>
  );
}
