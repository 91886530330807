import React, { useState } from 'react';
import SelectBox from 'devextreme-react/select-box';

const Singleselectiondropdown = (props) => {

    const [value, setValue] = useState(props.defaultValue);

    function onChangedHandler(event){
        setValue(event.value);
        props.parentCallback(event.value);
    }

    return(
        <div>
            <div className="dx-fieldset-text">
                <div className="dx-field">
                    <div className="dx-field-label">{props.caption}</div>
                </div>
            </div>

            <div className="dx-fieldset">
                <div className="dx-field">
                    <div className="dx-field-value">
                            <SelectBox dataSource={props.dataSource}
                                placeholder={props.placeholder}
                                displayExpr={props.displayExpr}
                                stylingMode={"outlined"}
                                valueExpr={props.valueExpr}
                                defaultValue={props.defaultValue}
                                className='filter-drop-down'
                                onValueChanged = {onChangedHandler}
                                value = {value}>
                            </SelectBox>
                        </div>
                </div>
            </div>
        </div>
    );
}

export default Singleselectiondropdown;