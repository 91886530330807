import { Button } from 'devextreme-react';
import NumberBox from 'devextreme-react/number-box';
import React from 'react';
import PropTypes from 'prop-types';
import './number-box.scss';

function fillString(format, value) {
    return value.padStart(format.length, '0');
}

export default function DefaultNumberBox(props) {
    const {
        displayStar,
        caption,
        displayValue,
        value,
        disabled,
        placeholder,
        onValueChanged,
        stylingMode = "outlined",
        format,
        min,
        max,
        direction = "vertical",
        labelWidth,
        isResetStyle,
        justify: _justify = "left",
        align: _align = "left",
        className,
        labelClassName,
        hideColon,
        step = 1,
        errorMessage,
        renderBottom,
        onChange,
        ...rest
    } = props;
    const ref = React.useRef(null);

    const justify = React.useMemo(() => `justify ${_justify}`, [_justify]);
    const align = React.useMemo(() => `align ${_align}`, [_align]);
    const resetStyle = React.useMemo(() => isResetStyle ? 'reset ' : '', [isResetStyle]);

    const stepper = React.useMemo(() => {
        if (format) {
            const formatted = format.split(".");
            if (formatted[1]) {
                return parseFloat(`0.${fillString(formatted[1], `${step}`)}`)
            }
        }

        return step;
    }, [format, step])

    const nb_onChangeHandler = (event) => {
        onValueChanged?.(event.value);
    }

    const onValueChange = (value) => {
        onChange?.(value);
    }

    const incrementBtn = React.useCallback(() => {
        if (ref.current) {
            const currentValue = ref.current.instance.option('value');
            const changedValue = currentValue + stepper < max ? currentValue + stepper : max;
            ref.current.instance.option('value', changedValue);
        }
    }, [max, stepper]);

    const decrementBtn = React.useCallback(() => {
        if (ref.current) {
            const currentValue = ref.current.instance.option('value');
            const changedValue = currentValue - stepper > min ? currentValue - stepper : min;
            ref.current.instance.option('value', changedValue);
        }
    }, [min, stepper])

    return (
        <div className={`number-box-container ${className ?? ''} ${direction} ${justify} ${align}`}>
            <div className={`${resetStyle}dx-fieldset-text`} style={{ width: labelWidth }}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        <span className={["label", labelClassName].join(" ")}>{caption}</span>
                        <>{displayStar ? (<span style={{ color: 'red' }}> * </span>) : ""}{!hideColon && ":"}</>
                        {displayValue === '' ? <span className="required-field">Required</span> : null}
                    </div>
                </div>
            </div>
            <div className={`${resetStyle}dx-fieldset field`}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <NumberBox
                            ref={ref}
                            {...rest}
                            className="number-box"
                            value={value}
                            readOnly={disabled}
                            placeholder={placeholder}
                            onValueChanged={nb_onChangeHandler}
                            onValueChange={onValueChange}
                            stylingMode={stylingMode}
                            format={format}
                            showSpinButtons={true}
                            min={min}
                            max={max}
                            step={step}
                        />
                        <div className='arrowContainer'>
                            <Button
                                type="button"
                                stylingMode='text'
                                className='arrowBtn'
                                onClick={incrementBtn}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1eafed" viewBox="0 0 256 256"><path d="M213.66,165.66a8,8,0,0,1-11.32,0L128,91.31,53.66,165.66a8,8,0,0,1-11.32-11.32l80-80a8,8,0,0,1,11.32,0l80,80A8,8,0,0,1,213.66,165.66Z"></path></svg>
                            </Button>
                            <Button
                                type="button"
                                stylingMode='text'
                                className='arrowBtn'
                                onClick={decrementBtn}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1eafed" viewBox="0 0 256 256"><path d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {errorMessage ? (
                <span className="errorText">{errorMessage}</span>
            ) : null}
            {renderBottom}
        </div>
    );
}

DefaultNumberBox.propTypes = {
    displayStar: PropTypes.bool,
    caption: PropTypes.string,
    displayValue: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    stylingMode: PropTypes.oneOf(["outlined", "underlined", "filled"]),
    onValueChanged: PropTypes.func,
    onChange: PropTypes.func,
    format: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    direction: PropTypes.oneOf(["horizontal", "vertical"]),
    labelWidth: PropTypes.number,
    isResetStyle: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    hideColon: PropTypes.bool,
    step: PropTypes.number,
    errorMessage: PropTypes.string,
    renderBottom: PropTypes.node,
}