export const DefaultDocStatusSource =  
[
    {
        displayValue: 'DRAFT'
    },
    {
        displayValue: 'POSTED'
    },
    {
        displayValue: 'CANCEL'
    }
]

export const InitialSelectedDocStatus = ['DRAFT', 'POSTED'] 