// import { CheckBox } from 'devextreme-react/check-box';
import React from "react";
import PropTypes from "prop-types";
import "./check-box.scss";
import { Checkbox } from "@mui/material";

// const checkedLabel = { 'aria-label': 'Checked' };
// const indeterminateLabel = { 'aria-label': 'Indeterminate' };
// const disabledLabel = { 'aria-label': 'Disabled' };

export default function DefaultCheckbox(props) {
  const {
    // defaultValue,
    label,
    disabled,
    value,
    onValueChanged,
    labelWidth,
    displayStar,
    flexReverse,
    isResetStyle,
    justify: _justify = "left",
    align: _align = "left",
    className,
    checkColor,
    labelClassName,
  } = props;

  const justify = React.useMemo(() => `justify ${_justify}`, [_justify]);
  const align = React.useMemo(() => `align ${_align}`, [_align]);
  const resetStyle = React.useMemo(
    () => (isResetStyle ? "reset " : ""),
    [isResetStyle]
  );

  const uncheckBorderColor = checkColor === "#4CC85B" ? "white" : undefined;

  const cb_onChangeHandler = (event) => {
    onValueChanged?.(event.target.checked);
  };

  return (
    <div
      className={`check-box-container ${className ?? ""} ${justify} ${align}`}
      style={{
        width: "100%",
        flexDirection: flexReverse ? "row-reverse" : "row",
      }}
    >
      <div
        className={`${resetStyle}dx-fieldset-text`}
        style={{ width: labelWidth }}
      >
        <div className="dx-field">
          <div className="dx-field-label">
            <span className={["label", labelClassName].join(" ")}>{label}</span>
            <>
              {displayStar ? <span style={{ color: "red" }}> * </span> : ""}
              {flexReverse ? "" : ":"}
            </>
          </div>
        </div>
      </div>
      <div className={`${resetStyle}dx-fieldset field`}>
        <div className="dx-field">
          <div
            className="dx-field-value"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox
              checked={value}
              disabled={disabled}
              className="check-box"
              onChange={cb_onChangeHandler}
              sx={{
                color: uncheckBorderColor,
                "& .MuiSvgIcon-root": { fontSize: 20 },
                "&:hover": {
                  background: "none",
                },
                "&.Mui-checked": {
                  color: checkColor,
                },
                "&.MuiCheckbox-root": {
                  padding: 0
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultCheckbox.propTypes = {
  displayStar: PropTypes.bool,
  displayValue: PropTypes.bool,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onValueChanged: PropTypes.func,
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  isResetStyle: PropTypes.bool,
  flexReverse: PropTypes.bool,
  checkColor: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};
