//import Button from 'devextreme-react/button';
//import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
//import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useRef } from 'react';
//import { useHistory } from 'react-router';
//import { SideNavigationMenu, Footer } from '../../components';
import { Footer } from '../../components';
import './side-nav-inner-toolbar.scss';
//import { useScreenSize } from '../../utils/media-query';
//import { Template } from 'devextreme-react/core/template';
//import { useMenuPatch } from '../../utils/patches';

export default function ({ title, children }) {
  const scrollViewRef = useRef();
  //const history = useHistory();
  //const { isXSmall, isLarge } = useScreenSize();
  //const { isLarge } = useScreenSize();
  //const [patchCssClass, onMenuReady] = useMenuPatch();
  //const [menuStatus, setMenuStatus] = useState(
  //  isLarge ? MenuStatus.Opened : MenuStatus.Closed
  //);

  //const toggleMenu = useCallback(({ event }) => {
  //  setMenuStatus(
  //    prevMenuStatus => prevMenuStatus === MenuStatus.Closed
  //      ? MenuStatus.Opened
  //      : MenuStatus.Closed
  //  );
  //  event.stopPropagation();
  //}, []);

  //const temporaryOpenMenu = useCallback(() => {
  //  setMenuStatus(
  //    prevMenuStatus => prevMenuStatus === MenuStatus.Closed
  //      ? MenuStatus.TemporaryOpened
  //      : prevMenuStatus
  //  );
  //}, []);

  //const onOutsideClick = useCallback(() => {
  //  setMenuStatus(
  //    prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
  //      ? MenuStatus.Closed
  //      : prevMenuStatus
  //  );
  //}, [isLarge]);

  //const onNavigationChanged = useCallback(({ itemData: { path }, event, node }) => {
  //  if (menuStatus === MenuStatus.Closed || !path || node.selected) {
  //    event.preventDefault();
  //    return;
  //  }
//
  //  history.push(path);
  //  scrollViewRef.current.instance.scrollTo(0);
//
  //  if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
  //    setMenuStatus(MenuStatus.Closed);
  //    event.stopPropagation();
  //  }
  //}, [history, menuStatus, isLarge]);

  return (
    <div className={'side-nav-inner-toolbar'}>
        <div className={'container'}>
          {/*<Header
            menuToggleEnabled={isXSmall}
            toggleMenu={toggleMenu}
          />*/}
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'}>
            <div className={'content'} tabIndex="-1">
              {React.Children.map(children, item => {
                return item.type !== Footer && item;
              })}
            </div>
            {/* <div className={'content-block'}>
              {React.Children.map(children, item => {
                return item.type === Footer && item;
              })}
            </div> */}
          </ScrollView>
        </div>
    </div>
  );
}

//const MenuStatus = {
//  Closed: 1,
//  Opened: 2,
//  TemporaryOpened: 3
//};
