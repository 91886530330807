import defaultUser from '../utils/default-user';
import apiEndpoints from './api-endpoints';
import { RequestOptions } from './config';

export async function signIn(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser
    };
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

export function POST_GetUser({ token, UICulture = 'en-US', Tz }) {
  return fetch(`${apiEndpoints.postGetUser}?UI-Culture=${UICulture}`,
    RequestOptions(token, "POST", {}))
    .then(handleErrors)
    .then(response => response.json())
    .then(data => ({
      data: data.Obj.User,
      message: data.Message,
      result: data.Result,
    }));
}

//handle errors
function handleErrors(response) {
  if (!response.ok) throw new Error(response.status);
  return response;
}