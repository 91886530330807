import React, { Component } from 'react'
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

export const editBtn = ({ onClick, enabled }) => function (props) {
    return (
        <EditBtn
            {...props}
            onClick={onClick}
            enabled={enabled}
        />
    )
}

export class EditBtn extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.data = this.props.data;
        this.props.onClick(event);
    };

    render() {
        return (
            <div>
                {
                    this.props.enabled ?
                        (
                            <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" onClick={this.onClick} className="icon-edit-icon">
                                <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
                            </div>
                        )
                        :
                        (
                            <div className="disable-view-dtl">
                                <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" className="icon-edit-icon">
                                    <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
                                </div>
                            </div>
                        )
                }
            </div>
        )
    }
}
