export const GetUserLayout = (token, userID, moduleItem, controlID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_GET_USER_PREFERED_LAYOUT}userID=${userID}&moduleItem=${moduleItem}&controlID=${controlID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Query_UserPreferedLayout;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const PostUserLayout = (token, companyID, clientID, userID, moduleItemID, controlID, moduleItem, storageKey, data) =>
{
  const POSTrequestOptions = {
    method: 'POST',
    headers: new Headers({
      'accept': 'text/plain',
      'Content-Type': 'application/json-patch+json',//application/json
      'Authorization' : "Bearer " + token
    })
  }
  if(data){
    POSTrequestOptions.body = JSON.stringify
    ({
      "UPL_ID": 0,
      "UPL_UserID": userID,
      "UPL_ModuleItemID": moduleItemID,
      "UPL_ControlID": controlID,
      "UPL_Layout": JSON.stringify(data),
      "UPL_DefaultLayout": "",
      "UPL_DateCreated": "",
      "UPL_CreatedBy": userID,
      "UPL_DateModified": "",
      "UPL_ModifiedBy": userID,
      "UPL_CompanyID": companyID,
      "UPL_ClientID" : clientID,
      "UPL_LayoutName": "",
    });
  }
  
  return fetch(`${process.env.REACT_APP_API_POST_USER_PREFERED_LAYOUT}moduleItem=${moduleItem}&UI-Culture=en-US`, POSTrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .catch((error) => {console.log(error); return error;});  
}

//handle errors
function handleErrors(response) {
  if (!response.ok) throw new Error(response.status);
  return response;
}