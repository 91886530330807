import React from 'react';
import Cookies from 'universal-cookie';
import Form from 'devextreme-react/form';
import ReactTooltip from 'react-tooltip';
import { Button } from 'devextreme-react';
import { Item } from 'devextreme-react/select-box';
import { DefaultPager, DefaultStateStoringIgnore} from '../../../utils/default-data-grid-settings';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  ColumnChooser,
  Export,
  Scrolling,
  Summary,
  TotalItem,
  Grouping,
  GroupPanel,
  LoadPanel,
  StateStoring,
} from 'devextreme-react/data-grid';
import './purchase-invoice.scss';

//components
import ChildShowFilterRow from '../../../components/context-menu/data-grid-context-menu'
import { ListingHeader4 } from '../../../layouts/index.js'

//default 
import { 
  CompanyID, 
  UserID, 
  ClientID, 
  AdminYN, 
  SetCookies, 
  SetDecimalPoints, 
  SetDefaultName, 
  DefaultName, 
  Token, 
  SetCompanyID, 
  SetSearchText, 
  //SearchText 
} from '../../../utils/default-cookies';
import { DefaultSMIPurchaseInvoice } from '../../../utils/default-smi';

//language
import { formatMessage, locale } from 'devextreme/localization';

//loading bar
import loadingBar from '../../../components/image/loadingBar.gif';

//api
import { GetPurchaseInvoiceHDRByDateType_Date, CancelPurchaseInvoice, SetDateType, DateType, SetSelectedStatus, SelectedStatus} from './purchase-invoice-services'
import { GetAllCompaniesIAGByUser, GetUserGroupAccessRightsByModuleItem} from './../../inventory-setting/package-item/package-item-services';
import { PostUserLayout, GetUserLayout } from '../../../api/user-preferred-layout';

// dx save state function not allow to call class component -> this 
var moduleItemID = 0;
const cookies = new Cookies();
const Mousetrap = require("mousetrap");
Mousetrap.prototype.stopCallback = function () {
  return false;
}

class PurchaseInvoice extends React.Component {
  constructor(props) {
    super(props);

    //component instance
    this.dataGridRef = React.createRef();
    this.childToolBarRef = React.createRef();
    this.childShowFilterRowRef = React.createRef();
    this.childHeader = React.createRef();


    //data grid context menu
    this.addMenuItems     = this.addMenuItems.bind(this);
    this.showGroupPanel   = this.showGroupPanel.bind(this);
    this.showFilterRow    = this.showFilterRow.bind(this);
    this.showInfoChange   = this.showInfoChange.bind(this);
    this.restoreLayout    = this.restoreLayout.bind(this);
    
    //pop up flash msg
    this.onFlashMessageTimer  = this.onFlashMessageTimer.bind(this);
    this.showMsgHandler       = this.showMsgHandler.bind(this);
    
    // handle state
    this.handlePurchaseInvoice            = this.handlePurchaseInvoice.bind(this);
    this.handleCompanies                  = this.handleCompanies.bind(this);
    this.handleSessionPopUpCallBack       = this.handleSessionPopUpCallBack.bind(this);
    this.handleAllowExportGrid            = this.handleAllowExportGrid.bind(this);
    this.handleAllowDisplayColumnChooser  = this.handleAllowDisplayColumnChooser.bind(this);
    this.handleAllowRestoreLayout         = this.handleAllowRestoreLayout.bind(this);
    this.handleViewDtl                    = this.handleViewDtl.bind(this);
    this.selectValueChangeHandler         = this.selectValueChangeHandler.bind(this);   //Company dropdown variable
    this.tagValueChangeHandler            = this.tagValueChangeHandler.bind(this);      //Status multi-selection variable
    this.dateTypeChangeHandler            = this.dateTypeChangeHandler.bind(this);      //Date Type dropdown variable
    this.searchValueChangeHandler         = this.searchValueChangeHandler.bind(this);

    //data grid rendering
    this.onCellPrepared               = this.onCellPrepared.bind(this);
    this.buttonGroup                  = this.buttonGroup.bind(this);
    this.preparedDocNoLinkButtonCell  = this.preparedDocNoLinkButtonCell.bind(this);
    this.preparedVendorLinkButtonCell = this.preparedVendorLinkButtonCell.bind(this);
    this.onCellRender                 = this.onCellRender.bind(this);
    this.statusButton                 = this.statusButton.bind(this);
    this.renderAddNewHeader           = this.renderAddNewHeader.bind(this);

    //data grid function
    this.onOptionChanged = this.onOptionChanged.bind(this);

    // post message from alaya c#
    this.receiveMessage = this.receiveMessage.bind(this);

    // buttons
    this.filterHandler    = this.filterHandler.bind(this);
    this.analysisHandler  = this.analysisHandler.bind(this);
    this.docActionHandler = this.docActionHandler.bind(this);

    // data grid command buttons
    this.onClickEdit    = this.onClickEdit.bind(this);
    this.onClickDelete  = this.onClickDelete.bind(this);
    this.onClickCopy    = this.onClickCopy.bind(this);

    // user access control
    this.handleAllowCopy = this.handleAllowCopy.bind(this);
    this.handleAllowDelete = this.handleAllowDelete.bind(this);
    this.handleAllowAdd = this.handleAllowAdd.bind(this);
    this.handleAllowExportGrid = this.handleAllowExportGrid.bind(this);
    this.handleAllowDisplayColumnChooser = this.handleAllowDisplayColumnChooser.bind(this);
    this.handleAllowRestoreLayout = this.handleAllowRestoreLayout.bind(this);
    
    this.calculateSearchExpression = this.calculateSearchExpression.bind(this);
    this.dataGridFocusedRowUp = this.dataGridFocusedRowUp.bind(this);
    this.dataGridFocusedRowDown = this.dataGridFocusedRowDown.bind(this);
    

    this.state = {
      purchaseInvoice : [],
      companies : [],
      searchText: '',
      showFilterRow: false,
      showGroupPanel: false,
      allowSave: false,
      allowDelete: false,
      allowCopy: false,
      allowAdd: false,
      viewDtl: false,
      buttonMode: true,
      displayFlashMessageDialog: false,
      status: 'success',
      size: 'flash-message-success-container',
      allowExportGrid: false,
      allowDisplayColumnChooser: false,
      allowRestoreLayout: false,
    };

    
  }

  //component instance
  get dataGrid() {
    // `current.instance` points to the UI component instance
    return this.dataGridRef.current.instance;
  }
  
  //#region set data grid context menu

  addMenuItems(e) {
    this.childShowFilterRowRef.current.DataGridContextMenuItemsService(e, this.dataGrid);
  }

  //set child - data-grid-context-menu to show group panel
  showGroupPanel() {
    this.setState(prevState => ({
      showGroupPanel: !prevState.showGroupPanel
    }));
  }

  //set child - data-grid-context-menu to show filter row
  showFilterRow() {
    this.setState(prevState => ({
      showFilterRow: !prevState.showFilterRow
    }));
    this.dataGridRef.current.instance.clearFilter();
  }

  //paging info
  showInfoChange(value){
    this.setState({...this.state, showInfo: value})
  }

  //data-grid-context-menu to restore datagrid default layout 
  restoreLayout() {
    PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID, DefaultSMIPurchaseInvoice.controlID,  DefaultSMIPurchaseInvoice.purchaseInvoice, "storageKey", " ");
    if (this.dataGridRef.current != null)
      this.dataGridRef.current.instance.state("");
  }

  //#endregion set data grid context menu

  //#region flash msg

  getParentNode(element, level=1) { // 1 - default value (if no 'level' parameter is passed to the function)
    while(level-- > 0) {
        element = element.parentNode;
        if(!element) {
            return null; // to avoid a possible "TypeError: Cannot read property 'parentNode' of null" if the requested level is higher than document
        }
    }
    return element;
  }

  onFlashMessageTimer(e){
    if(this.state.displayFlashMessageDialog === true){
      setTimeout(() => this.setState({displayFlashMessageDialog: false}), 1000)
    }
    if((e.code==="Enter" || e.code === "NumpadEnter") && this.state.viewDtl !== false)
    {
      //check if default focus at quick search input box, 3 = level up of parents, it might change if DOM change
      var quickSearchNode = this.getParentNode(document.activeElement, 3);
      if (quickSearchNode !== null  && quickSearchNode.id !== 'search')
      {
        //let objCompany = this.state.companies.find(c => c.CO_ID == CompanyID());
        //var iag = objCompany.CO_IAG;
        //var iag_desp = objCompany.IAG_Description;
        //window.open("/#/package-item-details?id=" + this.state.PackageItemsFocusedRowData.IM_ID + "&code=" + this.state.PackageItemsFocusedRowData.IM_Code + "&desp=" + this.state.PackageItemsFocusedRowData.IM_Description + "&iag=" + iag + '&iag_desp=' + iag_desp, "_self");
      }
    }
  }

  showMsgHandler(params, value, size) {
    if (params !== '') {
      this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
    }
    else {
      this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
    }
  }

  //#endregion flash msg

  //#region set state/variable

  handlePurchaseInvoice = (result) => {
    this.setState({ purchaseInvoice: result });
  }

  handleCompanies = (result) => {
    this.setState({ companies: result });
  }

  handleSessionPopUpCallBack = () => {
    this.setState(state => ({
      displaySessionPopUp: !state.displaySessionPopUp
    }))
  }

  handleAllowCopy = (result) => {
    this.setState({ allowCopy: result });
  }

  handleAllowDelete = (result) => {
    this.setState({ allowDelete: result });
  }

  handleAllowAdd = (result) => {
    this.setState({ allowAdd: result });
  }

  handleAllowExportGrid = (result) => {
    this.setState({ allowExportGrid: result });
  }

  handleAllowDisplayColumnChooser = (result) => {
    this.setState({ allowDisplayColumnChooser: result });
  }
  
  handleAllowRestoreLayout = (result) => {
    this.setState({ allowRestoreLayout: result });
  }

  handleViewDtl = (result) => {
    this.setState({ viewDtl: result });
  }

  selectValueChangeHandler = (event) => {
    SetCompanyID(cookies, event);
  }

  tagValueChangeHandler = (event) => {
    SetSelectedStatus(event);
  }

  dateTypeChangeHandler = (event) => {
    SetDateType(event);
  }

  searchValueChangeHandler = (event) => {
    SetSearchText(cookies, event);
    this.setState({ searchText: event });
  }

  //#endregion set state/variable
  
  //#region  data grid rendering
  // data grid render #1
  onCellPrepared(e) {
   if (e.rowType === "totalFooter") {
     if (e.column.type === "buttons") {
       // create the required HTML element with 'summaryValue' and append it to 'e.cellElement'
       const span = document.createElement("span");
       span.className = "dx-datagrid-summary-item"
       span.innerHTML = formatMessage("Records") + ": " + this.state.purchaseInvoice.totalCount;
       e.cellElement.appendChild(span);
     }
     if (e.column.dataField === "DocumentAmount") {
       e.cellElement.innerHTML = "";
       const span = document.createElement("span");
       span.className = "dx-datagrid-summary-item"
       span.innerHTML =  this.state.purchaseInvoice.totalSum;
       e.cellElement.appendChild(span);
     }
   }
  }

  // data grid render #2
  buttonGroup(e) {
    return (
      <div className='icon-row'>
        <div className='icon-1'>
          {this.state.viewDtl === true ?
            (
              <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" id={e.data.PIH_ID} name={e.data.DocumentNo} value={e.data.IM_Description} onClick={this.editIconClick} className="icon-edit-icon">
                <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
              </div>
            ) :
            (
              <div className="disable-row-view-dtl">
                <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" id={e.data.PIH_ID} name={e.data.DocumentNo} value={e.data.IM_Description} className="icon-edit-icon">
                  <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
                </div>
              </div>
            )}
        </div>
        <div className='icon-2'>
          {(this.state.allowDelete === true && e.data.Status !== "CANCEL" && !e.data.IsKnockOff) ?
            (
              <div data-tip={formatMessage("Delete[Delete]")} data-for="delete" id={e.data.PIH_ID} onClick={this.onClickDelete} className="icon-delete-icon">
                <ReactTooltip id="delete" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
              </div>
            ) :
            (
              <div className="disable-row-delete">
                <div data-tip={formatMessage("Delete[Delete]")} data-for="delete" id={e.data.PIH_ID} className="icon-delete-icon">
                  <ReactTooltip id="delete" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
                </div>
              </div>
            )}
        </div>
        <div className='icon-3'>
          {this.state.allowCopy === true ?
            (
              <div data-tip={formatMessage("COPY")} data-for="copy" id={e.data.PIH_ID} onClick={this.onClickCopy} className="icon-copy-text-icon">
                <ReactTooltip id="delete" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
              </div>
            ) :
            (
              <div className="disable-row-copy">
                <div data-tip={formatMessage("COPY")} data-for="copy" id={e.data.PIH_ID} className="icon-copy-text-icon">
                  <ReactTooltip id="copy" arrowColor="#474751" place='top' effect="solid" className="dataGridTooltips" />
                </div>
              </div>
            )}
        </div>
      </div>
    )
  }

  // data grid render #3
  preparedDocNoLinkButtonCell(data) {
    return (
      <a 
        href={void (0)} 
        onClick={() => this.openSourceTab(data.data.LinkBtnUrlDocumentNo)} 
        className="item-code">{data.value}
      </a>
    );
  }

  // data grid render #3
  preparedVendorLinkButtonCell(data) {
    return (
      <a 
        href={void (0)} 
        onClick={() => this.openSourceTab(data.data.LinkBtnUrlVendor)} 
        className="item-code">{data.value}
      </a>
    );
  }

  // data grid render #4
  onCellRender(cellData) {
    const search = this.state.searchText.trim();
    if (search.length === 0) return cellData.value;
    const regExpText = "(".concat(
      search
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
        .split(" ").filter(el => el.length > 0)
        .sort((a, b) => b.length - a.length)
        .join(")|(")
        .concat(")")
    );
    const regExp = new RegExp(regExpText, "ig");
    try {
      const matches = (cellData.value.match(regExp)) ? cellData.value.match(regExp).filter((el) => el !== undefined) : [];
      return (
        <div>
          {cellData.value
            .split(regExp)
            .filter((el) => el !== undefined && el != null)
            .map((el, index) => {
              if (el === matches[0]) {
                matches.shift();
                return (
                  <span key={index} className="dx-datagrid-search-text">
                    {el}
                  </span>
                );
              } else return el;
            })}
        </div>
      );
    }
    catch (error) { }
  }

  // data grid render #5
  statusButton(cellData) {
    if(cellData.value === 'POSTED'){
      return (
        <div className="status-container">
          <div className="green">
          {cellData.value}
          </div>
        </div>
      )
    }
    if(cellData.value === 'CANCEL'){
      return (
        <div className="status-container">
          <div className="red">
          {cellData.value}
          </div>
        </div>
      )
    }
    if(cellData.value === 'DRAFT'){
      return (
        <div className="status-container">
          <div className="orange">
          {cellData.value}
          </div>
        </div>        
      )
    }
  }

  // data grid render #6
  renderAddNewHeader() {
    return (
      <div data-tip={formatMessage("AddNew[Insert]")} data-for="addNew" className="new-btn-contanier">
        {this.state.allowAdd === true ?
          (
            <Button
              className="addNewBtn"
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}
              onClick={this.onClickAddNewItem}>
              <span className="icon-add-icon" />
              <span className="package-item-add-text">{formatMessage('New1')}</span>
            </Button>
          ) :
          (
            <Button
              style={{ backgroundColor: "darkgrey" }}
              className="addNewBtn"
              hoverStateEnabled={false}
              activeStateEnabled={false}
              focusStateEnabled={false}>
              <span className="icon-add-icon" />
              <span className="package-item-add-text">{formatMessage('New1')}</span>
            </Button>
          )}
        <ReactTooltip id="addNew" effect="solid" arrowColor="#474751" place='top' className="dataGridTooltips" />
      </div>
    );
  }

  //#endregion  data grid rendering

  //#region data grid function

  //data grid - search function
  onOptionChanged(options) {
    //if (options.name === "searchPanel" && options.fullName === "searchPanel.text") {
    //  this.setState({ searchText: options.value });
    //}
  }

  // data grid - load user preferred layout
  // cannot remove because it will load default state not user preferred state everytime search text change
  loadState () {

  }

  // data grid - save user preferred layout
  saveState (state) {
    console.log('state')
    //every time refresh will call 1 time due to set state after get user preferred layout api called.
    //unable to call this api before componentdidmount due to have to wait for the cookies send from iframe 
    //save the custom state 
    PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID, DefaultSMIPurchaseInvoice.controlID, DefaultSMIPurchaseInvoice.purchaseInvoice, "storageKey", state);
  }

  //#endregion

  componentDidMount() {
    window.addEventListener("message", this.receiveMessage, false);
    document.addEventListener('keydown', (event) => this.onFlashMessageTimer(event), false);
    document.addEventListener('mousedown', (event) => this.onFlashMessageTimer(event), false);

    Mousetrap.bind("up", () => this.dataGridFocusedRowUp());
    Mousetrap.bind("down", () => this.dataGridFocusedRowDown());

  }

  componentWillUnmount() {
    //remove event listener
    window.removeEventListener("message", this.receiveMessage, false);
    document.removeEventListener('keydown', (event) => this.onFlashMessageTimer(event), false);
    document.removeEventListener('mousedown', (event) => this.onFlashMessageTimer(event),false);

    Mousetrap.unbind("up");
    Mousetrap.unbind("down");
  }

  async receiveMessage(e) {
    if (e.data !== "empty") {
      SetCookies(cookies, e.data.accessToken, e.data.userID, e.data.companyID, e.data.clientID, e.data.username, e.data.adminYN, e.data.administratorYN, e.data.language);
      //language
      sessionStorage.setItem("locale", 'en');
      const locales = sessionStorage.getItem("locale");
      locale(locales);

      const [dataSource_GetPurchaseInvoice, dataSource_GetAllCompanyIAG, UserAccessControl, UserPreferedLayout] = await Promise.all([
        GetPurchaseInvoiceHDRByDateType_Date(Token(), CompanyID(), DateType(), '2021-01-01','2021-10-01', true, UserID(), SelectedStatus(),''),
        GetAllCompaniesIAGByUser(Token(), ClientID(), UserID(), AdminYN()),
        GetUserGroupAccessRightsByModuleItem(Token(), UserID(), 'purchase-invoice', CompanyID()),
        GetUserLayout(Token(), UserID(), DefaultSMIPurchaseInvoice.purchaseInvoice, DefaultSMIPurchaseInvoice.controlID)
      ])

      if (dataSource_GetPurchaseInvoice === 'Error: 401' || dataSource_GetAllCompanyIAG === 'Error: 401' || UserAccessControl === 'Error: 401') {
        this.handleSessionPopUpCallBack();
      }
      else {
        var currentCompanyData = dataSource_GetAllCompanyIAG.find(x => x.CO_ID == CompanyID());
        SetDefaultName(cookies, currentCompanyData.CO_Name);
        SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

        this.handleCompanies(dataSource_GetAllCompanyIAG);
        this.handlePurchaseInvoice(dataSource_GetPurchaseInvoice);

        if(AdminYN() === "true"){
          this.handleAllowCopy(true);
          this.handleAllowDelete(true);    
          this.handleAllowAdd(true);  
          this.handleViewDtl(true);
          this.handleAllowDisplayColumnChooser(true);
          this.handleAllowExportGrid(true);
          this.handleAllowRestoreLayout(true);  
        }
        else{
          this.handleAllowCopy(UserAccessControl.Query_UserModuleItemAccessSimplified[0].Copy);
          this.handleAllowDelete(UserAccessControl.Query_UserModuleItemAccessSimplified[0].Delete); 
          this.handleAllowAdd(UserAccessControl.Query_UserModuleItemAccessSimplified[0].Add); 
          this.handleViewDtl(UserAccessControl.Query_UserModuleItemAccessSimplified[0].SaveEdit);
          this.handleAllowDisplayColumnChooser(UserAccessControl.Query_UserModuleItemAccessSimplified[0].GridColumnChooser);
          this.handleAllowExportGrid(UserAccessControl.Query_UserModuleItemAccessSimplified[0].ExportGrid);
          this.handleAllowRestoreLayout(UserAccessControl.Query_UserModuleItemAccessSimplified[0].RestoreGridLayout);                
        }
      }

      //load user preferred layout
      if(UserPreferedLayout.length > 0)
      {
        moduleItemID = UserPreferedLayout[0].UPL_ModuleItemID;
        if(this.dataGridRef.current != null)
        {
          var userlayout = (JSON.parse(UserPreferedLayout[0].UPL_Layout));
          userlayout['pageIndex'] = 0;
          delete userlayout['focusedRowKey'];
          this.dataGridRef.current.instance.state(userlayout);
        }
      }
    }
  }

  //#region buttons
  async filterHandler(event) {
    const [dataSource_GetPurchaseInvoice] = await Promise.all([
      GetPurchaseInvoiceHDRByDateType_Date(Token(), CompanyID(), DateType(), '2021-01-01','2021-10-01', false, UserID(), SelectedStatus(),'')
    ])
    
    var currentCompanyData = this.state.companies.find(x => x.CO_ID === CompanyID());
    SetDefaultName(cookies, currentCompanyData.CO_Name);
    SetDecimalPoints(cookies, currentCompanyData.IAG_ItemRounding);

    this.handlePurchaseInvoice(dataSource_GetPurchaseInvoice);
  }

  analysisHandler(event){
    this.openSourceTab(this.state.purchaseInvoice.LinkBtnUrlAnalysis+';Purchase Invoice Analysis');
  }

  docActionHandler(event){
    this.openSourceTab(this.state.purchaseInvoice.LinkBtnUrlDocumentAction+';Document Action');
  }

  //#endregion

  //#region command buttons functions

  onClickEdit() {
  }

  async onClickDelete(event) {
    const {id} = event.target;
    console.log(id)
    const [result] = await Promise.all([
      CancelPurchaseInvoice(Token(), id, CompanyID())
    ])
    if (result === 'Error: 401') {
      this.handleSessionPopUpCallBack();
    }

    console.log(result.Success)
    if(result.length > 0)
      this.showMsgHandler(formatMessage('SavedSuccessfully'), 'success', 'flash-message-success-container');
    else 
      return;
  }

  onClickCopy() {
  }    

  //#endregion
  dataGridFocusedRowUp() {
    //up shortcut key not function when focus on data grid
    this.dataGridRef.current.instance.focus();
  }
  dataGridFocusedRowDown() {
    //down shortcut key not function when focus on data grid
    this.dataGridRef.current.instance.focus();
    var newFocusRowIndex = this.dataGridRef.current.instance.option('focusedRowIndex') + 1;
    this.dataGridRef.current.instance.option('focusedRowIndex', newFocusRowIndex)
  }


  openSourceTab(url) {
    var urlArray = url.split(';');
    if (urlArray.length === 2)
      window.parent.postMessage(this.getPostMessageObj(urlArray[0], urlArray[1]), "*");
    else if (urlArray.length === 1)
      window.parent.postMessage(this.getPostMessageObj(urlArray[0], 'Purchase Invoice'), "*");
  }

  getPostMessageObj(url, tabTitle) {
    return {
        source: window.location.host,
        target: process.env.ALAYA_APP_API_BASE_URL,
        eval: "parent.closeTab('" + tabTitle +"');parent.addTab('" + tabTitle + "', '"+ url +"')"
      }
  }

  calculateSearchExpression(filtervalue, selectedFilterOperations, target) {
    const query = filtervalue.trim();
    const regExpText = "(".concat(
      query
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
        .split(" ")
        .join(")|(")
        .concat(")")
    );

    const regExp = new RegExp(regExpText, 'i');
    const getValue = (col) => {
      return (query.length === 0) ? true : regExp.test(col.DocumentDate)  || regExp.test(col.DocumentNo)     || regExp.test(col.SupplierInvNo) || 
                                           regExp.test(col.Vendor)        || regExp.test(col.DocumentAmount) || regExp.test(col.Status)        ||
                                           regExp.test(col.CreatedDate)   || regExp.test(col.CreatedBy)      || regExp.test(col.Currency)      ||
                                           regExp.test(col.Description)   || regExp.test(col.Division)       || regExp.test(col.PostingDate)   ||
                                           regExp.test(col.PurchaseAgent) || regExp.test(col.PurchaseOrg) 
                                           ;
    }
    if (target !== "search") return;
    return [[getValue, '=', true]];
  }

  //#endregion

  render() {

    return (
      <React.Fragment>
        <Form
          id      = {"purchase-invoice-form"}
          width   = {"100%"}
          colCount={8}>
          <Item colSpan={8}>
            <div className="package-item-header-title">
              <ListingHeader4
                placeHolderText     = {formatMessage('Search')}
                buttonText          = {formatMessage('Search')} 
                captionDropdown     = {formatMessage('Company')}
                captionDropdown2    = {formatMessage('DateType')}
                captionTagbox       = {formatMessage('Status')}
                captionTextbox      = {formatMessage('AssociatedItemGroup')}
                buttonText2         = {formatMessage('Analysis')}
                buttonText3         = {formatMessage('DocumentAction')}
                buttonText4         = {formatMessage('Filter')}
                dataSource          = {this.state.companies} 
                buttonMode          = {this.state.buttonMode}
                onCalBtnClick       = {this.onCalBtnClick}
                defaultValue        = {CompanyID()}
                defaultName         = {DefaultName()}
                codeProp            = {'CO_Code'}
                nameProp            = {'CO_Name'}
                valueProp           = {'CO_ID'}
                displayProp         = {'IAG_Description'}
                onFilterClick       = {this.filterHandler}
                onAnalysisBtnClick  = {this.analysisHandler}
                onDocActionBtnClick = {this.docActionHandler}
                onSelectValueChange = {this.selectValueChangeHandler}
                onTagValueChange    = {this.tagValueChangeHandler}
                onDateTypeValueChange    = {this.dateTypeChangeHandler}
                onSearchValueChange = {this.searchValueChangeHandler}
              />
            </div>
          </Item>

          <Item colSpan={8}>
            <div className="dx-fieldset">
              <div className="dx-field">
                <div className="dx-field-value">
                  <DataGrid
                    id                        = {DefaultSMIPurchaseInvoice.controlID}
                    allowColumnReordering     = {true}
                    allowColumnResizing       = {true}
                    columnAutoWidth           = {true}
                    columnHidingEnabled       = {false}
                    columnResizingMode        = {"widget"}
                    dataSource                = {this.state.purchaseInvoice.data}
                    defaultFocusedRowIndex    = {0}
                    focusedRowEnabled         = {true}
                    keyExpr                   = {"PIH_ID"}
                    onOptionChanged           = {this.onOptionChanged}
                    onCellPrepared            = {this.onCellPrepared}
                    // onContentReady         = {this.onContentReady}
                    onContextMenuPreparing    = {this.addMenuItems}
                    // onEditorPreparing      = {this.onEditorPreparing}
                    // onFocusedRowChanged    = {this.onFocusedRowChanged}
                    // onKeyDown              = {this.onKeyDown}
                    // onRowDblClick          = {this.editIconClick}
                    ref                       = {this.dataGridRef}
                    rowAlternationEnabled     = {true}
                    showBorders               = {true}
                    showRowLines              = {true}
                    className                 = 'dx-datagrid-items'>

                    <Export enabled               = {false} />
                    <FilterRow visible            = {this.state.showFilterRow} />
                    <Grouping contextMenuEnabled  = {true} />
                    <LoadPanel indicatorSrc       = {loadingBar} />
                    <GroupPanel visible           = {this.state.showGroupPanel} />
                    <Paging defaultPageSize       = {DefaultPager.defaultPageSize_listing} />
                    <Pager showPageSizeSelector   = {true}
                      allowedPageSizes            = {DefaultPager.allowedPageSizes_listing}
                      showNavigationButtons       = {true}
                      showInfo                    =  {this.showInfoChange}/>

                    <ColumnChooser enabled        = {false}
                                   mode           = {"select"} />

                    <SearchPanel text             = {this.state.searchText}
                                 visible          = {false} />

                    <Scrolling mode               = "standard"
                               useNative          = {false}
                               scrollByContent    = {true}
                               scrollByThumb      = {true}
                               showScrollbar      = "always" />

                    <StateStoring enabled                 = {true}
                                  type                    = {"custom"}
                                  customLoad              = {this.loadState}
                                  customSave              = {this.saveState}
                                  ignoreColumnOptionNames = {DefaultStateStoringIgnore}
                    />

                    <Column
                      allowHiding           = {false}
                      headerCellRender      = {this.renderAddNewHeader}
                      showInColumnChooser   = {false}
                      type                  = {"buttons"}
                      cellRender            = {this.buttonGroup}
                      fixed                 = {true}
                      fixedPosition         = {'left'}
                      minWidth              = {110}>
                    </Column>

                    <Column
                      dataField                   = {'PIH_ID'}
                      width                       = {120}
                      minWidth                    = {60}
                      showInColumnChooser         = {false}
                      visible                     = {false} />

                    <Column
                      dataField                   = {'DocumentDate'}
                      caption                     = {formatMessage('DocDate')}
                      width                       = {180}
                      //cellRender                  = {this.onCellRender}
                      //calculateFilterExpression   = {this.calculateSearchExpression}
                      minWidth                    = {60} />

                    <Column
                      dataField                   = {'DocumentNo'}
                      caption                     = {formatMessage('DocNo')}
                      minWidth                    = {60}
                      cellRender                  = {this.preparedDocNoLinkButtonCell} 
                      //calculateFilterExpression   = {this.calculateSearchExpression} 
                    />

                    <Column
                      dataField                   = {'SupplierInvNo'}
                      caption                     = {formatMessage('SupplierInvNo')}
                      minWidth                    = {60}
                      //cellRender                  = {this.onCellRender} 
                      //calculateFilterExpression   = {this.calculateSearchExpression} 
                    />

                    <Column
                      dataField   = {'Vendor'}
                      caption     = {formatMessage('Vendor')}
                      cellRender  = {this.preparedVendorLinkButtonCell}
                      minWidth    = {60} />

                    <Column
                      dataField   = {'DocumentAmount'}
                      caption     = {formatMessage('DocAmount')}
                      format      = {this.format}
                      minWidth    = {60} />

                    <Column
                      dataField   = {'Status'}
                      caption     = {formatMessage('Status')}
                      cellRender  = {this.statusButton}
                      minWidth    = {60} />

                    <Column
                       dataField                   = {'CreatedDate'}
                       caption                     = {formatMessage('CreatedDate')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'CreatedBy'}
                       caption                     = {formatMessage('CreatedBy')}
                       width                       = {180}
                       minWidth                    = {60} 
                       visible                     = {false}/>

                    <Column
                       dataField                   = {'Currency'}
                       caption                     = {formatMessage('Currency')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'Description'}
                       caption                     = {formatMessage('Description')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'Division'}
                       caption                     = {formatMessage('Division')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'PostingDate'}
                       caption                     = {formatMessage('PostingDate')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'PurchaseAgent'}
                       caption                     = {formatMessage('PurchaseAgent')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Column
                       dataField                   = {'PurchaseOrg'}
                       caption                     = {formatMessage('PurchaseOrg')}
                       width                       = {180}
                       minWidth                    = {60}
                       visible                     = {false} />

                    <Summary>
                    <TotalItem
                        name          = {"totalAmount"}
                        summaryType   = {"sum"}
                        valueFormat   = {"currency"}
                        value         = {"#,##0.##"}
                        displayFormat = {"{0}"}
                        column        = {"DocumentAmount"} />

                    </Summary>

                  </DataGrid>
                </div>
              </div>
            </div>
          </Item>
          <ChildShowFilterRow 
           displayColumnChooser = {this.state.allowDisplayColumnChooser}
           displayRestoreLayout = {this.state.allowRestoreLayout}
           displayExportGrid = {this.state.allowExportGrid}
           ref={this.childShowFilterRowRef} 
           getChildFilterRow={this.showFilterRow} 
           getChildGroupPanel={this.showGroupPanel} 
           restoreLayout={this.restoreLayout} 
           showRestoreMsg={this.showMsgHandler}/>
        </Form>
      </React.Fragment>
    )
  }
}

export default PurchaseInvoice;