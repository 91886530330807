export const GetCostMappingByCompanyID = (token, companyID, clientID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }
  
  return fetch(`${process.env.REACT_APP_API_GET_COST_MAPPING}companyID=${companyID}&clientID=${clientID}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())
  .then(data =>{
    return data.Obj;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const PostCostMapping = (token, cm_Id, clientID, companyID, iagid, mappingDigit_0, mappingDigit_1, 
  mappingDigit_2, mappingDigit_3, mappingDigit_4, mappingDigit_5, mappingDigit_6, mappingDigit_7, mappingDigit_8,
  mappingDigit_9, mappingDigit_10, leadingDigits, dateCreated, createdBy, dateModified, modifiedBy) => {

    const POSTrequestOptions = {
      method: 'POST',
      headers: new Headers({
        'accept': 'text/plain',
        'Content-Type': 'application/json-patch+json',
        'Authorization' : "Bearer " + token})
    }

    POSTrequestOptions.body = JSON.stringify({
      "CM_ID": cm_Id,
      "CM_ClientID": clientID,
      "CM_CompanyID": companyID,
      "CM_IAGID": iagid,
      "CM_MappingDigit_0": mappingDigit_0,
      "CM_MappingDigit_1": mappingDigit_1,
      "CM_MappingDigit_2": mappingDigit_2,
      "CM_MappingDigit_3": mappingDigit_3,
      "CM_MappingDigit_4": mappingDigit_4,
      "CM_MappingDigit_5": mappingDigit_5,
      "CM_MappingDigit_6": mappingDigit_6,
      "CM_MappingDigit_7": mappingDigit_7,
      "CM_MappingDigit_8": mappingDigit_8,
      "CM_MappingDigit_9": mappingDigit_9,
      "CM_MappingDigit_P": mappingDigit_10,
      "CM_LeadingDigits": leadingDigits,
      "CM_DateCreated": dateCreated,
      "CM_CreatedBy": createdBy,
      "CM_DateModified": dateModified,
      "CM_ModifiedBy": modifiedBy
    });

    return fetch(`${process.env.REACT_APP_API_POST_COST_MAPPING}UI-Culture=en-US`, POSTrequestOptions)
    .then(handleErrors)
    .then(response => response.json())
    .then(data =>{
      return data;
    })
    .catch((error) => {console.log(error); return error;});  
  }

export const DeleteCostMapping = (token, CMID) =>
{
  const GETrequestOptions = {
    method: 'DELETE',
    headers: new Headers({
      'accept': 'text/plain',
      'Authorization' : "Bearer " + token
    })
  }
  
  return fetch(`${process.env.REACT_APP_API_DELETE_COST_MAPPING}CMID=${CMID}&UI-Culture=en-US  `, GETrequestOptions)
  .then(handleErrors)
  .then(response => response.json())    
  .then(data =>{
    return data;
  })
  .catch((error) => {console.log(error); return error;});  
}

//handle errors
function handleErrors(response) {
  if (!response.ok) throw new Error(response.status);
  return response;
}
