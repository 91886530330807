import "gridstack/dist/gridstack.css";
import "gridstack/dist/es5/gridstack-poly.js";
import "gridstack/dist/es5/gridstack-all.js";
import "./styles.scss";
import React from "react";
import { GridStack } from "gridstack/dist/es5/gridstack";

export default function SimpleDemo() {
  const gridRef = React.useRef();
  const [widgets, setWidgets] = React.useState([{
    x: 0,
    y: 0,
    w: 4,
    h: 2,
    content: "1",
  }]);

  React.useEffect(() => {
    gridRef.current = GridStack.init({
      cellHeight: 70,
      minRow: 4,
      acceptWidgets: true,
      removable: "#trash", // drag-out delete class
      float: true,
    });

    const grid = gridRef.current;

    GridStack.setupDragIn(".newWidget", {
      appendTo: "body",
      helper: "clone",
      handle: ".grid-stack-item-content",
    });

    grid.on("dropped", (event, previousWidget, newWidget) => {
      if (previousWidget === undefined && !!newWidget) {
        const { el, w, h, x, y, minW, minH, maxH, maxW } = newWidget;
        console.log({ newWidget });
        setWidgets?.((items) => [
          ...items,
          {
            id: new Date().getTime().toString(),
            type: el.dataset.type,
            fieldName: el.dataset.name,
            placeholder: el.dataset.placeholder,
            label: el.dataset.label,
            labelWidth: el.dataset.label_width || 108,
            size: el.dataset.size,
            maximumValue: el.dataset.maximum_value,
            minimumValue: el.dataset.minimum_value,
            decimalPoint: el.dataset.decimal_point,
            options: el.dataset.options,
            required: el.dataset.required || false,
            w,
            h,
            x,
            y,
            minW,
            minH,
            maxW,
            maxH,
          },
        ]);
      }
    });
    grid.on("added removed change", function (e, items) {
      let str = "";
      //   items.forEach(function (item) {
      //     str += " (x,y)=" + item.x + "," + item.y;
      //   });
      console.log(e.type + " " + items.length + " items:" + str);
    });
  }, []);

  return (
    <div class="row" style={{ width: "100vw", display: "flex" }}>
      <div class="col-md-2 d-none d-md-block">
        <div
          id="trash"
          style={{ padding: 5, marginBottom: 5 }}
          class="text-center"
        >
          <div>
            +
          </div>
          <div>
            <span>Drop here to remove!</span>
          </div>
        </div>
        <div class="newWidget grid-stack-item">
          <div class="grid-stack-item-content" style={{ padding: 5 }}>
            <div>
              +
            </div>
            <div>
              <span>Drag me in the dashboard!</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-10" style={{ width: "100%", height: 500 }}>
        <div class="grid-stack" style={{ background: "blue" }}>
          {widgets.map((item) => (
            <div key={item.content} className="grid-stack-item">
              <div className="grid-stack-item-content">{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
