import { DefaultPIDateTypeValue } from '../../../utils/default-date-type';
import { InitialSelectedDocStatus } from '../../../utils/default-doc-status';

const GetRequestOptions = (token) => {
  return  {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }
}

const POSTRequestOptions = (token) => {
  return {
    method: 'POST',
    headers: new Headers({
      'accept': 'text/plain',
      'Content-Type': 'application/json-patch+json',//application/json
      'Authorization' : "Bearer " + token
    })
  }
}
  
export const GetPurchaseInvoiceHDRByDateType_Date = (token, companyID, dateType, fromDate, toDate, loadFirstTime, userID, status, filter) =>
{ 
  return fetch(`${process.env.REACT_APP_API_GET_PURCHASE_INVOICE_HDR}companyID=${companyID}&dateType=${dateType}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&strStatus=${status}&loadFirstTime=${loadFirstTime}&UI-Culture=en-US`, GetRequestOptions(token))
  .then(handleErrors2)    
  .then(response => response.json())
  .then((data) => {
    return {
      data: data.Obj.Query_PurchaseInvoiceListing,
      totalCount: data.Obj.RowCount,
      totalSum: data.Obj.RowSum,
      LinkBtnUrlAnalysis : data.Obj.LinkBtnUrlAnalysis,
      LinkBtnUrlDocumentAction: data.Obj.LinkBtnUrlDocumentAction
    };
  });
}

export const CancelPurchaseInvoice = (token, PIH_ID, companyID) =>
{ 
  return fetch(`${process.env.REACT_APP_API_CANCEL_PURCHASE_INVOICE_HDR}PIHID=${PIH_ID}&companyID=${companyID}&UI-Culture=en-US`, POSTRequestOptions(token))
  .then(handleErrors2)    
  .then(response => response.json())
  .then((data) => {
    return data
  });
}



//handle errors
//function handleErrors(response) {
//    if (!response.ok) throw new Error(response.status);
//    return response;
//}
  
function handleErrors2(response) {
    if (!response.ok) throw new Error("Session Expired! Please login again.");
    return response;
}
  
//#endregion

var date_Type = DefaultPIDateTypeValue;
var selected_Status = InitialSelectedDocStatus;

export const DateType = () =>{
  return date_Type;
};

export const SelectedStatus = () =>{
  return selected_Status;
};

export const SetDateType = (dateType) => {
  date_Type = dateType;
}

export const SetSelectedStatus = (selectedStatus) => {
  selected_Status = selectedStatus;
}
  