import React from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  ColumnChooser,
  Export,
  Button,
  Editing,
  Scrolling,
  Summary, TotalItem
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

//const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const REACT_APP_API_URL = process.env.REACT_APP_API_URL + process.env.REACT_APP_GET_ITEM_MASTER_URL

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

const dataSource = new CustomStore({
  key: 'ImId',
  load: function(loadOptions) {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary'
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i]))
      { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
    });
    params = params.slice(0, -1);
    return fetch(`${REACT_APP_API_URL}${params}`)
      .then(response => response.json())
      .then((data) => {
        return {
          data: data.Obj.Items,
          totalCount: data.Obj.Items.length,
        };
      })
      .catch(() => { throw new Error('Data Loading Error'); });
  },
});

const renderGridCell = (data) => {
  return <a href={"/#/itemdetails/"} target="_self">{ data.data.ImCode }</a>
}

const renderAddNewHeader = (data) => {
  return <i className ="dx-link dx-icon-add" onClick={onClickNewItemDetails}></i>;
}

function onClickNewItemDetails(e) {
  e.preventDefault();
  console.log('The link was clicked.');
  window.open("/#/itemdetails/", "_self");
}


function Items () {
  //const [searchValue, setSearchValue] = useState('');
  //const priceAmountFormat = { style: 'currency', currency: 'MYR', useGrouping: true, minimumSignificantDigits: 3 };

  function allowDeleting(e) {
    return true;
  }

  function calculateQtyOnHand(rowData) {
    let total = 0;
    rowData.ItemWarehouses.forEach((data) => {
    total += data.IwQtyOnHand});
    return total;
  }
  
  function calculateQtyReserved(rowData) {
      let total = 0;
      rowData.ItemWarehouses.forEach((data) => {
      total += data.IwQtySo});
      return total;
  }
  function calculateQtyAvailable(rowData) {
      let total = 0;
      rowData.ItemWarehouses.forEach((data) => {
      total += data.IwQtyAvailable});
      return total;
  }
  function calculateQtyOrdered(rowData) {
      let total = 0;
      rowData.ItemWarehouses.forEach((data) => {
      total += data.IwQtyPo});
      return total;
  }

  function calculateSelectedRow(options) {
    if (options.name === 'SelectedRowsSummary') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      } else if (options.summaryProcess === 'calculate') {
        if (options.component.isRowSelected(options.value.ID)) {
          options.totalValue = options.totalValue + options.value.SaleAmount;
        }
      }
    }
  }

  function onOptionChanged(e) {
    if(e.fullName === "searchPanel.text") {
        //setSearchValue(e.value);
    }
  }

    return (
      <React.Fragment>
        <h6 className={'content-block'}>Items</h6>
      
        <DataGrid
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={true}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          onOptionChanged={onOptionChanged}
        >
          <Paging defaultPageSize={10} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <FilterRow visible={false} />
          <SearchPanel visible={true} />
          <ColumnChooser
              enabled={true}
              mode="select"
          />
          <Scrolling 
            mode="standard" 
            useNative={false}
            scrollByContent={true}
            scrollByThumb={true}
            showScrollbar="always"/>
          <Export enabled={true} />
          <Editing
              mode="row"
              useIcons={true}
              allowUpdating={true}
              allowDeleting={allowDeleting} />
          <Column type="buttons" width={110} headerCellRender={renderAddNewHeader}>
            <Button name="edit" />
            <Button name="delete" />
            <Button hint="Clone" icon="repeat" visible={true}/>
          </Column>
          <Column dataField={'ImId'} width={90} visible={false}/>
          <Column
            dataField={'ImCode'}
            width={190}
            caption={'Item Code'}
            cellRender={renderGridCell}
          />
          <Column
            dataField={'ImDescription'}
            caption={'Description'}
          />
          <Column
            dataField={'ImDescription2'}
            caption={'2nd Description'}
          />
          <Column
            dataField={'ImCategory'}
            visible={false}
          />
          <Column
            dataField={'ImItemGroup'}
            visible={false}
          />
          <Column
            dataField={'ImType'}
            visible={false}
          />
          <Column
            dataField={'ImDateCreated'}
            caption={'Created Date'}
            dataType={'datetime'}
          />
          <Column
            dataField={'ImCostingCode'}
            caption={'Costing Method'}
          />
          <Column
            dataField={'Vendor Item'}
            caption={'ImVendorItem'}
          />
          <Column
            dataField={'ImDiscontinueYn'}
            caption={'Discontinue'}
            dataType={'bool'}
          />
          <Column
            dataField={'ImCategoryNavigation.IcCode'}
            caption={'Item Category'}
          />              
          <Column
            dataField={'ImItemGroupNavigation.IgCode'}
            caption={'Item Group'}
          />
          <Column
            dataField={'ImSizeNavigation.IsCode'}
            caption={'Size'}
          />
          <Column
            dataField={'ImColorNavigation.IcCode'}
            caption={'Color'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuUomNavigation.UomCode'}
            caption={'Base UOM'}
          />
          <Column
            dataField={'ImReportUomNavigation.IuUomNavigation.UomCode'}
            caption={'Report UOM'}
          />
          <Column
            dataField={'ImDefaultSalesUomNavigation.IuUomNavigation.UomCode'}
            caption={'Sales UOM'}
          />              
          <Column
            dataField={'ImDefaultPurchaseUomNavigation.IuUomNavigation.UomCode'}
            caption={'Purchase UOM'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice1'}
            caption={'Price 1'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice2'}
            caption={'Price 2'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice3'}
            caption={'Price 3'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice4'}
            caption={'Price 4'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice5'}
            caption={'Price 5'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBaseUomNavigation.IuSalesPrice6'}
            caption={'Price 6'}
            format = {'currency'}
          />
          <Column
            dataField={'ImBrandNavigation.IbCode'}
            caption={'Item Brand'}
          />
          <Column
            dataField={'ImTypeNavigation.ItCode'}
            caption={'Item Type'}
          />
          <Column
            dataField={'ImModelNavigation.ImCode'}
            caption={'Item Model'}
          />
          <Column
            dataField={'ImManufacturer'}
            caption={'Manufacturer'}
          />
          <Column
            dataField={'ImControlAccountType'}
            caption={'Control Account Type'}
          />
          <Column
            dataField={'ImPointEntitleYn'}
            caption={'Point Entitle'}
          />
          <Column
            dataField={'ImNonSaleItemYn'}
            caption={'Non Saleable Item'}
          />
          <Column
            dataField={'ImRedemptionItemYn'}
            caption={'Redemption Item'}
          />
          <Column
            dataField={'ImRedemptionPoint'}
            caption={'Redemption Point'}
          />
         <Column
              caption="Qty On Hand"
              calculateCellValue={calculateQtyOnHand}
          />
          <Column
              caption="Qty Reserved"
              calculateCellValue={calculateQtyReserved}
              />
          <Column
              caption="Qty Available"
              calculateCellValue={calculateQtyAvailable}
          />
          <Column
              caption="Qty Ordered"
              calculateCellValue={calculateQtyOrdered}
          />
          <Summary calculateCustomSummary={calculateSelectedRow}>
            <TotalItem
              summaryType="count"
              displayFormat="Count: {0}"
              showInColumn="ImCode" />
          </Summary>
        </DataGrid>
      </React.Fragment>
    );
  
}
export default Items;
