import React, { Component } from 'react'

export const customSearch = ({ allowSearch, link, searchText = "", onClick = () => { }, textHideable }) => function (props) {
    if (textHideable && props.data.hideText) return "";

    return (
        <CustomSearch
            {...props}
            allowSearch={allowSearch}
            link={link}
            searchText={searchText}
            onClick={onClick}
        />
    )
}

export class CustomSearch extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.props.onClick(this.props.data);
    };

    searchableCell() {
        const searchQuery = this.props.searchText.trim();

        if (searchQuery.length === 0) {
            return this.normalCell();
        }

        const regExpText = "(".concat(
            searchQuery
                .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
                .split(" ").filter(el => el.length > 0)
                .sort((a, b) => b.length - a.length)
                .join(")|(")
                .concat(")")
        );

        const regExp = new RegExp(regExpText, "ig");

        try {
            const matches = (this.props.text.match(regExp)) ? this.props.text.match(regExp).filter((el) => el !== undefined && el !== 'undefined' && el != null) : [];

            return (
                <div onClick={this.onClick} className={`${this.props.link ? "datagrid-data-link" : ""}`}>
                    {
                        this.props.text
                            .split(regExp)
                            .filter((el) => el !== undefined && el !== 'undefined' && el != null)
                            .map((el, index) => {
                                if (el === matches[0]) {
                                    matches.shift();
                                    return (
                                        <span key={index} className="dx-datagrid-search-text">
                                            {el}
                                        </span>
                                    );
                                } else {
                                    return el;
                                }
                            })
                    }
                </div>
            );
        }
        catch (error) { }

        return this.normalCell();
    }

    normalCell() {
        return (
            <div onClick={this.onClick} className={`${this.props.link ? "datagrid-data-link" : ""}`}>
                {this.props.text}
            </div>
        );
    }

    render() {
        return this.props.allowSearch ? this.searchableCell() : this.normalCell();
    }
}
