import "./popup-module-editor.scss";

import classNames from 'classnames';
import React from "react";

import Button from "../app-editor/components/button/button";
import DefaultDialog from "../../../components/custom-dialog/default-dialog";
import styles from './popup-module-editor.module.scss';

export default function DeleteMenuPopup(props) {
    const { onHiding, onConfirm, title, message, messageWarning } = props;

    const _onConfirm = async () => {
        try {
            onConfirm?.();
        } catch (error) {
            console.error(error);
        }
    };

    const _onHiding = () => {
        onHiding(false);
    };

    return (
        <DefaultDialog
            title={title}
            width={400}
            height={244}
            onClose={_onHiding}
            titlePosition="center"
        >
            <div className={classNames(styles.content, styles.bodyPad, styles.contentCenter)} >
                <span className={styles.defaultText}>{message}</span>
                <span className={styles.redText}>{messageWarning}</span>
            </div>
            <div className={classNames(styles.footerContainer, styles.deleteMenuFooter)}>
                <Button
                    variant="normal"
                    onClick={_onHiding}
                    className={styles.cancelBtn}
                >
                    Cancel
                </Button>
                <Button
                    className={styles.deleteBtn}
                    onClick={_onConfirm}
                >
                    Delete
                </Button>
            </div>
        </DefaultDialog>
    );
}
