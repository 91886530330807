export const DefaultPIDateTypeSource =  
[
    {
        display : 'Created Date',
        value   : 'PIH_DateCreated'
    },
    {
        display: 'Document Date',
        value   : 'PIH_DocDate'
    },
    {
        display: 'Posting Date',
        value   : 'PIH_PostingDate'
    }
]

export const DefaultPIDateTypeValue = 'PIH_DocDate';