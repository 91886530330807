import React, { Component } from "react";
import { formatMessage } from "devextreme/localization";
import ReactTooltip from "react-tooltip";
import { TrashIcon, WrenchIcon } from "../../../assets";

export class EditBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.data = this.props.data;
    this.props.onClick(event);
  }

  render() {
    return (
      <div>
        {this.props.enabled ? (
          <div
            data-tip={formatMessage("EditDetails[Enter]")}
            data-for="edit"
            onClick={this.onClick}
            className="icon-edit-icon"
          >
            <ReactTooltip
              id="edit"
              arrowColor="#474751"
              place="bottom"
              effect="solid"
              className="dataGridTooltips"
            />
          </div>
        ) : (
          <div className="disable-view-dtl">
            <div
              data-tip={formatMessage("EditDetails[Enter]")}
              data-for="edit"
              className="icon-edit-icon"
            >
              <ReactTooltip
                id="edit"
                arrowColor="#474751"
                place="bottom"
                effect="solid"
                className="dataGridTooltips"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export class ConfigurationBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.data = this.props.data;
    this.props.onClick(event);
  }

  render() {
    return (
      <div>
        {this.props.enabled ? (
          <div
            data-tip={"Screen Configuration"}
            data-for="configuration"
            onClick={this.onClick}
            style={{ cursor: "pointer", marginRight: 8 }}
          >
            <ReactTooltip
              id="configuration"
              arrowColor="#474751"
              place="bottom"
              effect="solid"
              className="dataGridTooltips"
            />
            <WrenchIcon />
          </div>
        ) : (
          <div className="disable-view-dtl">
            <div
              data-tip={"Screen Configuration"}
              data-for="configuration"
              style={{ marginRight: 8 }}
            >
              <ReactTooltip
                id="configuration"
                arrowColor="#474751"
                place="bottom"
                effect="solid"
                className="dataGridTooltips"
              />
              <WrenchIcon />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export class DeleteBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    event.data = this.props.data;
    this.props.onClick(event);
  }

  render() {
    return (
      <div>
        {this.props.enabled ? (
          <div
            data-tip={"Delete"}
            data-for="delete"
            onClick={this.onClick}
            style={{ cursor: "pointer" }}
          // className="icon-edit-icon"
          >
            <ReactTooltip
              id="delete"
              arrowColor="#474751"
              place="bottom"
              effect="solid"
              className="dataGridTooltips"
            />
            <TrashIcon color="#20ADEB" />
          </div>
        ) : (
          <div className="disable-view-dtl">
            <div
              data-tip={"Delete"}
              data-for="delete"
            // className="icon-edit-icon"
            >
              <ReactTooltip
                id="delete"
                arrowColor="#474751"
                place="bottom"
                effect="solid"
                className="dataGridTooltips"
              />
              <TrashIcon color="#20ADEB" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const actionButtonCell = (action) =>
  function (props) {
    const {
      edit: { onClick: onEditClick, enabled: editEnabled },
      configuration: {
        onClick: onConfigurationClick,
        enabled: configurationEnabled,
      },
      delete: {
        onClick: onDeleteClick, enabled: deleteEnabled
      }
    } = action;

    return (
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <EditBtn {...props} onClick={onEditClick} enabled={editEnabled} />
        <ConfigurationBtn
          {...props}
          onClick={onConfigurationClick}
          enabled={configurationEnabled}
        />
        {!props.data.UT_SystemTable_YN && (
          <DeleteBtn
            {...props}
            onClick={onDeleteClick}
            enabled={deleteEnabled}
          />
        )}

      </div>
    );
  };
