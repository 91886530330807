import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export const FormContext = React.createContext({
  values: {},
});

export default function HOCInput(props) {
  const { keys, children } = props;
  const { control } = useFormContext();
  const values = useWatch({ name: keys, control });

  const transformedValues = values.reduce((prev, next, idx) => {
    return {
      ...prev,
      [keys[idx]]: next,
    };
  }, {});

  const value = React.useMemo(
    () => ({
      values: transformedValues,
    }),
    [transformedValues],
  );

  return (
    <FormContext.Provider value={value}>
      {typeof children === 'function' ? children(transformedValues) : children}
    </FormContext.Provider>
  );
}

export function useFormValueState() {
  const context = React.useContext(FormContext);

  if (context === undefined) {
    throw new Error('useFormValueState must be used inside Form Container')
  }

  return context;
}
