import React, { useImperativeHandle, useRef, useState } from 'react';
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

const TextboxChecker = React.forwardRef((props, ref) => {

    const [isValid, setIsValid] = useState(false)
    const inputRef = useRef();

    //handling value
    const onChange = (event) => {
        props.onChange(event)
    }

    //onFocus event
    const activate = () => {
        inputRef.current.focus({ preventScroll: true });
    }

    useImperativeHandle(ref, () => {
        return { focus: activate }
    })

    //onFocus - border color
    const onFocus = () => {
        if(!isValid){
            setIsValid(true)
        }
    }

    const onBlur = () => {
        if(isValid){
            setIsValid(false)
        }
    }

    const ShowAttentionButton = () => {
        return (
            <div className="dx-texteditor-buttons-container button-is-required">   
                <div className="dx-button dx-button-default dx-button-mode-contained dx-widget dx-button-has-icon dx-state-independent " role="button">
                    <div data-tip={formatMessage('ADuplicateMappingDigitisNotAllow')} data-for="attention" className="dx-button-content">
                        <i className="dx-icon glyphicon icon-attention-icon-icon"/>
                        <ReactTooltip id="attention" place="bottom" effect="solid" arrowColor="#474751" offset={{left: -19}}/>
                    </div> 
                </div>
            </div>
        )
    }
    
    const ShowRequireButton = () => {
        return (
            <div className="dx-texteditor-buttons-container button-is-required">   
            <div className="dx-button dx-button-default dx-button-mode-contained dx-widget dx-button-has-icon dx-state-independent " role="button">
                <div className="dx-button-content">
                    <p className="require">{formatMessage('Required')}</p>
                </div>
            </div>
            </div>
        )
    }
    
    const ShowInvalidValueButton = () => {
        return (
            <div className="dx-texteditor-buttons-container button-is-required2">   
            <div className="dx-button dx-button-default dx-button-mode-contained dx-widget dx-button-has-icon dx-state-independent " role="button">
                <div className="dx-button-content">
                    <p className="invalid-value-message">{formatMessage('InvalidValue')}</p>
                </div>
            </div>
            </div>
        )
    }

    return (
        <>
        <div className= "cost-mapping-header-container" style={{display: props.header}}>
            <div className="cost-mapping-border">
                <div className="cost-mapping-row">
                    <div className="cost-mapping-column1">
                        <p className="cost-mapping-title">
                            {formatMessage('SystemDigit')}
                        </p>
                    </div>
                    <div className="cost-mapping-column2">
                        <p className="cost-mapping-title">
                            {formatMessage('MappingDigit')}
                        </p>
                    </div>
                    <div className="cost-mapping-column3">
                        <span className="icon-message icon-notice-icon-icon"></span>
                        <span className="message-header3">
                            {formatMessage('UppercaseLettersNumbersandSymbols')}
                        </span>
                    </div>
                </div>  
            </div>
        </div>
        <div className="cost-mapping-container">
            <div className="cost-mapping-border ">
                <div id='cost-mapping-texteditor' className={`dx-show-invalid-badge dx-textbox dx-texteditor dx-editor-outlined dx-texteditor-empty dx-widget ${(isValid)? 'dx-state-focused': ''}`}>
                    <div className="dx-texteditor-container">
                        <div className='cost-mapping-label'>
                            <p className="label">{props.label}</p>
                        </div>
                        <input
                            align           = {props.align}
                            id              = {props.id}
                            name            = {props.name}
                            value           = {props.value}
                            readOnly        = {props.readOnly}
                            disabled        = {props.disabled}
                            tabIndex        = {props.tabIndex}
                            maxLength       = {props.maxLength}
                            ref             = {inputRef}
                            onBlur          = {onBlur}
                            onFocus         = {onFocus}
                            onChange        = {onChange}
                            autoComplete    = {'off'}
                            autoCapitalize  = {'none'}
                            type            = {'text'} 
                            spellCheck      = {'false'} 
                            role            = {'textbox'}
                            className       = {'dx-texteditor-input'}/>
                    </div>
                </div>
                {(props.checker === 1)? <ShowAttentionButton /> : 
                (props.checker === 2)? <ShowRequireButton /> : 
                (props.checker === 3)? <ShowInvalidValueButton /> : null}
            </div>
        </div>
    </>
    )
})

export default TextboxChecker;



