import * as React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#2977B6", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={width}
      height={height}
      ref={ref}
      fill={color}
      {...rest}
    >
      <path d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88 88.1 88.1 0 0 1-88 88Zm0-144a56 56 0 1 0 56 56 56.06 56.06 0 0 0-56-56Zm0 96a40 40 0 1 1 40-40 40 40 0 0 1-40 40Z" />
    </svg>
  );
};

const RadioCircleIcon = forwardRef(SvgComponent);

export default RadioCircleIcon;
