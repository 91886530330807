import * as React from 'react';
import './radio-group.scss';
import { RadioGroup } from 'devextreme-react';

export default function DefaultRadioGroup(props) {
    const { items, label, hideColon, displayStar, displayValue, onValueChanged, ...rest } = props;

    const rg_onValueChanged = (event) => {
        onValueChanged?.(event.value)
    }

    return (
        <div className="radioGroupContainer">
            <div className={`dx-field labelContainer`}>
                <div className={`dx-field-label label`}>{label}</div>
                {displayStar ? (<span style={{ color: 'red', marginRight: 4 }}> * </span>) : ""}{!hideColon && " :"}
                {displayValue === '' ? <span className="required-field">Required</span> : null}
            </div>
            <div className={`dx-field-value bodyContainer`}>
                <RadioGroup
                    {...rest}
                    className="radioGroup"
                    onValueChanged={rg_onValueChanged}
                    items={items}
                />
            </div>
        </div>
    );
}