import React from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { } from 'devextreme/exporter'
import './data-grid-context-menu.scss';
//import font .ttf
import { formatDate, formatMessage } from 'devextreme/localization';

const { forwardRef, useImperativeHandle } = React;

// Function to flatten the tree structure while preserving the hierarchy
const flattenTree = ({ dateFormat, treeData, parentId = 0, level = 0, isCSV }) => {
  let result = [];
  treeData.forEach(node => {
    if (node.parentId === parentId) {
      const formatedDateModified = formatDate(new Date(node.dateModified), dateFormat);
      const formatedDateCreated = formatDate(new Date(node.dateCreated), dateFormat);
      result.push({
        level: level,
        name: node.name,
        caption: node.caption.toString(),
        type: node.type.toString(),
        dateModified: isCSV ? `"${formatedDateModified}"` : formatedDateModified,
        dateCreated: isCSV ? `"${formatedDateCreated}"` : formatedDateCreated,
        activeYn: node.activeYn.toString(),
        sequence: node.sequence.toString(),
      });
      // Recursively process children nodes
      result = result.concat(flattenTree({ dateFormat, treeData, parentId: node.id, level: level + 1, isCSV }));
    }
  });
  return result;
};

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const TreeListChildShowFilterRow = forwardRef((props, ref) => {

  const sendFilterRowToParent = () => {
    //here calling Parents changeValue
    props.getChildFilterRow(true);
  }

  const sendRestoreLayoutToParent = () => {
    //here calling parents restore layout function
    props.restoreLayout();
    props.showRestoreMsg(formatMessage('RestoredSuccessfully'), 'success');
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({

    TreeListContextMenuItemsService(e, TreeListInstance) {
      const pdfdocumentView = () => {
        // //get based on custom columns 
        // const columns = TreeListInstance._views.headerPanel._columnsController._visibleColumns

        // columns.map(x => {
        //   console.log(x)
        //   //more than 7 above - landscape
        //   if (x.length >= 7) {
        //     exportGridPdf('l');
        //   }
        //   //less than 6 column - portrait
        //   else if (x.length <= 6) {
        //     exportGridPdf('p');
        //   }
        // });

        exportGridPdf('l');
      }

      const exportGridPdf = (pdfMode) => {
        var today = new Date();
        const doc = new jsPDF(pdfMode);
        const flattenedData = flattenTree({ dateFormat: props.dateFormat, treeData: props.data });
        const tableData = flattenedData.map(item => [
          ' '.repeat(item.level * 4) + item.name, // Indent based on level
          item.caption,
          item.type,
          item.dateModified,
          item.dateCreated,
          item.activeYn,
          item.sequence,
        ]);

        const headers = [["Code", "Name", "Type", "Modified On", "Created On", "Active", "Sequence"]];

        doc.autoTable({
          head: headers,
          body: tableData,
          theme: 'grid',
          headStyles: {
            fillColor: "#FFFFFF",
            textColor: "#000000",
            // lineColor: 20,
            lineWidth: 0.1,
          },
          startY: 20, // Adjust as needed for positioning
          styles: {
            cellPadding: 3,
            fontSize: 10,
          },
          columnStyles: {
            0: { cellWidth: 50 }, // Code column width
            1: { cellWidth: 50 }, // Name column width
            2: { cellWidth: 30 }, // Type column width
            3: { cellWidth: 40 }, // Modified On column width
            4: { cellWidth: 40 }, // Created On column width
            5: { cellWidth: 20 }, // Active column width
            6: { cellWidth: 30 }, // Sequence column width
          },
        });

        // Save the generated PDF
        doc.save('Module-Editor-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '.pdf');
      }

      const exportGridCSV = () => {
        var today = new Date();
        const flattenedData = flattenTree({ dateFormat: props.dateFormat, treeData: props.data, isCSV: true });
        const headers = ["Code", "Name", "Type", "Modified On", "Created On", "Active", "Sequence"];
        const tableData = flattenedData.map(item => [
          ' '.repeat(item.level * 4) + item.name, // Indent based on level
          item.caption,
          item.type,
          item.dateModified,
          item.dateCreated,
          item.activeYn,
          item.sequence,
        ]);

        // Combine headers and rows
        const csvContent = [
          headers.join(','),        // Column headers
          ...tableData.map(row => row.join(',')) // Rows as CSV string
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link to trigger the CSV download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'Module-Editor-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      const exportGridXLSX = async () => {
        var today = new Date();
        const flattenedData = flattenTree({ dateFormat: props.dateFormat, treeData: props.data });
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        worksheet.addRow(["Code", "Name", "Type", "Modified On", "Created On", "Active", "Sequence"]);

        flattenedData.forEach(item => {
          worksheet.addRow([
            ' '.repeat(item.level * 4) + item.name, // Indent based on level
            item.caption,
            item.type,
            item.dateModified,
            item.dateCreated,
            item.activeYn,
            item.sequence,
          ])
        })

        worksheet.getRow(1).font = { bold: true };

        // Generate the Excel file and download it
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'Module-Editor-' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '.xlsx');
      }

      if (e.target === 'header' || e.target === 'headerPanel') {
        // e.items can be undefined
        if (!e.items) e.items = [];

        var groupByThisColumnItem = e.items && e.items.find(i => i.value === "group")
        if (groupByThisColumnItem) {
          groupByThisColumnItem.icon = 'glyphicon icon-group-column-icon';
        }

        var ungroupAll = e.items && e.items.find(i => i.value === "ungroupAll")
        if (ungroupAll) {
          ungroupAll.icon = 'minus';
          var groupCount = e.component.columnOption("groupIndex:0");
          if (!groupCount) {
            e.items.forEach((item) => {
              if (item.value === "ungroupAll")
                item.disabled = true;
            })
          }
        }

        // Add a custom menu item
        e.items.push(
          {
            text: formatMessage('RestoreDefaultLayout'),
            icon: 'glyphicon icon-restore-default-icon',
            beginGroup: 'true',
            onItemClick: () => {
              sendRestoreLayoutToParent();
            },
          },
          {
            text: formatMessage('dxDataGrid-columnChooserTitle'),
            icon: 'glyphicon icon-column-chooser-icon',
            onItemClick: () => {
              console.log(e.column.caption);
              TreeListInstance.showColumnChooser();
            },
          },
          {
            text: formatMessage('dxDataGrid-applyFilterText'),
            icon: 'glyphicon icon-filter-row-icon',
            beginGroup: 'true',
            onItemClick: () => {
              sendFilterRowToParent();
            },
          },
          {
            text: formatMessage('dxDataGrid-exportTo'),
            icon: 'glyphicon icon-export-icon',
            onItemClick: () => {
              console.log(e.column.caption);
            },
            items: [
              {
                text: formatMessage('dxDataGrid-exportToPdf'),
                icon: 'glyphicon icon-pdf-red-icon',
                onItemClick: function (args) {
                  console.log(e.column.caption);
                  pdfdocumentView();
                }
              },
              {
                text: formatMessage('dxDataGrid-exportToExcel'),
                icon: 'glyphicon icon-xlsx-green-icon',
                onItemClick: function (args) {
                  console.log(e.column.caption);
                  exportGridXLSX();

                }
              },
              {
                text: formatMessage('dxDataGrid-exportToCsv'),
                icon: 'glyphicon icon-csv-greenblue-icon',
                onItemClick: function (args) {
                  console.log(e.column.caption);
                  exportGridCSV();
                }
              }]
          }
        );

        if (props.displayRestoreLayout === false) {
          e.items = e.items.filter(x => x.text !== formatMessage('RestoreDefaultLayout'))
        }

        if (props.displayColumnChooser === false) {
          e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-columnChooserTitle'))
        }

        if (props.displayExportGrid === false) {
          e.items = e.items.filter(x => x.text !== formatMessage('dxDataGrid-exportTo'))
        }
      }
    }
  }));

  return <div></div>;
});

export default TreeListChildShowFilterRow;
