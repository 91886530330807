import React, { Suspense } from 'react';

const infiniteThenable = { then() {} };

function Suspender({
  freeze,
  children,
}) {
  if (freeze) {
    throw infiniteThenable;
  }
  return <>{children}</>;
}


export function Freeze({ freeze, children, placeholder = null }) {
  return (
    <Suspense fallback={placeholder}>
      <Suspender freeze={freeze}>{children}</Suspender>
    </Suspense>
  );
}
