import { useEffect, useRef } from "react";
import { useWebStudio } from "./web-studio";

export default function TableCard(props) {
  const { id, w, h, x, y, minW, minH, maxW, maxH, actions, children } = props;
  const ref = useRef(null);
  const { setFocusWidget, state } = useWebStudio();

  useEffect(() => {
    actions.handleRemove(ref.current, false);
    actions.handleAdd(ref.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFocus = state?.id === id;

  return (
    <div
      ref={ref}
      id={`${id}`} // convert to string
      className={"grid-stack-item"}
      gs-w={w}
      gs-h={h}
      gs-min-w={minW}
      gs-min-h={minH}
      gs-max-w={maxW}
      gs-max-h={maxH}
      gs-x={x}
      gs-y={y}
      onClick={() => {
        setFocusWidget(props, actions.handleRemove, ref.current);
      }}
    >
      <div
        className={`grid-stack-item-content ${
          isFocus ? "grid-stack-item-content-highlight" : ""
        }`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: isFocus ? undefined : "#F6F6F7",
        }}
      >
        {children}
      </div>
    </div>
  );
}
