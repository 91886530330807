import { useController } from "react-hook-form";
import { useWebStudio } from "./web-studio";
import {
  CalendarIcon,
  CheckSquareIcon,
  HashIcon,
  LinkSimpleHorizontalIcon,
  ListDashesIcon,
  NumberZeroIcon,
  RadioCircleIcon,
  TabsIcon,
  TextAaIcon,
  TextAlignLeftIcon,
} from "../../../../assets";
import DefaultTextArea from "../../../../components/text-area/text-area";
import DefaultNumberBox from "../../../../components/number-box/number-box";
import DefaultDateBox from "../../../../components/date-box/date-box";
import DefaultCheckbox from "../../../../components/check-box/check-box";
import DefaultSelectBox from "../../../../components/select-box/select-box";
import DefaultTextBox from "../../../../components/text-box/text-box";
import Button from "./button/button";

function useFormController({ name }) {
  const { isEdit } = useWebStudio();
  let controller;
  if (!isEdit) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    controller = useController({ name });
  }
  return { controller, isEdit };
}

export const WIDGET_COMPONENTS = ["tabs"];
export const WIDGET_NEW_FIELDS = [
  "textField",
  "multilineField",
  "integerField",
  "decimalField",
  "dateField",
  "checkboxField",
  "selectionField",
  "relatedField",
];

export const WIDGETS = {
  tabs: {
    w: 12,
    h: 4,
    label: "Tabs",
    minW: 12,
    maxW: 12,
    icon: <TabsIcon />,
    childrenable: true,
    Component: ({ text }) => {
      return <div />;
    },
  },
  button: {
    w: 1,
    h: 1,
    label: "Button",
    icon: <RadioCircleIcon />,
    Component: ({ label = "Button", onClick, buttonType = "button" }) => {
      const { isEdit } = useWebStudio();
      return (
        <Button
          style={{ width: "100%", minWidth: 0 }}
          onClick={onClick}
          type={buttonType}
          children={label}
          disabled={isEdit}
        />
      );
    },
  },
  textField: {
    Component: ({
      fieldName,
      type,
      label,
      defaultValue,
      placeholder,
      size,
      labelWidth,
      required,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });
      return (
        <DefaultTextBox
          {...(controller
            ? {
              onTbvalueChange: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          readOnly={isEdit}
          caption={label}
          placeholder={placeholder}
          stylingMode="outlined"
          maxLength={size}
          direction="horizontal"
          align="center"
          isResetStyle
          displayStar={required}
          labelWidth={labelWidth}
          className="full-height"
        />
      );
    },
    label: "Text",
    name: "name",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <TextAaIcon />,
  },
  multilineField: {
    Component: ({
      fieldName,
      type,
      label,
      defaultValue,
      placeholder,
      size,
      required,
      labelWidth,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });

      return (
        <DefaultTextArea
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          placeholder={placeholder}
          maxLength={size}
          direction="horizontal"
          isResetStyle
          displayStar={required}
          className="full-height"
        />
      );
    },
    label: "Multiline Text",
    name: "multiline",
    placeholder: "Placeholder",
    w: 5,
    h: 2,
    minH: 2,
    icon: <TextAlignLeftIcon />,
  },
  integerField: {
    Component: ({
      fieldName,
      type,
      label,
      defaultValue,
      placeholder,
      minimumValue,
      maximumValue,
      required,
      labelWidth,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });
      const _placeholder = placeholder || "0";

      return (
        <DefaultNumberBox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          placeholder={_placeholder}
          min={minimumValue}
          max={maximumValue}
          direction="horizontal"
          isResetStyle
          displayStar={required}
          align="center"
          className="full-height"
        />
      );
    },
    label: "Integer",
    name: "number",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <HashIcon />,
  },
  decimalField: {
    Component: ({
      fieldName,
      label,
      defaultValue = 0.0,
      minimumValue,
      maximumValue,
      decimalPoint,
      placeholder,
      required,
      labelWidth,
    }) => {
      const fixed = "0".repeat(decimalPoint !== undefined ? decimalPoint : 1);
      const { controller, isEdit } = useFormController({ name: fieldName });
      const _placeholder = placeholder || `0.${fixed}`;

      return (
        <DefaultNumberBox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          defaultValue={defaultValue}
          placeholder={_placeholder}
          format={`#.${fixed}`}
          min={minimumValue}
          max={maximumValue}
          direction="horizontal"
          displayStar={required}
          isResetStyle
          align="center"
          className="full-height"
        />
      );
    },
    label: "Decimal",
    name: "floater",
    placeholder: "Placeholder",
    decimal_point: 2,
    maximum_value: 1,
    minimum_value: 1,
    w: 5,
    h: 1,
    maxH: 1,
    icon: <NumberZeroIcon />,
  },
  dateField: {
    Component: ({
      labelWidth,
      fieldName,
      type,
      label,
      defaultValue,
      required,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });

      return (
        <DefaultDateBox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          defaultValue={defaultValue}
          direction="horizontal"
          isResetStyle
          displayStar={required}
          align="center"
          className="full-height"
        />
      );
    },
    label: "Date",
    name: "date",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <CalendarIcon />,
  },
  checkboxField: {
    Component: ({ labelWidth, fieldName, type, label, defaultValue }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });
      return (
        <DefaultCheckbox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          label={label}
          defaultValue={defaultValue}
          isResetStyle
          align="center"
          className="full-height"
          justify="between"
        />
      );
    },
    label: "Checkbox",
    name: "checkbox",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <CheckSquareIcon />,
  },
  selectionField: {
    Component: ({
      fieldName,
      type,
      label,
      defaultValue,
      options,
      required,
      labelWidth,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });
      return (
        <DefaultSelectBox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          defaultValue={defaultValue}
          items={options}
          direction="horizontal"
          isResetStyle
          displayStar={required}
          align="center"
          className="full-height"
        />
      );
    },
    label: "Selection",
    name: "select",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <ListDashesIcon />,
  },
  relatedField: {
    Component: ({
      fieldName,
      type,
      label,
      defaultValue,
      options,
      required,
      labelWidth,
    }) => {
      const { controller, isEdit } = useFormController({ name: fieldName });
      return (
        <DefaultSelectBox
          {...(controller
            ? {
              onValueChanged: (value) => controller.field.onChange(value),
              value: controller.field.value,
            }
            : {})}
          labelWidth={labelWidth}
          disabled={isEdit}
          caption={label}
          defaultValue={defaultValue}
          items={options}
          direction="horizontal"
          isResetStyle
          displayStar={required}
          align="center"
          className="full-height"
        />
      );
    },
    label: "Related Field",
    name: "related",
    placeholder: "Placeholder",
    w: 5,
    h: 1,
    maxH: 1,
    icon: <LinkSimpleHorizontalIcon />,
  },
};
