import React, { useRef } from 'react';
import Form from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
//language
import { formatMessage } from 'devextreme/localization';

class DownloadPopUp extends React.Component {
    constructor(props) {
        super(props);
        this.downloadItem = this.downloadItem.bind(this);
    }

    downloadItem(){
        this.props.parentCallback();
    }
    
    render() {
        return (
            <Popup
                position={'center'}
                className="item-popup-center"
                showTitle={true}
                title={formatMessage("Confirmation")}
                onHiding={this.props.hideDownloadPopup}
                visible={this.props.visible}
                dragEnabled={false}
                closeOnOutsideClick={true}
                width={"auto"}
                height={"auto"}>
                <Form colCount={4}>
                    <Item colSpan={4}>
                        <div className="delete-line-1">{formatMessage("DataIsTooLarge")}</div>
                        <div className="delete-line-2">{formatMessage("DoYouWantToExportToExcel")}</div>
                    </Item>
                </Form>
                    <div className="download-cancel-btn-wrapper">
                        <Button
                            id={!this.props.isDeleteBtnFocus ? 'download-cancel-btn': ''}
                            className="cancel-btn"
                            text={formatMessage("No")}
                            type="normal"
                            stylingMode="contained"
                            onClick={this.props.hideDownloadPopup}>
                        </Button>
                    </div>
                    <div className="download-wrapper">                        
                        <Button
                            id={this.props.isDeleteBtnFocus ? 'downloadBtn': ''}
                            className="download-btn"
                            text={formatMessage("Yes")}
                            onClick={this.downloadItem}
                            type="default"
                            stylingMode="contained">
                        </Button>
                    </div>
            </Popup>
        );
    }
}

export default DownloadPopUp;