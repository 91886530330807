import React, { Component } from 'react'
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

export const dragBtn = (props) => {
    return (
        <DragBtn
            {...props}
        />
    )
}

export class DragBtn extends Component {
    render() {
        return (
            <div data-tip={formatMessage("Draganddroptoadesiredposition")} className="icon-draggable-icon">
                <ReactTooltip arrowColor="#474751" place='top' className="dataGridTooltips" />
            </div>
        )
    }
}
