import { useRef, useEffect } from "react";
import { GridStack } from "gridstack/dist/es5/gridstack";
import { useWebStudio } from "./web-studio";

const commonOptions = {
  column: 12,
  minRow: 4,
  cellHeight: 50,
  cellWidth: 50,
  resizable: {
    handles: "se",
  },
  disableOneColumnMode: true,
  acceptWidgets: true,
  float: true,
};

let subOptions = commonOptions;

export default function Grid({
  children,
  setWidgets: _setWidgets,
  id = "mainGrid",
  nested,
  options,
  isHideGrid,
}) {
  const gridRef = useRef();
  let setWidgets;

  if (typeof _setWidgets !== "undefined") {
    setWidgets = _setWidgets;
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { onChangeWidgets } = useWebStudio();
    setWidgets = onChangeWidgets;
  }

  useEffect(() => {
    gridRef.current = GridStack.init(
      options
        ? options
        : {
            ...commonOptions,

            subGridOpts: subOptions, // all sub grids will default to those
            // removable: "#divForm", // drag-out delete class
            // draggable: {
            //   cancel: "input,textarea,button,select,option,.grid-stack-item-nodrag",
            // }, // example of additional custom elements to skip drag on
          },
      nested ? `grid-nested-${id}` : document.getElementById(id)
    );

    GridStack.setupDragIn(".droppable", {
      appendTo: "body",
      helper: "clone",
      handle: ".grid-stack-item-content",
      cancel: "input,textarea,button,select,option,.grid-stack-item-nodrag",
      // scroll: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const grid = gridRef.current;

    if (grid) {
      // Dropped
      grid.on("dropped", (event, previousWidget, newWidget) => {
        if (previousWidget === undefined && !!newWidget) {
          const { el, w, h, x, y, minW, minH, maxH, maxW } = newWidget;
          const nodeToAdd = { children: [] };
          if (el.dataset.childrenable) {
            newWidget.grid.makeSubGrid(el, nodeToAdd, undefined, false);
            // subGrid.on
          }

          grid.removeWidget(el);
          setWidgets?.((items) => [
            ...items,
            {
              id: new Date().getTime().toString(),
              type: el.dataset.type,
              fieldName: el.dataset.name,
              placeholder: el.dataset.placeholder,
              label: el.dataset.label,
              labelWidth: el.dataset.label_width || 108,
              size: el.dataset.size,
              maximumValue: el.dataset.maximum_value,
              minimumValue: el.dataset.minimum_value,
              decimalPoint: el.dataset.decimal_point,
              options: el.dataset.options,
              required: el.dataset.required || false,
              w,
              h,
              x,
              y,
              minW,
              minH,
              maxW,
              maxH,
              ...(el.dataset.type === "tabs"
                ? { tabs: [], childrenable: true }
                : {}),
            },
          ]);
        }
      });
      // End Dropped
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const grid = gridRef.current;

    if (grid) {
      grid.on("added removed change", (event, previousWidget, newWidget) => {
        setWidgets?.((items) => {
          return (items || []).map((item) => {
            const findValue = previousWidget?.find(
              (curr) => curr.el?.id === item.id
            );
            if (findValue) {
              return {
                ...item,
                x: findValue.x,
                y: findValue.y,
                h: findValue.h,
                w: findValue.w,
              };
            }
            return item;
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = (el) => {
    if (el && gridRef.current) {
      gridRef.current.makeWidget(el);
      // gridRef.current.makeSubGrid(el)
    }
  };

  const handleRemove = (el, actualRemove = true) => {
    if (el && gridRef.current) {
      gridRef.current.removeWidget(el, false);
      actualRemove &&
        setWidgets((items) => items.filter((item) => `${item.id}` !== el.id));
    }
  };

  const handleEnableMove = (flag = true) => {
    if (gridRef.current) {
      gridRef.current.enableMove(flag);
    }
  };

  return (
    <section className={isHideGrid ? undefined : "gridline"}>
      <div
        id="mainGrid"
        className={`grid-stack ${
          nested ? `grid-stack-nested grid-nested-${id}` : ""
        }`}
        // className="grid-stack"
        style={{ width: "100%" }}
      >
        {children({
          handleAdd,
          handleRemove,
          handleEnableMove,
        })}
      </div>
    </section>
  );
}
