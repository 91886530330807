const generalProperty = {
  fieldName: "textField",
  label: "textField",
  labelWidth: "numberField",
  tooltip: "textField",
};

const numberProperty = {
  maximumValue: "numberField",
  minimumValue: "numberField",
};

const defaultValueProperty = {
  text: {
    defaultValue: "textField",
  },
  number: {
    defaultValue: "numberField",
  },
  date: {
    defaultValue: "selectionField",
  },
  selection: {
    defaultValue: "selectionField",
  },
  checkbox: {
    defaultValue: "selectionField",
  },
};

const widgetProperty = {
  textField: {
    unique: "checkboxField",
    ...generalProperty,
    placeholder: "textField",
    size: "numberField",
    ...defaultValueProperty.text,
  },
  multilineField: {
    unique: "checkboxField",
    ...generalProperty,
    placeholder: "textField",
    size: "numberField",
    ...defaultValueProperty.text,
  },
  integerField: {
    unique: "checkboxField",
    ...generalProperty,
    ...numberProperty,
    placeholder: "textField",
    ...defaultValueProperty.number,
  },
  decimalField: {
    unique: "checkboxField",
    ...generalProperty,
    ...numberProperty,
    decimalPoint: "numberField",
    placeholder: "textField",
    ...defaultValueProperty.number,
  },
  dateField: {
    ...generalProperty,
    ...defaultValueProperty.date,
  },
  selectionField: {
    ...generalProperty,
    options: "button-modal",
    // selectionType: "selectionField",
    placeholder: "textField",
    ...defaultValueProperty.selection,
  },
  checkboxField: {
    ...generalProperty,
    ...defaultValueProperty.checkbox,
  },
  relatedField: {
    fieldName: "textField",
    label: "textField",
    labelWidth: "numberField",
    dataTable: "selectionField",
    dataToShow: "selectionField",
    tooltip: "textField",
  },
  button: {
    label: "textField",
    variant: "selectionField",
    buttonType: "textField",
  },
  tabs: {},
};

export default widgetProperty;

export const uniquableFields = [
  "textField",
  "multilineField",
  "integerField",
  "decimalField",
];

export const widgetLabelText = {
  fieldName: "Field Name",
  label: "Label",
  labelWidth: "Label Width (px)",
  tooltip: "Tooltip",
  placeholder: "Placeholder",
  size: "Size",
  defaultValue: "Default Value",
  selectionType: "Type",
  dataTable: "Data Table",
  dataToShow: "Data To Show",
  maximumValue: "Maximum Value",
  minimumValue: "Minimum Value",
  decimalPoint: "Decimal Point",
  variant: "Variant",
  buttonType: "Type",
};

export const TypeSelectionOption = ["Selection"];

export const WIDGET_PROPERTY_TITLE = {
  textField: "Text Properties",
  multilineField: "Multiline Text Properties",
  integerField: "Integer Properties",
  decimalField: "Decimal Properties",
  dateField: "Date Properties",
  selectionField: "Selection Properties",
  checkboxField: "Checkbox Properties",
  relatedField: "Relation Field Properties",
  button: "Button Properties",
  tabs: "Tabs Properties",
};
