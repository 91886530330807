import "../../../../../components/pop-up/popup.scss";
import "gridstack/dist/gridstack.css";
import "gridstack/dist/es5/gridstack-poly.js";
import "gridstack/dist/es5/gridstack-all.js";
import "../../styles.scss";

import { GridStack } from "gridstack/dist/es5/gridstack";
import React, { useEffect, useState } from "react";
import ScrollView from "devextreme-react/scroll-view";
import { useController, useFieldArray, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { Form } from "../../form";
import Button from "../button/button";
import "./selection-popup.scss";
import {
  CheckmarkIcon,
  DotSixVerticalIcon,
  TrashIcon,
} from "../../../../../assets";
import DefaultDialog from "../../../../../components/custom-dialog/default-dialog";

function AppendItem({ append }) {
  const [value, setValue] = useState("");

  return (
    <div className="itemContainer">
      <div className="dotsContainer">
        <DotSixVerticalIcon />
      </div>
      <div className="inputContainer">
        <input
          className="textInput"
          onChange={(event) => setValue(event.currentTarget.value)}
          value={value}
          placeholder="New Value"
        />
      </div>
      <Button
        className={`iconButton checkmarkButton`}
        onClick={() => {
          if (!!value) {
            append({ value });
            setValue("");
          }
        }}
      >
        <CheckmarkIcon />
      </Button>
    </div>
  );
}

function EditItem({ name, remove }) {
  const { field } = useController({ name });
  return (
    <div className={`grid-stack-item-content itemContainer`}>
      <div className={"dotsContainer"}>
        <DotSixVerticalIcon />
      </div>
      <div className={"inputContainer"}>
        <input className={"textInput"} {...field} />
      </div>
      <Button
        className={`iconButton trashButton`}
        onClick={remove}
      >
        <TrashIcon />
      </Button>
    </div>
  );
}

function Items({ control }) {
  const { append, fields, remove } = useFieldArray({ name: "items", control });
  const gridRef = React.useRef();
  const itemsRef = React.useRef({});

  if (Object.keys(itemsRef.current).length !== fields.length) {
    fields.forEach(({ id }) => {
      itemsRef.current[id] = itemsRef.current[id] || React.createRef();
    });
  }

  useEffect(() => {
    gridRef.current =
      gridRef.current ||
      GridStack.init(
        {
          cellHeight: 50,
          minRow: 1,
          column: 1,
          acceptWidgets: true,
          disableResize: true,
        },
        ".option-grid"
      );
  }, [fields]);

  React.useEffect(() => {
    const grid = gridRef.current;
    if (grid) {
      grid.batchUpdate();
      grid.removeAll(false);
      fields.forEach(
        ({ id }) =>
          itemsRef.current[id] && grid.makeWidget(itemsRef.current[id].current)
      );
      grid.batchUpdate(false);
    }
  }, [fields]);

  return (
    <>
      <div className="selection-popup-gridContainer">
        {fields.length !== 0 && <div className="grid-stack option-grid" style={{ width: "100%" }}>
          {fields.map((curr, idx) => (
            <div
              ref={itemsRef.current[curr.id]}
              key={curr.id}
              className="grid-stack-item"
            >
              <div className="grid-stack-item-content">
                <EditItem
                  // key={curr.id}
                  name={`items.${idx}.value`}
                  remove={() => remove(idx)}
                />
              </div>
            </div>
          ))}
        </div>}
        <AppendItem append={append} grid={gridRef.current} />
      </div>
    </>
  );
}

export default function SelectionPopup(props) {
  const { onHiding, visible, items = [], onItemsChange } = props;
  // const [options, setOptions] = useState(items);

  const methods = useForm({
    defaultValues: {
      items: [],
    },
    mode: "onChange",
  });
  const { setValue, control } = methods;

  useEffect(() => {
    if (visible) {
      setValue(
        "items",
        items.map((curr) => ({
          id: uuid(),
          value: curr,
        }))
      );
    }
  }, [setValue, visible]);

  const onSubmit = (values) => {
    onItemsChange?.(values.items.map((curr) => curr.value));
    onHiding(false);
  };

  return (
    <DefaultDialog
      title="Selections Options"
      onClose={onHiding}
      width={400}
      height={450}
    >
      <Form methods={methods} onSubmit={onSubmit} style={{ height: "100%" }}>
        <div style={{ flex: 1, position: "relative" }}>
          <div style={{ position: 'absolute', inset: 0 }}>
            <ScrollView width="100%">
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: 24 }}>
                <b style={{ fontSize: 14 }}>Specify all possible values</b>
                <Items control={control} />
              </div>
            </ScrollView>
          </div>
        </div>
        <div style={{
          display: "flex",
          gap: 8,
          backgroundColor: "#F7F7F7",
          padding: 24,
        }}>
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </DefaultDialog>
  );
}
