// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ic3ZlfTMJvlrUtYdliSiN{color:#f54343;font-size:14px;font-weight:bold}._1CZGcxfuObOWkv9rSAGSPa{font-size:14px}._2jJO1DF2pYlnA0IqzrFnRd{display:flex;flex-direction:column;gap:16px}._37Cq76gYQL4UFyddHoWW2f{align-items:center}._7Vmo6HpX-BSGBPiUY3mVt{padding:24px}._1y-DuqI_wIRf4z-l4wdffd{display:flex;gap:8px;background-color:#f7f7f7;padding:24px}._3haPjt8oor-DeJhP3vUGJM{justify-content:space-between}._3DbTbImgmksSiFnoaGUeCH{background-color:#e7292b}.ZnWMqxXkSmeQz23QCYIRr{color:#919191;background-color:#eff0f1}.ZnWMqxXkSmeQz23QCYIRr:hover{background-color:#def6ff}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/module-editor/popup-module-editor.module.scss"],"names":[],"mappings":"AAAA,uBACE,aAAA,CACA,cAAA,CACA,gBAAA,CAGF,yBACE,cAAA,CAGF,yBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEF,yBACE,kBAAA,CAGF,wBACE,YAAA,CAGF,yBACE,YAAA,CACA,OAAA,CACA,wBAAA,CACA,YAAA,CAGF,yBACE,6BAAA,CAGF,yBACE,wBAAA,CAEF,uBACE,aAAA,CACA,wBAAA,CAEF,6BACE,wBAAA","sourcesContent":[".redText {\n  color: #f54343;\n  font-size: 14px;\n  font-weight: bold;\n}\n\n.defaultText {\n  font-size: 14px;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n.contentCenter {\n  align-items: center;\n}\n\n.bodyPad {\n  padding: 24px;\n}\n\n.footerContainer {\n  display: flex;\n  gap: 8px;\n  background-color: #f7f7f7;\n  padding: 24px;\n}\n\n.deleteMenuFooter {\n  justify-content: space-between;\n}\n\n.deleteBtn {\n  background-color: #e7292b;\n}\n.cancelBtn {\n  color: #919191;\n  background-color: #eff0f1;\n}\n.cancelBtn:hover{\n  background-color: #def6ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redText": "ic3ZlfTMJvlrUtYdliSiN",
	"defaultText": "_1CZGcxfuObOWkv9rSAGSPa",
	"content": "_2jJO1DF2pYlnA0IqzrFnRd",
	"contentCenter": "_37Cq76gYQL4UFyddHoWW2f",
	"bodyPad": "_7Vmo6HpX-BSGBPiUY3mVt",
	"footerContainer": "_1y-DuqI_wIRf4z-l4wdffd",
	"deleteMenuFooter": "_3haPjt8oor-DeJhP3vUGJM",
	"deleteBtn": "_3DbTbImgmksSiFnoaGUeCH",
	"cancelBtn": "ZnWMqxXkSmeQz23QCYIRr"
};
export default ___CSS_LOADER_EXPORT___;
