export const navigation = 
[
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Examples',
    icon: 'folder',
    items: [
      {
        text: 'Profile',
        path: '/profile'
      },
      {
        text: 'Tasks',
        path: '/tasks'
      }
    ]
  },
  {
    text: 'Inventory',
    icon: 'folder',
    items: 
    [
      {
        text: 'Inventory Setting',
        icon: 'folder',
        items: [
          {
            text: 'Package Item',
            path: '/package-item'
          }
        ]
      },
      {
        text: 'Items',
        path: '/items'
      },
    
    ]
  }
];
