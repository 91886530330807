import React from "react";
import ConfirmationDialog from "./confirmation-dialog";

function Dialog(props, ref) {
    const [state, setState] = React.useState();
    const option = state && state.option;
    const type = state && state.type;

    const onClose = React.useCallback(() => {
        setState(undefined);
        document.body.classList.remove('dialog-open');
    }, [setState]);

    React.useImperativeHandle(ref, () => ({
        show(customOption) {
            setState({
                type: "custom",
                option: customOption,
                onClose,
            });
        },
        showConfirmation(confirmationOption) {
            setState({
                type: "confirmation",
                option: confirmationOption,
                onClose,
            })
        }
    }));

    const result = React.useMemo(() => {
        if (type === "custom") {
            const { render } = option;
            return render(onClose);
        }
        return null;
    }, [type, option, onClose]);
    if (state) {
        switch (type) {
            case "confirmation":
                return (
                    <ConfirmationDialog
                        onClose={onClose}
                        {...state.option}
                    />
                );
            default:
                return <>{result}</>;
        }
    }
    return null;
}

export default React.forwardRef(Dialog);
