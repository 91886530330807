import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Checkbox } from "@mui/material";
import { assetsPng } from "../../../assets/png";

export class EditBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"Edit"}
          data-for="edit"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer", marginRight: 8 } : {}}
        >
          <img src={this.props.enabled ? assetsPng.editActive : assetsPng.editInactive} alt="edit" />
          <ReactTooltip
            id="edit"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
        </div>
      </div>
    );
  }
}

export class AddBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"Add"}
          data-for="add"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer", marginRight: 8 } : {}}
        >
          <img src={this.props.enabled ? assetsPng.plusCircleActive : assetsPng.plusCircleInactive} alt="plus-circle" />
          <ReactTooltip
            id="add"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
        </div>
      </div>
    );
  }
}

export class ConfigurationBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"Screen Configuration"}
          data-for="screen-configuration"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer", marginRight: 8 } : {}}
        >
          <ReactTooltip
            id="screen-configuration"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
          <img src={this.props.enabled ? assetsPng.wrenchActive : assetsPng.wrenchInactive} alt="wrench" />
        </div>
      </div>
    );
  }
}

export class UserBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"User Group"}
          data-for="user-group"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer", marginRight: 8 } : {}}
        >
          <ReactTooltip
            id="user-group"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
          <img src={this.props.enabled ? assetsPng.userSquareActive : assetsPng.userSquareInactive} alt="user-square" />
        </div>
      </div>
    );
  }
}

export class DeleteBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"Delete"}
          data-for="delete"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer" } : {}}
        >
          <ReactTooltip
            id="delete"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
          <img src={this.props.enabled ? assetsPng.trashActive : assetsPng.trashInactive} alt="trash" />
        </div>
      </div>
    );
  }
}

export class InfoBtn extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    if (this.props.enabled) {
      event.data = this.props.data;
      this.props.onClick(event);
    }
  }

  render() {
    return (
      <div style={this.props.isHiding ? { display: "none" } : {}}>
        <div
          data-tip={"Details"}
          data-for="info"
          onClick={this.onClick}
          style={this.props.enabled ? { cursor: "pointer", marginRight: 8 } : {}}
        >
          <ReactTooltip
            id="info"
            arrowColor="#474751"
            place="bottom"
            effect="solid"
            className="dataGridTooltips"
          />
          <img src={this.props.enabled ? assetsPng.arrowSquareOutActive : assetsPng.arrowSquareOutInactive} alt="info" />
        </div>
      </div>
    );
  }
}

export const actionButtonCell = (action) =>
  function (props) {
    const {
      edit: {
        onClick: onEditClick,
        enabled: editEnabled,
      },
      add: {
        onClick: onAddClick,
        enabled: addEnabled,
      },
      userGroup: {
        onClick: onUserGroupClick,
        enabled: userGroupEnabled,
      },
      screenConfiguration: {
        onClick: onScreenConfigurationClick,
        enabled: screenConfigurationEnabled,
      },
      delete: {
        onClick: onDeleteClick,
        enabled: deleteEnabled,
      },
      info: {
        onClick: onInfoClick,
        enabled: infoEnabled,
      },
    } = action;

    const isCollapsible = props.data.collapsable !== null;

    const _deleteEnabled = () => {
      if (isCollapsible) {
        return !props.data.original.UdfTables.length;
      }
      return deleteEnabled;
    };

    return (
      <div style={{ display: "flex", alignItems: "flex-end", justifyContent: 'center' }}>
        <EditBtn data={props.data} onClick={onEditClick} enabled={editEnabled} />
        <InfoBtn
          isHiding={isCollapsible}
          data={props.data}
          onClick={onInfoClick}
          enabled={infoEnabled}
        />
        <AddBtn
          data={props.data}
          onClick={onAddClick}
          enabled={addEnabled}
          isHiding={!isCollapsible}
        />
        <UserBtn
          data={props.data}
          onClick={onUserGroupClick}
          enabled={userGroupEnabled}
          isHiding={isCollapsible}
        />
        <ConfigurationBtn
          data={props.data}
          onClick={onScreenConfigurationClick}
          enabled={screenConfigurationEnabled}
          isHiding={isCollapsible}
        />
        <DeleteBtn
          data={props.data}
          onClick={onDeleteClick}
          enabled={_deleteEnabled()}
        />
      </div>
    );
  };

export const checkboxCell = (action) => function (props) {
  return (
    <Checkbox
      checked={props.data.activeYn}
      disabled
      className="check-box"
      sx={{
        "& .MuiSvgIcon-root": { fontSize: 20 },
        "&:hover": {
          background: "none",
        },
        "&.MuiCheckbox-root": {
          padding: 0,
          ...props.data.activeYn ? { color: "#159BD3" } : {}
        }
      }}
    />
  );
}