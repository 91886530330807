// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--blue: #007bf7;--red: #f54343;--white: #ffffff}._2_CoZFxlAm92FpTIFQwEq9{padding:8px 12px;border-radius:4px;display:flex;align-items:center;justify-content:center;min-width:125px}._3sY64m27eY-55N3aRJlmfE{background-color:var(--blue);color:var(--white)}._3XG2jOIP0pcDyDzDNsa-iv{background-color:transparent;border:2px solid var(--white);color:var(--white)}._1YRiy3SCZGtWQgN5UwgNJq{background-color:transparent;border:2px solid var(--blue);color:var(--blue)}._Dn2RpSfxIm9Rfxy92r0i{color:var(--white);background-color:var(--red)}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/button/style.module.scss"],"names":[],"mappings":"AAAA,MACE,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,yBACE,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CAGF,yBACE,4BAAA,CACA,kBAAA,CAGF,yBACE,4BAAA,CACA,6BAAA,CACA,kBAAA,CAGF,yBACE,4BAAA,CACA,4BAAA,CACA,iBAAA,CAGF,uBACE,kBAAA,CACA,2BAAA","sourcesContent":[":root {\n  --blue: #007bf7;\n  --red: #f54343;\n  --white: #ffffff;\n}\n\n.container {\n  padding: 8px 12px;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-width: 125px;\n}\n\n.primary {\n  background-color: var(--blue);\n  color: var(--white);\n}\n\n.secondary {\n  background-color: transparent;\n  border: 2px solid var(--white);\n  color: var(--white);\n}\n\n.secondaryBlue {\n  background-color: transparent;\n  border: 2px solid var(--blue);\n  color: var(--blue);\n}\n\n.solidRed {\n  color: var(--white);\n  background-color: var(--red);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2_CoZFxlAm92FpTIFQwEq9",
	"primary": "_3sY64m27eY-55N3aRJlmfE",
	"secondary": "_3XG2jOIP0pcDyDzDNsa-iv",
	"secondaryBlue": "_1YRiy3SCZGtWQgN5UwgNJq",
	"solidRed": "_Dn2RpSfxIm9Rfxy92r0i"
};
export default ___CSS_LOADER_EXPORT___;
