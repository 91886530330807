import arrowSquareOutActiveIcon from './arrowSquareOut-active.png';
import arrowSquareOutInactiveIcon from './arrowSquareOut-inactive.png';
import editActiveIcon from './edit-active.png';
import editInactiveIcon from './edit-inactive.png';
import plusCircleActiveIcon from './plus-circle-active.png';
import plusCircleInactiveIcon from './plus-circle-inactive.png';
import trashActiveIcon from './trash-active.png';
import trashInactiveIcon from './trash-inactive.png';
import userSquareActiveIcon from './user-square-active.png';
import userSquareInactiveIcon from './user-square-inactive.png';
import wrenchActiveIcon from './wrench-active.png';
import wrenchInactiveIcon from './wrench-inactive.png';

export { default as editActiveIcon } from './edit-active.png';
export { default as editInactiveIcon } from './edit-inactive.png';
export { default as plusCircleActiveIcon } from './plus-circle-active.png';
export { default as plusCircleInactiveIcon } from './plus-circle-inactive.png';
export { default as trashActiveIcon } from './trash-active.png';
export { default as trashInactiveIcon } from './trash-inactive.png';
export { default as userSquareActiveIcon } from './user-square-active.png';
export { default as userSquareInactiveIcon } from './user-square-inactive.png';
export { default as wrenchActiveIcon } from './wrench-active.png';
export { default as wrenchInactiveIcon } from './wrench-inactive.png';

export const assetsPng = {
    arrowSquareOutActive: arrowSquareOutActiveIcon,
    arrowSquareOutInactive: arrowSquareOutInactiveIcon,
    editActive: editActiveIcon,
    editInactive: editInactiveIcon,
    plusCircleActive: plusCircleActiveIcon,
    plusCircleInactive: plusCircleInactiveIcon,
    trashActive: trashActiveIcon,
    trashInactive: trashInactiveIcon,
    userSquareActive: userSquareActiveIcon,
    userSquareInactive: userSquareInactiveIcon,
    wrenchActive: wrenchActiveIcon,
    wrenchInactive: wrenchInactiveIcon,
}