export const GetAllCompaniesIAGByUser = (token, clientID, userID, isAdmin) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/Company/GetAllCompaniesIAGByUser?clientID=${clientID}&UserID=${userID}&IsAdmin=${isAdmin}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAllDocumentType = (token) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/MasterData/GetAllDocumentType?UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data.Obj.DocumentType;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAllSetting = (token) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/MasterData/GetAllSetting?UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Setting;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAllUser = (token, userID) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/Users/GetAllUsers?userID=${userID}&userName=Administrator&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Query_User;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAuditLogSetting = (token, companyID, userID, code, fromDate, toDate) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogSetting?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&setting=Package Item&code=${code}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Query_AuditLogSetting;
  })
  .catch((error) => {console.log(error); return error;});  
}

export const GetAuditLogSettingCount = (token, companyID, userID, code, fromDate, toDate) =>
{
  const GETrequestOptions = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization' : "Bearer " + token
    })
  }

  return fetch(`${process.env.REACT_APP_API_BASE_URL}api/AuditLog/GetAuditLogSettingCount?companyID=${companyID}&fromDate=${fromDate}&toDate=${toDate}&userID=${userID}&setting=Package Item&code=${code}&UI-Culture=en-US`, GETrequestOptions)
  .then(handleErrors)  
  .then(response => response.json())
  .then(data =>{
    return data.Obj.Query_AuditLogSettingCount;
  })
  .catch((error) => {console.log(error); return error;});  
}

//handle errors
function handleErrors(response) {
    if (!response.ok) throw new Error(response.status);
    return response;
  }
  
function handleErrors2(response) {
    if (!response.ok) throw new Error("Session Expired! Please login again.");
    return response;
}
  
//#endregion
  