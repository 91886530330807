// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2C8S6MmX7I5QZwDUOzh_v3{display:flex;width:100vw;height:100%;flex-direction:column}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/detail-view/detail-view.module.scss"],"names":[],"mappings":"AAAA,yBACE,YAAA,CACA,WAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".container {\n  display: flex;\n  width: 100vw;\n  height: 100%;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2C8S6MmX7I5QZwDUOzh_v3"
};
export default ___CSS_LOADER_EXPORT___;
