// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--main-color: #2977b6;--main-dark-color: #123958}._35fj1uBzaIJumGz_MImxNr{flex-basis:350px;background-color:#2977b6;display:flex;flex-direction:column}._3oDcbElo0sms23UvTadrc1{padding:8px 24px;width:100%}._2l3G2rQXg20di0Iyl_PYRn{padding:8px 24px 24px 24px}._26WrFpGTnXZf1c663_Gmi6{padding-left:16px;padding-right:16px}.A3kN-JTfvj3RW3D0i22Im{padding-bottom:24px}._1QeydpcMEZ_0IA5hr5hpKQ{padding-left:14px;padding-right:4px}.Tj3uLAECvHRGUCMsSLOht{width:100%}._3qi2LVrTtLpTHexix0nx6E{padding-top:16px}", "",{"version":3,"sources":["webpack://src/pages/user-defined-field/app-editor/components/style.module.scss"],"names":[],"mappings":"AAAA,MACE,qBAAA,CACA,0BAAA,CAGF,yBAEE,gBAAA,CACA,wBAAA,CACA,YAAA,CACA,qBAAA,CAGF,yBACE,gBAAA,CACA,UAAA,CAGF,yBACE,0BAAA,CAGF,yBACE,iBAAA,CACA,kBAAA,CAGF,uBACE,mBAAA,CAGF,yBACE,iBAAA,CACA,iBAAA,CAGF,uBACE,UAAA,CAGF,yBACE,gBAAA","sourcesContent":[":root {\n  --main-color: #2977b6;\n  --main-dark-color: #123958;\n}\n\n.tabContainer {\n  //   width: 256px;\n  flex-basis: 350px;\n  background-color: #2977b6;\n  display: flex;\n  flex-direction: column;\n}\n\n.buttonContainer {\n  padding: 8px 24px;\n  width: 100%;\n}\n\n.titleContainer {\n  padding: 8px 24px 24px 24px;\n}\n\n.checkboxContainer {\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.checkboxPadBottom {\n  padding-bottom: 24px;\n}\n\n.inputContainer {\n  padding-left: 14px;\n  padding-right: 4px;\n}\n\n.button {\n  width: 100%;\n}\n\n.propertyContainer {\n  padding-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabContainer": "_35fj1uBzaIJumGz_MImxNr",
	"buttonContainer": "_3oDcbElo0sms23UvTadrc1",
	"titleContainer": "_2l3G2rQXg20di0Iyl_PYRn",
	"checkboxContainer": "_26WrFpGTnXZf1c663_Gmi6",
	"checkboxPadBottom": "A3kN-JTfvj3RW3D0i22Im",
	"inputContainer": "_1QeydpcMEZ_0IA5hr5hpKQ",
	"button": "Tj3uLAECvHRGUCMsSLOht",
	"propertyContainer": "_3qi2LVrTtLpTHexix0nx6E"
};
export default ___CSS_LOADER_EXPORT___;
