import Dialog from "./dialog";
import React, { useRef, useMemo } from "react";
import './dialog.scss';

const DialogContext = React.createContext(undefined);

export function DialogProvider({ children }) {
    const DialogRef = useRef(null);
    const action = useMemo(
        () => ({
            show(customOption) {
                if (DialogRef.current) {
                    document.body.classList.add('dialog-open');
                    DialogRef.current.show(customOption);
                }
            },
            showConfirmation(confirmationDialog) {
                if(DialogRef.current) {
                    document.body.classList.add('dialog-open');
                    DialogRef.current.showConfirmation(confirmationDialog);
                }
            }
        }),
        []
    );
    return (
        <>
            <DialogContext.Provider value={action}>{children}</DialogContext.Provider>
            <Dialog ref={DialogRef} />
        </>
    );
}

export default function useDialog() {
    const context = React.useContext(DialogContext);
    if (context === undefined) {
        throw new Error("UseDialog must be used within DialogProvider");
    }
    return context;
}
