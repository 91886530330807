import classNames from 'classnames';
import * as React from 'react';
import PropTypes from "prop-types";

import styles from './dialog.module.scss';
import DefaultDialog from './default-dialog';
import Button from '../../pages/user-defined-field/app-editor/components/button/button';

export default function ConfirmationDialog(props) {
  const {
    message,
    positiveLabel = "Yes",
    negativeLabel = "No",
    title = "Confirmation",
    titlePosition = "center",
    noNegative = false,
    onNegativeAction,
    onPositiveAction,
    width = 400,
    height = 214,
    onClose,
    ...rest
  } = props;

  const onPositivePress = React.useCallback(() => {
    onPositiveAction(onClose);
  }, [onClose, onPositiveAction]);

  const onNegativePress = React.useCallback(() => {
    if (onNegativeAction) {
      onNegativeAction(onClose);
    } else {
      onClose();
    }
  }, [onClose, onNegativeAction]);


  return (
    <DefaultDialog
      title={title}
      titlePosition={titlePosition}
      width={width}
      height={height}
      onClose={onClose}
      {...rest}
    >
      <div
        className={classNames(styles.content, styles.bodyPad, styles.contentCenter)}
      >
        <span className={styles.defaultText}>{message}</span>
      </div>
      <div
        className={classNames(styles.footerContainer, styles.justifyCenter)}
      >
        <Button onClick={onPositivePress}>{positiveLabel}</Button>
        {!noNegative && (
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={onNegativePress}
          >
            {negativeLabel}
          </Button>
        )}
      </div>
    </DefaultDialog>
  )
}

ConfirmationDialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  titlePosition: PropTypes.oneOf(['left', 'center']),
  message: PropTypes.string.isRequired,
  positiveLabel: PropTypes.string,
  negativeLabel: PropTypes.string,
  onPositiveAction: PropTypes.func.isRequired,
  onNegativeAction: PropTypes.func,
  noNegative: PropTypes.bool,
}
