import React from 'react';
import Cookies from 'universal-cookie';
import Form from 'devextreme-react/form';
import ReactTooltip from 'react-tooltip';
import { Button } from 'devextreme-react';
import { Item } from 'devextreme-react/select-box';
import { DefaultPager, DefaultStateStoringIgnore} from '../../../utils/default-data-grid-settings';
import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  ColumnChooser,
  Export,
  Scrolling,
  Summary,
  TotalItem,
  Grouping,
  GroupPanel,
  LoadPanel,
  StateStoring,
  RowDragging, 
  Selection
} from 'devextreme-react/data-grid';
import './purchase-invoice.scss';

//components
import { ChildShowFilterRow } from '../../../components/index'
import { ListingHeader5 } from '../../../layouts/index.js'

//default 
import { CompanyID, UserID, ClientID, AdminYN, SetCookies, SetDecimalPoints, SetDefaultName, DefaultName, Token, SetCompanyID, SetSearchText, SearchText } from '../../../utils/default-cookies';

//language
import { formatMessage, locale } from 'devextreme/localization';

//loading bar
import loadingBar from '../../../components/image/loadingBar.gif';

//api
import { GetAllCompaniesIAGByUser} from './../../inventory-setting/package-item/package-item-services';

const cookies = new Cookies();
const Mousetrap = require("mousetrap");
Mousetrap.prototype.stopCallback = function () {
  return false;
}

class DocumentAction extends React.Component {
  constructor(props) {
    super(props);

    //component instance
    this.dataGridRef = React.createRef();
    this.childToolBarRef = React.createRef();
    this.childShowFilterRowRef = React.createRef();
    this.childHeader = React.createRef();

    this.searchValueChangeHandler = this.searchValueChangeHandler.bind(this);
    this.selectValueChangeHandler = this.selectValueChangeHandler.bind(this);
    this.onCalBtnClick = this.onCalBtnClick.bind(this);

    this.editBtn = this.editBtn.bind(this);
    this.dragBtn = this.dragBtn.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.onFlashMessageTimer = this.onFlashMessageTimer.bind(this);
    this.calculateSearchExpression = this.calculateSearchExpression.bind(this);
    this.itemCodeCell = this.itemCodeCell.bind(this);
    this.onCellRender = this.onCellRender.bind(this);
    this.dataGridFocusedRowUp = this.dataGridFocusedRowUp.bind(this);
    this.dataGridFocusedRowDown = this.dataGridFocusedRowDown.bind(this);

    // post message from alaya c#
    this.receiveMessage = this.receiveMessage.bind(this);
    this.restoreLayout = this.restoreLayout.bind(this);
    this.showMsgHandler = this.showMsgHandler.bind(this);
    this.showFilterRow = this.showFilterRow.bind(this);
    this.showGroupPanel = this.showGroupPanel.bind(this);

    this.state = {
      companies : [],
      searchText: '',
      showFilterRow: false,
      showGroupPanel: false,
      allowSave: false,
      allowDelete: false,
      allowCopy: false,
      allowAdd: false,
      viewDtl: false,
      buttonMode: true,
      displayFlashMessageDialog: false,
      status: 'success',
      size: 'flash-message-success-container'
    }

    this.companies =
      [
        {
          "Key": 1,
          "A": "06/05/2021",
          "B": "PI-00000077",
          "C": "B678",
          "D": "Deployed Industries",
          "E": 786518901,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 3209.10
        },
        {
          "Key": 2,
          "A": "06/05/2021",
          "B": "PI-00000078",
          "C": "B880",
          "D": "HICOM Teck See",
          "E": 673590133,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 352.10
        },
        {
          "Key": 3,
          "A": "06/05/2021",
          "B": "PI-00000079",
          "C": "B123",
          "D": "MTV Digital Technology",
          "E": 761890123,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 1129.90
        },
        {
          "Key": 4,
          "A": "06/05/2021",
          "B": "PI-00000080",
          "C": "B209",
          "D": "Multi-Code Electronic",
          "E": 109878901,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 918.30
        },
        {
          "Key": 5,
          "A": "06/05/2021",
          "B": "PI-00000081",
          "C": "B667",
          "D": "Delloyed Alpha",
          "E": 561789109,
          "F":  "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 455.70
        },
        {
          "Key": 6,
          "A": "06/05/2021",
          "B": "PI-00000082",
          "C": "B109",
          "D": "Delloyd Electronics",
          "E": 4324342443,
          "F":  "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 2559.00
        },
        {
          "Key": 7,
          "A": "06/05/2021",
          "B": "PI-00000083",
          "C": "B897",
          "D": "Proreka",
          "E": 123435454,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 13660.20
        },
        {
          "Key": 8,  
          "A": "06/05/2021",
          "B": "PI-00000084",
          "C": "B345",
          "D": "Xenso Electronics",
          "E": 2344878754,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 1799.00
        },
        {
          "Key": 9,
          "A": "06/05/2021",
          "B": "PI-00000085",
          "C": "B345",
          "D": "Deployed Industries",
          "E": 348765432,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 3209.10
        },
        {
          "Key": 10, 
          "A": "06/05/2021",
          "B": "PI-00000077",
          "C": "B678",
          "D": "Deployed Industries",
          "E": 365478981,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 3209.10
        },  
        {
          "Key": 11, 
          "A": "06/05/2021",
          "B": "PI-00000077",
          "C": "B678",
          "D": "Deployed Industries",
          "E": 3209.10,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 3209.10
        },  
        {
          "Key": 12,
          "A": "06/05/2021",
          "B": "PI-00000077",
          "C": "B678",
          "D": "Deployed Industries",
          "E": 3209.10,
          "F": "delloyed_in@gmail.com",
          "G": "03-76519871",
          "H": 3209.10
        },  
      ]

  }

  onCalBtnClick = () => {
    
  }

//set child - data-grid-context-menu to show group panel
    showGroupPanel() {
        this.setState(prevState => ({
            showGroupPanel: !prevState.showGroupPanel
        }));
    }

    //set child - data-grid-context-menu to show filter row
    showFilterRow() {
    this.setState(prevState => ({
        showFilterRow: !prevState.showFilterRow
    }));
    }

    handleSessionPopUpCallBack = () => {
    this.setState(state => ({
        displaySessionPopUp: !state.displaySessionPopUp
    }))
    }

  handleCompanies = (result) => {
    this.setState({ companiess: result });
  }

  componentDidMount() {
    window.addEventListener("message", this.receiveMessage, false);
    document.addEventListener('keydown', () => this.onFlashMessageTimer(), false);
    document.addEventListener('mousedown', () => this.onFlashMessageTimer(), false);

    Mousetrap.bind("up", () => this.dataGridFocusedRowUp());
    Mousetrap.bind("down", () => this.dataGridFocusedRowDown());
    this.receiveMessage('')
  }

  componentWillUnmount() {
    //remove event listener
    window.removeEventListener("message", this.receiveMessage, false);
    document.removeEventListener('keydown', () => this.onFlashMessageTimer(), false);
    document.removeEventListener('mousedown', () => this.onFlashMessageTimer(),false);

    Mousetrap.unbind("up");
    Mousetrap.unbind("down");
  }

  async receiveMessage(e) {
    if (e.data != "empty") {
      //SetCookies(cookies,  "eyJhbGciOiJSUzI1NiIsImtpZCI6IjQyMEU5NjI2QzYxNTY1NEQyRDUzNjlBMEFBNTc3OEFBIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2Mjk4NzY3MTMsImV4cCI6MTYyOTk2MzExMywiaXNzIjoiaHR0cHM6Ly90ZXN0YXBpLmlyc2FsYXlhLmNvbSIsImNsaWVudF9pZCI6ImFwaSIsInN1YiI6ImFkbWluIiwiYXV0aF90aW1lIjoxNjI5ODc2NzEzLCJpZHAiOiJsb2NhbCIsIkxvZ2luQXMiOiJVc2VyIiwiQ2xpZW50SUQiOiJyZWFjdCIsIkNuc05hbWUiOiJBbGF5YURiRXhhYnl0ZSIsIkRiTmFtZSI6IklSU0FjY291bnRpbmdfSVJTMDEwNF9SRUFDVCIsImp0aSI6IjI5QjczMkY5NTlGQkRBMzI1QzVCQ0Q3NDJGM0EyRkNDIiwiaWF0IjoxNjI5ODc2NzEzLCJzY29wZSI6WyJhcGkxIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInBhc3N3b3JkIl19.RNJt0qnhD-M7LP7dg3Vf9V8FZbxWkNDXZ_eZ5w7ZVXJ944_wyULun4EnZPsJVoGW9AAD1I7LPO18nNEI-9EHEvdffogWxnreAOscKMg96gtuSDv4uNG-bw8cXlFv74Qh2XLOSwvJh0HFGVtEq9BCMukD2GKYm1D8YEIS8tvXJ-o_hTAObPhCQ0bUOYbaQ4ltrDLlrD4Ogl2d-2UP7R45gPtXfPwMI0ct7uZtZkMo84KUDDjtHFApRTLMGT6rAfGw_3TQBpnPwzYmns7ZnZKWXnT4_bjvueY2S3LEKsB39hyVO3p6zjyLHqXd4joGkd_uqpLasLOZnBYV1EKdbMHDrQ", 2, 392, 1, "IRS ADMIN", true, "en");  
      //SetCookies(cookies, e.data.accessToken, e.data.userID, e.data.companyID, e.data.clientID, e.data.username, e.data.adminYN, e.data.administratorYN, e.data.language);
      //language
      sessionStorage.setItem("locale", 'en');
      const locales = sessionStorage.getItem("locale");
      locale(locales);
    }
  }

  dataGridFocusedRowUp() {
    //up shortcut key not function when focus on data grid
    this.dataGridRef.current.instance.focus();
  }
  dataGridFocusedRowDown() {
    //down shortcut key not function when focus on data grid
    this.dataGridRef.current.instance.focus();
    var newFocusRowIndex = this.dataGridRef.current.instance.option('focusedRowIndex') + 1;
    this.dataGridRef.current.instance.option('focusedRowIndex', newFocusRowIndex)
  }

  onReorder(e){        
    //drag and drop column
  }

  onFlashMessageTimer(){
    if(this.state.displayFlashMessageDialog === true){
      setTimeout(() => this.setState({displayFlashMessageDialog: false}), 1000)
    }
  }

  //data-grid-context-menu to restore datagrid default layout 
  restoreLayout() {
    // PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleItemID, DefaultSMIPackageItem.controlID,  DefaultSMIPackageItem.packageItem, "storageKey", " ");

    if (this.dataGridRef.current != null)
      this.dataGridRef.current.instance.state("");
  }

  get dataGrid() {
    // `current.instance` points to the UI component instance
    return this.dataGridRef.current.instance;
  }

  showMsgHandler(params, value, size) {

    if (params !== '') {
      this.setState({ emptyMsg: params, displayFlashMessageDialog: true, status: value, size: size });
    }
    else {
      this.setState({ emptyMsg: '', displayFlashMessageDialog: false, status: value, size: size });
    }
  }

  searchValueChangeHandler = (event) => {
    SetSearchText(cookies, event);
    this.setState({ searchText: event });
    // const dataSource_PackageItem = GetPackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), AdminYN(), this.state.searchText, '')
    // this.setState({ 
    //   PackageItems: dataSource_PackageItem
    // });
  }

  selectValueChangeHandler = (event) => {
    SetCompanyID(cookies, event);
    // const dataSource_PackageItem = GetPackageItemByCompanyID(Token(), CompanyID(), ClientID(), UserID(), AdminYN(), this.state.searchText, '')
    // this.setState({ 
    //   PackageItems: dataSource_PackageItem
    // });
  }

  dragBtn() {
    return (
      <div data-tip={formatMessage("Draganddroptoadesiredposition")} className="icon-draggable-icon">
        <ReactTooltip arrowColor="#474751" place='top' className="dataGridTooltips" />
      </div>
    );
  }

  editBtn(e) {
    return (
      <div>
        {this.state.viewDtl == true ?
          (
            <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" id={e.data.IM_ID} name={e.data.IM_Code} value={e.data.IM_Description} onClick={this.editIconClick} className="icon-edit-icon">
              <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
            </div>
          ) :
          (
            <div className="disable-view-dtl">
              <div data-tip={formatMessage('EditDetails[Enter]')} data-for="edit" id={e.data.IM_ID} name={e.data.IM_Code} value={e.data.IM_Description} className="icon-edit-icon">
                <ReactTooltip id="edit" arrowColor="#474751" place="bottom" effect="solid" className="dataGridTooltips" />
              </div>
            </div>
          )}
      </div>
    )
  }

  onCellPrepared(e) {
    if (e.rowType === "totalFooter") {
        if (e.column.command === "select") {
          const summaryValue = e.component.getTotalSummaryValue("totalCount");
          // create the required HTML element with 'summaryValue' and append it to 'e.cellElement'
          const span = document.createElement("span");
          span.className = "dx-datagrid-summary-item"
          span.innerHTML = formatMessage("Records") + ": " + summaryValue;
          e.cellElement.appendChild(span);
        }
        if (e.column.dataField === "A") {
          e.cellElement.innerHTML = "";
        }
      }
  }

  itemCodeCell(data) {
    return (
      <a 
        href={void (0)} 
        // onClick={() => this.openSourceTab(data.data.ChildItemCode_URL)} 
        className="item-code">{data.value}
      </a>
    );
  }

  addMenuItems(e) {
    // this.childShowFilterRowRef.current.DataGridContextMenuItemsService(e, this.dataGrid);
  }

  //data grid - search function
  onOptionChanged(options) {
    if (options.name === "searchPanel" && options.fullName === "searchPanel.text") {
      this.setState({ searchText: options.value });
    }
  }

  calculateSearchExpression(filtervalue, selectedFilterOperations, target) {
    const query = filtervalue.trim();
    const regExpText = "(".concat(
      query
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
        .split(" ")
        .join(")|(")
        .concat(")")
    );

    const regExp = new RegExp(regExpText, 'i');
    const getValue = (col) => {
      return (query.length === 0) ? true : regExp.test(col.IM_Code) || regExp.test(col.IM_Description) || regExp.test(col.IM_Description2);
    }
    if (target !== "search") return;
    return [[getValue, '=', true]];
  }

  onCellRender(cellData) {
    const search = this.state.searchText.trim();
    if (search.length === 0) return cellData.value;
    const regExpText = "(".concat(
      search
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
        .split(" ").filter(el => el.length > 0)
        .sort((a, b) => b.length - a.length)
        .join(")|(")
        .concat(")")
    );

    const regExp = new RegExp(regExpText, "ig");
    try {
      const matches = (cellData.value.match(regExp)) ? cellData.value.match(regExp).filter((el) => el !== undefined) : [];
      return (
        <div>
          {cellData.value
            .split(regExp)
            .filter((el) => el !== undefined && el != null)
            .map((el, index) => {
              if (el === matches[0]) {
                matches.shift();
                return (
                  <span key={index} className="dx-datagrid-search-text">
                    {el}
                  </span>
                );
              } else return el;
            })}
        </div>
      );
    }
    catch (error) { }
  }
  //#endregion

  render() {

    return (
      <>
        <Form
          id="purchase-invoice-form"
          width="100%"
          colCount={8}>
          <Item colSpan={8}>
            <div className="package-item-header-title">
              <ListingHeader5
                placeHolderText     = {formatMessage('QuickSearch')}
                placeHolderText2    = {formatMessage('InputDocumentNo')}
                captionTextbox      = {formatMessage('DocumentType')}
                captionDropdown2    = {formatMessage('DocumentNo')}
                buttonText          = {formatMessage('Search')} 
                buttonText2         = {formatMessage('Analysis')}
                buttonText3         = {formatMessage('DocumentAction')}
                buttonText4         = {formatMessage('Filter')}
                buttonMode          = {this.state.buttonMode}
                onCalBtnClick       = {this.onCalBtnClick}
                onSearchValueChange = {this.searchValueChangeHandler}
              />
            </div>
          </Item>

          <Item colSpan={8}>
            <div className="dx-fieldset">
              <div className="dx-field">
                <div className="dx-field-value">
                  <DataGrid
                    // id                   = {DefaultSMIPackageItem.controlID}
                    allowColumnReordering   ={true}
                    allowColumnResizing     ={true}
                    columnAutoWidth         ={true}
                    columnHidingEnabled     ={false}
                    columnResizingMode      ={"widget"}
                    dataSource              ={this.companies}
                    defaultFocusedRowIndex  ={0}
                    focusedRowEnabled       ={true}
                    keyExpr                 ="Key"
                    onOptionChanged         ={this.onOptionChanged}
                    onCellPrepared          ={this.onCellPrepared}
                    // onContentReady          = {this.onContentReady}
                    onContextMenuPreparing  ={this.addMenuItems}
                    // onEditorPreparing       = {this.onEditorPreparing}
                    // onFocusedRowChanged     = {this.onFocusedRowChanged}
                    // onKeyDown               = {this.onKeyDown}
                    // onRowDblClick           = {this.editIconClick}
                    ref                     = {this.dataGridRef}
                    rowAlternationEnabled   = {true}
                    showBorders             = {true}
                    showRowLines            = {true}
                    className               = {'dx-datagrid-items'}>

                    <Export enabled                 = {false} />
                    <FilterRow visible              = {this.state.showFilterRow} />
                    <Grouping contextMenuEnabled    = {true} />
                    <LoadPanel indicatorSrc         = {loadingBar} />
                    <GroupPanel visible             = {this.state.showGroupPanel} />
                    <Paging defaultPageSize         = {DefaultPager.defaultPageSize_listing} />
                    <Pager showPageSizeSelector     = {true}
                           allowedPageSizes         = {DefaultPager.allowedPageSizes_listing}
                           showNavigationButtons    = {true}
                        //    showInfo                 ={this.showInfoChange}/>
                    />

                    <ColumnChooser enabled  = {false}
                                   mode     = {"multipleSelection"} />
                   
                    <RowDragging allowReordering    = {true}
                                 onReorder          = {this.onReorder}/>

                    <SearchPanel text       = {this.state.searchText}
                                 visible    ={false} />

                    <Scrolling mode     = {"standard"}
                      useNative         = {false}
                      scrollByContent   = {true}
                      scrollByThumb     = {true}
                      showScrollbar     = {"always"} />

                    <StateStoring ignoreColumnOptionNames = {DefaultStateStoringIgnore}
                                  enabled                 = {true}
                                  type                    = {"custom"}
                                //   customLoad              = {this.loadState}
                                //   customSave              = {this.saveState}
                                  
                    />

                    <Selection type                 = {"checkbox"}
                               mode                 = {"multiple"} 
                               allowSelectAll       = {true}
                               showCheckBoxesMode   = {'always'}
                               allowFixing          = {true}
                    />

                    <Column
                      allowHiding           = {false}
                      showInColumnChooser   = {false}
                      type                  = {"drag"}
                      cellRender            = {this.dragBtn}
                      width                 = {30}
                      minWidth              = {30}>
                    </Column>

                    <Column
                      dataField                   = {'A'}
                      caption                     = {formatMessage('DocDate')}
                      width                       = {120}
                      cellRender                  = {this.onCellRender}
                      calculateFilterExpression   = {this.calculateSearchExpression}
                      minWidth                    = {60} />

                    <Column
                      dataField                   = {'B'}
                      caption                     = {formatMessage('DocNo')}
                      minWidth                    = {60}
                      cellRender                  = {this.itemCodeCell}
                      calculateFilterExpression   = {this.calculateSearchExpression}
                    />

                    <Column
                      dataField                   = {'C'}
                      caption                     = {formatMessage('ReferenceNo')}
                      minWidth                    = {60}
                      cellRender                  = {this.onCellRender}
                      calculateFilterExpression   = {this.calculateSearchExpression}
                    />

                    <Column
                      dataField   = {'D'}
                      caption     = {formatMessage('CompanyName')}
                      cellRender  = {this.itemCodeCell}
                      minWidth    = {60} />

                    <Column
                      dataField = {'E'}
                      caption   = {formatMessage('AccountNo')}
                      minWidth  = {60} />

                    <Column
                      dataField   = {'F'}
                      caption     = {formatMessage('Email')}
                      minWidth    = {60} />

                    <Column
                      dataField   = {'G'}
                      caption     = {formatMessage('Handphone')}
                      minWidth    = {60} />

                    <Column
                      dataField   = {'H'}
                      caption     = {formatMessage('DocAmount')}
                      minWidth    = {60} />

                    <Summary>

                      <TotalItem
                        name            = {"totalCount"}
                        summaryType     = {"count"}
                        displayFormat   = {"Count: {0}"}
                        showInColumn    = {"A"} />
                    
                    </Summary>

                  </DataGrid>
                </div>
              </div>
            </div>
          </Item>
          <ChildShowFilterRow ref={this.childShowFilterRowRef} getChildFilterRow={this.showFilterRow} getChildGroupPanel={this.showGroupPanel} restoreLayout={this.restoreLayout} showRestoreMsg={this.showMsgHandler} />
        </Form>
      </>
    )
  }
}

export default DocumentAction;