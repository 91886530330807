import React, { forwardRef } from "react";
import Form from "devextreme-react/form";
import Toolbar, { Item } from "devextreme-react/toolbar";
import ReactTooltip from "react-tooltip";
import { Button } from "devextreme-react/button";
import PropTypes from "prop-types";
import "./toolbar.scss";
import "./toolbar-udf.scss";

//#region customized

//language
import { formatMessage } from "devextreme/localization";
//#endregion

import { DesktopIcon, EyeSlashIcon, InfoIcon } from "../../assets";
import { useWebStudio } from "../../pages/user-defined-field/app-editor/components/web-studio";

const ToolbarUDF = forwardRef((props, ref) => {
  const { onHideGrid } = useWebStudio();
  const saveBtnClick = async () => {
    props.saveClick?.();
  };

  const backBtnClick = () => {
    props.goBackClick?.();
  };

  const hideGridClick = () => {
    onHideGrid?.((prev) => !prev);
  };

  const infoClick = () => {
    props.infoClick?.();
  };

  const listViewClick = () => {
    props.listViewClick?.();
  };

  const detailViewClick = () => {
    props.detailViewClick?.();
  };

  const isFocus = {
    info: props.isFocusInfo || false,
    list: props.isFocusList || false,
    detail: props.isFocusDetail || false,
  }

  return (
    <React.Fragment>
      <Form colCount={16} className="toolbar-udf">
        <Item colSpan={16}>
          <Toolbar className="toolbar-cus">
            <Item visible={props.displayBackBtn} location="before">
              <div
                data-tip={formatMessage("BackAltB")}
                data-for="backBtn"
                className="backToolbarBtn"
              >
                <Button
                  id="backBtn"
                  text={formatMessage("Back")}
                  stylingMode="text"
                  onClick={backBtnClick}
                  type="normal"
                  icon="back"
                  height={22}
                />

                <ReactTooltip
                  id="backBtn"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>

            <Item visible={props.displaySaveBtn} location="before">
              <div
                data-tip={formatMessage("SaveAltS")}
                data-for="saveBtn"
                className="toolbarBtn"
              >
                <Button
                  id="saveBtn"
                  text={formatMessage("Save")}
                  stylingMode="text"
                  onClick={saveBtnClick}
                  type="normal"
                  icon="save"
                  height={22}
                />

                <ReactTooltip
                  id="saveBtn"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>

            <Item visible={props.displayHideGridBtn} location="before">
              <div
                data-tip="Hide Grid"
                data-for="hideGrid"
                className="toolbarBtn"
              >
                <Button
                  id="hideGrid"
                  stylingMode="text"
                  onClick={hideGridClick}
                  type="normal"
                  height={22}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                    }}
                  >
                    <EyeSlashIcon />
                    <span className="dx-button-text">Hide Grid</span>
                  </div>
                </Button>

                <ReactTooltip
                  id="hideGrid"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>
            <Item visible={props.displayInfoBtn} location="after">
              <div data-tip="Info" data-for="info" className="toolbarBtn">
                <Button
                  id="info"
                  stylingMode="text"
                  onClick={infoClick}
                  type="normal"
                  height={22}
                  className={isFocus.info ? 'button-focus' : ''}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                    }}
                  >
                    <InfoIcon />
                    <span className="dx-button-text">Info</span>
                  </div>
                </Button>

                <ReactTooltip
                  id="info"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>
            <Item visible={props.displayListViewBtn} location="after">
              <div
                data-tip="List View"
                data-for="listView"
                className="toolbarBtn"
              >
                <Button
                  id="listView"
                  stylingMode="text"
                  onClick={listViewClick}
                  type="normal"
                  height={22}
                  className={isFocus.list ? 'button-focus' : ''}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                    }}
                  >
                    <EyeSlashIcon />
                    <span className="dx-button-text">List View</span>
                  </div>
                </Button>

                <ReactTooltip
                  id="listView"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>
            <Item visible={props.displayDetailViewBtn} location="after">
              <div
                data-tip="Detail View"
                data-for="detailView"
                className="toolbarBtn"
              >
                <Button
                  id="detailView"
                  stylingMode="text"
                  onClick={detailViewClick}
                  type="normal"
                  height={22}
                  className={isFocus.detail ? 'button-focus' : ''}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                    }}
                  >
                    <DesktopIcon />
                    <span className="dx-button-text">Detail View</span>
                  </div>
                </Button>

                <ReactTooltip
                  id="detailView"
                  place="bottom"
                  effect="solid"
                  arrowColor="#474751"
                />
              </div>
            </Item>
          </Toolbar>
        </Item>
      </Form>
    </React.Fragment>
  );
});

ToolbarUDF.displayName = "ToolbarUDF";

ToolbarUDF.propTypes = {
  saveClick: PropTypes.func,
  goBackClick: PropTypes.func,
  // hideGridClick: PropTypes.func,
  infoClick: PropTypes.func,
  listViewClick: PropTypes.func,
  detailViewClick: PropTypes.func,
  displayBackBtn: PropTypes.bool,
  displaySaveBtn: PropTypes.bool,
  displayHideGridBtn: PropTypes.bool,
  displayInfoBtn: PropTypes.bool,
  displayListViewBtn: PropTypes.bool,
  displayDetailViewBtn: PropTypes.bool,
};

export default ToolbarUDF;
