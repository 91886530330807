import * as React from "react";
import { FormProvider } from "react-hook-form";

export const context = React.createContext({
  editable: false,
  setIsEditable: () => {},
});

const { Provider } = context;

export function Form(props) {
  const { children, onSubmit, methods, style, method = "POST", onBeforeSubmit, onSubmitError } = props;
  const [isEditable, setIsEditable] = React.useState(
    props.defaultEditable !== undefined ? props.defaultEditable : true
  );

  const value = React.useMemo(
    () => ({
      editable: isEditable && !methods.formState.isSubmitting,
      setIsEditable,
    }),
    [isEditable, methods.formState.isSubmitting]
  );

  return (
    <Provider value={value}>
      <FormProvider {...methods}>
        <form
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            ...style,
          }}
          method={method}
          /** Disable native form validation since it interferes with number input validation ("Please enter a valid value"). 
           * We already have Yup so native validation is not really required unless you need accessibility. */
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onBeforeSubmit && !onBeforeSubmit(e)) {
              return;
            }
            // Keep console.error for debugging purposes for now
            methods.handleSubmit(onSubmit, onSubmitError || console.error)();
          }}
        >
          {children}
        </form>
      </FormProvider>
    </Provider>
  );
}
