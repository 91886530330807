import React from 'react';
import Form from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react';
import { formatMessage } from 'devextreme/localization';

class DeletePopUp extends React.Component {
    constructor(props) {
        super(props);

        this.deleteBtn = React.createRef();

        this.onShown = this.onShown.bind(this);
        this.onKeyEvent = this.onKeyEvent.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyEvent);
    }

    componentWillUnmount() {
        document.addEventListener('keydown', this.onKeyEvent);
    }

    onShown() {
        // focus delete button on
        this.deleteBtn.current.instance.focus();
    }

    onKeyEvent(e) {
        if (!this.props.visible) return;

        if (e.keyCode === 27) {
            // close popup on escape key
            this.props.hideDeletePopup();
        }
    }

    render() {
        return (
            <Popup
                position={'center'}
                className="item-popup-center"
                showTitle={true}
                title={formatMessage("DeleteItem")}
                onShown={this.onShown}
                onHiding={this.props.hideDeletePopup}
                visible={this.props.visible}
                dragEnabled={false}
                closeOnOutsideClick={true}
                width={"auto"}
                height={"auto"}
                tabIndex={-1}
                onKeyDown={this.onKeyDown}
            >
                <Form colCount={4} >
                    <Item colSpan={4} >
                        <div className="delete-line-1">
                            {formatMessage('Areyousureyouwanttodeletethisrow')}
                        </div>
                        <div className="delete-line-2">
                            {formatMessage("Thisactioncannotbeundone")}
                        </div>
                    </Item>
                </Form>
                <div className="left-btn-container-no-cover" >
                    <Button
                        className="cancel-btn"
                        text={formatMessage("Cancel")}
                        type="normal"
                        stylingMode="contained"
                        onClick={this.props.hideDeletePopup}
                    />
                </div>
                <div className="right-btn-container-no-cover" >
                    <Button
                        className="delete-btn"
                        text={formatMessage("Delete")}
                        onClick={this.props.parentCallback}
                        type="default"
                        stylingMode="contained"
                        ref={this.deleteBtn}
                    />
                </div>
            </Popup>
        );
    }
}

export default DeletePopUp;