import "./popup-module-editor.scss";

import React, { useMemo } from "react";
import { useController, useFieldArray, useForm } from "react-hook-form";
import { ReactSortable } from "react-sortablejs";

import { Form } from "../app-editor/form";
import Button from "../app-editor/components/button/button";
import DefaultTextBox from "../../../components/text-box/text-box";
import DefaultCheckbox from "../../../components/check-box/check-box";
import DefaultNumberBox from "../../../components/number-box/number-box";
import DefaultDialog from "../../../components/custom-dialog/default-dialog";
import { DotSixVerticalIcon } from "../../../assets";
import HOCInput from "../app-editor/hoc-input";

function Input({ name, label, placeholder, errorMessage, disabled, required, pattern }) {
  const { field, fieldState } = useController({ name, rules: { required, pattern, validate: (value) => !!value.trim() } });

  const errorText = () => {
    if (fieldState.error.type === 'required' || fieldState.error.type === "validate") {
      return <span className="errorText">This field is required</span>
    };
    if (fieldState.error.type === 'pattern') {
      return (
        <span className="errorText">
          The value must be at least 3 characters, starts with an alphabet and only allow alphanumeric.
        </span>
      );
    };
    return "";
  }

  return (
    <DefaultTextBox
      onTbvalueChange={field.onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      disabled={disabled}
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function NumberInput({ name, label, placeholder, errorMessage, disabled, required }) {
  const { field, fieldState } = useController({
    name,
    rules: {
      minLength: 1,
      required
    }
  });

  const errorText = () => {
    if (fieldState.error.type === 'required') {
      return <span className="errorText">This field is required</span>
    };
    return "";
  }

  return (
    <DefaultNumberBox
      onChange={field.onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      disabled={disabled}
      min={1}
      max={999}
      step={1}
      format="#"
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function CheckboxField({ name, label }) {
  const { field } = useController({ name });

  return (
    <div style={{ width: "100%" }}>
      <DefaultCheckbox
        onValueChanged={(value) => field.onChange(value)}
        value={field.value}
        name={field.name}
        label={label}
        labelWidth={100}
        flexReverse
        isResetStyle
        align="center"
      />
    </div>
  );
}

function MenuArrangement({ control }) {
  const { fields, replace } = useFieldArray({ name: "tableSeq", control });

  return (
    <>
      <span style={{ marginLeft: 6, fontSize: 14, marginBottom: 8, fontWeight: 'bold', color: '#474751' }}>
        Edit Menu Arragement
      </span>
      <ReactSortable
        list={fields}
        setList={replace}
        className="module-editor-sortable"
      >
        {fields.map((curr, idx) => (
          <div
            key={curr.tableId}
            className={`itemContainer`}
          >
            <div className="dotsContainer">
              <DotSixVerticalIcon />
            </div>
            <div className="inputContainer">
              <input className="textInput" defaultValue={curr.label} disabled />
            </div>
          </div>
        ))}
      </ReactSortable>

    </>
  );
}

const defaultValues = {
  Name: "",
  RibbonSeq: 0,
  Active_YN: true,
  TableIdSeq: {},
  tableSeq: [],
}


export default function EditModulePopup(props) {
  const { onHiding, onConfirm, data } = props;

  const defaultDataTableSeq = useMemo(() => {

    return data.original.UdfTables.map(item => ({
      tableId: item.UT_ID,
      label: item.UT_TableName,
      sequence: item.UT_MenuSeq,
    }))
  }, [data])

  const methods = useForm({
    defaultValues: {
      AppId: data.id,
      Name: data.name,
      Caption: data.caption,
      RibbonSeq: data.sequence,
      Active_YN: data.activeYn,
      tableSeq: data.original.UdfTables.sort((a, b) => {
        if (a.UT_MenuSeq > b.UT_MenuSeq) return 1;
        else if (b.UT_MenuSeq > a.UT_MenuSeq) return -1;
        return 0;
      }).map(item => ({
        tableId: item.UT_ID,
        label: item.UT_TableName,
        sequence: item.UT_MenuSeq,
      }))
    },
  });
  const { reset, control } = methods;

  const onSubmit = async (values) => {
    try {
      values.TableIdSeq = values.tableSeq.reduce((prev, curr, index) => {
        return { ...prev, [`${curr.tableId}`]: index ?? 0 }
      }, {})
      onConfirm?.(values);
    } catch (error) {
      console.error(error);
    }
  };

  const _onHiding = () => {
    props.close?.();
    reset(defaultValues);
    onHiding(false);
  };

  return (
    <DefaultDialog title="Edit Module" onClose={_onHiding} width={400} height={571}>
      <Form style={{ height: "100%" }} methods={methods} onSubmit={onSubmit}>
        <div style={{ flex: 1, position: "relative", height: '100%' }}>
          <div style={{ position: 'absolute', inset: 0, overflow: 'hidden' }}>
            <div style={{ height: '100%', overflow: 'auto', position: 'relative' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: 24 }}>
                <Input name="Name" label="Module Code" disabled />
                <Input name="Caption" label="Module Name" placeholder="Enter Module Name" required />
                <NumberInput name="RibbonSeq" label="Sequence" required />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ marginLeft: 6, fontSize: 14, marginBottom: 8, fontWeight: 'bold', color: '#474751' }}>Status</span>
                  <CheckboxField name="Active_YN" label="Active" />
                </div>
                <HOCInput keys={['tableSeq']}>{({ tableSeq }) => {
                  if (tableSeq.length === 0) return null;

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <MenuArrangement
                        control={control}
                        defaultData={defaultDataTableSeq}
                      />
                    </div>
                  );
                }}</HOCInput>

              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 8,
            backgroundColor: "#F7F7F7",
            padding: 24,
          }}
        >
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={_onHiding}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </DefaultDialog>
  );
}
