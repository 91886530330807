import * as React from "react";
import { Cookies } from "react-cookie";

import loadingBar from '../../../components/image/loadingBar.gif';
import WebStudioProvider, {
  useWebStudio,
} from "../app-editor/components/web-studio";
import Widgets from "../app-editor/components/widgets";
import Grid from "../app-editor/components/grid";
import { WIDGETS } from "../app-editor/components/widget.item";
import Card from "../app-editor/components/card";
import { SetCookies, Token } from "../../../utils/default-cookies";
import { GetLoadUdfTable, SaveUdfTable } from "../../../api/user-defined-field";
import ToolbarUDF from "../../../components/toolbar/toolbar-udf";
import styles from './detail-view.module.scss';
import { LoadPanel } from "devextreme-react/load-panel";

const cookies = new Cookies();

const Content = React.forwardRef(({ title }, ref) => {
  const { widgets, isHideGrid } = useWebStudio();

  React.useImperativeHandle(ref, () => ({
    getWidgets: widgets,
  }))

  return (
    <>
      <Widgets
        isDetail
        showViewTab
        showDeleteField={false}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          padding: 24,
        }}
      >
        <h4 style={{ fontSize: 32, fontWeight: 600, marginBottom: 24 }}>
          {title}
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            height: "fit-content",
            padding: 8,
            border: "4px solid #E4F0FD",
          }}
        >
          <Grid isHideGrid={isHideGrid}>
            {(actions) =>
              widgets.map((widget) => {
                const { Component: Widget } = WIDGETS[widget.type];
                return (
                  Widget && (
                    <Card key={widget.id} actions={actions} {...widget}>
                      <Widget {...widget} />
                    </Card>
                  )
                );
              })
            }
          </Grid>
        </div>
      </div>
    </>
  );
})

export default function UDFDetailView(props) {
  const [data, setData] = React.useState(null)
  const _data = React.useRef(null);
  const [widgets, setWidgets] = React.useState([]);
  const [initialLoad, setInitialLoad] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const contentRef = React.useRef(null);

  const fetchApi = React.useCallback(async (event) => {
    try {
      setIsLoading(true);
      const unescapeURL = decodeURIComponent(props.location.search);
      const ut_ID = parseInt(new URLSearchParams(unescapeURL).get("id"));
      const response = await GetLoadUdfTable({ token: Token(), tableId: ut_ID });
      if (response.result) {
        _data.current = {
          TableId: response.data.UdfTable.UT_ID,
          AppId: response.data.UdfTable.UT_UdfAppId,
          MenuType: response.data.UdfTable.UT_MenuType,
          MenuSeq: response.data.UdfTable.UT_MenuSeq,
          Caption: response.data.UdfTable.UT_Caption,
          TableName: response.data.UdfTable.UT_TableName,
          DocNoPrefix: response.data.UdfTable.UT_DocNoPrefix,
          ExistingFields: response.data.ExistingFields,
        };
        const layout = response.data.UdfTable.UT_Layout
          ? JSON.parse(response.data.UdfTable.UT_Layout)
          : [];
        setTitle(response.data.UdfTable.UT_TableName);
        setData(response.data);
        setWidgets(layout);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [props.location.search]);

  React.useEffect(() => {
    if (Token()) {
      fetchApi();
    }
  }, [fetchApi]);

  const goBack = () => {
    const pathname = "/user-defined-field/module-editor";
    props.history.push({
      pathname,
      state: { fromListing: true }
    });
  }

  const goListView = () => {
    const pathname = "/user-defined-field/list";
    const unescapeURL = decodeURIComponent(props.location.search);
    const ut_ID = parseInt(new URLSearchParams(unescapeURL).get("id"));
    const queryString = `?${encodeURIComponent(`id=${ut_ID}`)}`;
    props.history.push({
      pathname,
      search: queryString,
      state: { fromListing: true }
    })
  }

  const onSaveClick = async () => {
    try {
      console.log(contentRef.current.getWidgets);
      const widgets = contentRef.current.getWidgets.map(widget => {
        delete widget.children;
        delete widget.actions;
        return widget
      })
      const response = await SaveUdfTable({
        token: Token(), body: {
          ..._data.current,
          Layout: JSON.stringify(widgets),
        }
      })

    } catch (error) {
      console.log(error);
    }

  }

  return (
    <div className={styles.container}>
      {!isLoading ? (
        <WebStudioProvider isEdit widgets={widgets}>
          <ToolbarUDF
            displayBackBtn={true}
            displaySaveBtn={true}
            displayHideGrid={true}
            displayInfoBtn={true}
            displayListViewBtn={true}
            displayDetailViewBtn={true}
            isFocusDetail={true}
            goBackClick={goBack}
            listViewClick={goListView}
            saveClick={onSaveClick}
          />
          <div style={{ display: "flex", flex: 1 }}>
            <Content title={title} ref={contentRef} />
          </div>
        </WebStudioProvider>
      ) : <LoadPanel indicatorSrc={loadingBar} visible={true} />}
    </div>
  );
}
