import React from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
//language
import { formatMessage } from 'devextreme/localization';

const ErrorPopUp = (props) => {

    const sessionClose = () => {
        props.parentCallback();
    }
    return (
        <Popup
            id ="error-popup"
            className="error-popup-center"
            onHiding={props.hidePopup}
            visible={props.visible}
            showTitle={false}
            closeOnOutsideClick={false}
            dragEnabled={false}
            width={"auto"}
            height={"auto"}>
            <Form colCount={4}>
                <Item colSpan={4}>
                    <p className="middle-content-title">{props.title}</p>
                    <div className="session-line-1">{props.subTitle}</div>
                </Item>
            </Form>
                <div className="middle-btn-container">
                    <Button
                        className="middle-btn"
                        text={formatMessage("OK")}
                        type="normal"
                        stylingMode="contained"
                        onClick={sessionClose}>
                    </Button>
                </div>
        </Popup>
    );
    
}

export default ErrorPopUp;