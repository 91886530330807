import React, { Component } from 'react'

export const rowNumCell = () => function (props) {
    return (
        <RowNumCell
            {...props}
        />
    )
}

export class RowNumCell extends Component {
    render() {
        const rowNum = this.props.component.pageIndex() * this.props.component.pageSize() + this.props.rowIndex + 1;
        return rowNum;
    }
}
