import TreeList, { Column, ColumnChooser, FilterRow, LoadPanel, StateStoring } from 'devextreme-react/tree-list';
import { LoadPanel as RootLoadPanel } from "devextreme-react/load-panel";
import produce from 'immer';
import * as React from "react";
import { Cookies } from "react-cookie";

import { PlusCircleFillIcon } from "../../../assets";
import AddNewModulePopup from "./add-new-module.popup";
import AddMenuPopup from "./add-menu.popup";
import { DeleteUdfApp, DeleteUdfTable, GetListAllUdfApps, GetLoadUdfApp, GetLoadUdfTable, SaveUdfApp, SaveUdfTable } from "../../../api/user-defined-field";
import { actionButtonCell, checkboxCell } from "../custom-cell/module-editor";
import EditModulePopup from "./edit-module.popup";
import useDialog from "../../../components/custom-dialog/use-dialog";
import { customSearch, Flash, TextBox2 } from "../../../components";
import loadingBar from '../../../components/image/loadingBar.gif';
import DeleteMenuPopup from "./delete-menu.popup";
import styles from './module-editor.module.scss';
import { formatDate, formatMessage } from "devextreme/localization";
import ErrorPopUp from "../../../components/pop-up/error-popup";
import { POST_GetUser } from "../../../api/auth";
import TreeListChildShowFilterRow from "../../../components/context-menu/tree-list-context-menu";
import { DefaultStateStoringIgnore } from "../../../utils/default-data-grid-settings";

//default 
import {
  CompanyID,
  UserID,
  ClientID,
  SetCookies,
  Token,
} from '../../../utils/default-cookies';
import { DefaultSMIModuleEditor } from '../../../utils/default-smi';

//api
import { PostUserLayout, GetUserLayout } from '../../../api/user-preferred-layout';
import EditMenuPopup from './edit-menu.popup';
import apiEndpoints from '../../../api/api-endpoints';
import { getReactParameters } from '../../../utils/iframe-func';

// dx save state function not allow to call class component -> this 
// var moduleItemID = 0;
// var UserPreferedLayout = null;
const cookies = new Cookies();

const renderHeaderActionCell =
  ({ onClick }) =>
    () => {
      return (
        <button
          type="button"
          className={styles.renderHeaderCellBtn}
          onClick={onClick}
        >
          <PlusCircleFillIcon />
          <span>New</span>
        </button>
      );
    };

function transformerUT(data) {
  return {
    original: data,
    editBtnUrl: data.EditBtnUrl,
    moduleDetailEditorBtnUrl: data.ModuleDetailEditorBtnUrl,
    screenConfigurationBtnUrl: data.ScreenConfigBtnUrl,
    userGroupBtnUrl: data.UserGroupBtnUrl,
    appId: `UA-${data.UT_UdfAppId}`,
    id: `UT-${data.UT_ID}`,
    name: data.UT_TableName,
    caption: data.UT_Caption,
    sequence: data.UT_MenuSeq,
    activeYn: data.UT_Active_YN,
    dateCreated: data.UT_DateCreated,
    createdBy: data.UT_CreatedBy,
    dateModified: data.UT_DateModified,
    modifiedBy: data.UT_ModifiedBy,
    type: data.MenuType,
    menuType: data.UT_MenuType,
    associateGroup: data.UT_AssociateGroup,
    collapsable: null,
    parentId: `UA-${data.UT_UdfAppId}`,
    hideText: true,
  }
}

function transformerUA(data) {
  return {
    original: data,
    id: `UA-${data.UA_ID}`,
    name: data.UA_Name,
    caption: data.UA_Caption,
    sequence: data.UA_RibbonSeq,
    activeYn: data.UA_Active_YN,
    dateCreated: data.UA_DateCreated,
    createdBy: data.UA_CreatedBy,
    dateModified: data.UA_DateModified,
    modifiedBy: data.UA_ModifiedBy,
    type: "Module",
    collapsable: false,
    // parentId: data.UA_ID,
    parentId: 0,
  }
}

function HasRegexText(searchText) {
  const searchQuery = searchText.trim();
  const regExpText = "(".concat(
    searchQuery
      .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
      .split(" ").filter(el => el.length > 0)
      .sort((a, b) => b.length - a.length)
      .join(")|(")
      .concat(")")
  );

  const regExp = new RegExp(regExpText, "ig");
  return regExp;
}

export default function UDFModuleEditor(props) {
  const treelistRef = React.useRef(null);
  const childShowFilterRowRef = React.useRef(null);
  const [searchTextValue, setSearchTextValue] = React.useState("");
  const [visibleErrorPopup, setVisibleErrorPopup] = React.useState(false);
  const [errorPopupText, setErrorPopupText] = React.useState("");
  const [userDateFormat, setUserDateFormat] = React.useState("dd/MM/yyyy, HH:mm:ss");
  const [data, setData] = React.useState([]);
  const dialog = useDialog();
  const initialLoad = React.useRef(false);
  const tempAppId = React.useRef(null);
  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [flashMessageDialog, setFlashMessageDialog] = React.useState({
    show: false,
    state: "success",
    message: ""
  });
  const [showFilterRow, setShowFilterRow] = React.useState(false);
  const [moduleLayout, setModuleLayout] = React.useState({
    moduleItemID: 0,
    UserPreferedLayout: null,
  })
  const [displaySessionPopUp, setDisplaySessionPopup] = React.useState(false);

  const handleSessionPopUpCallBack = React.useCallback(() => {
    setDisplaySessionPopup(state => !state);
  }, [])

  const checkIsUnauthenticated = React.useCallback((error) => {
    if (error === "401") {
      handleSessionPopUpCallBack();
      return;
    }
  }, [handleSessionPopUpCallBack])

  const fetchApi = async () => {
    try {
      //load user preferred layout
      const responseUserPreferLayout = await GetUserLayout(Token(), UserID(), DefaultSMIModuleEditor.moduleURL, DefaultSMIModuleEditor.controlID);

      if (responseUserPreferLayout.length > 0) {
        setModuleLayout({
          moduleItemID: responseUserPreferLayout[0].UPL_ModuleItemID,
          UserPreferedLayout: responseUserPreferLayout
        })
      }

      const responseGetUser = await POST_GetUser({ token: Token() });

      if (responseGetUser.result) {
        setUserDateFormat(`${responseGetUser.data.US_DateFormat}, HH:mm:ss`);
      } else {
        setUserDateFormat("dd/MM/yyyy, HH:mm:ss");
      }

      const response = await GetListAllUdfApps({ token: Token() });
      const tempList = (response.data || []).filter(datum => datum.MenuType !== 'System').reduce((prev, curr) => {
        let tempData = [...prev];
        const uaData = transformerUA(curr);
        tempData = [...tempData, uaData];
        if (curr.UdfTables.length > 0) {
          const tempTableData = curr.UdfTables.map(dataTable => transformerUT(dataTable));
          tempData = [...tempData, ...tempTableData];
        }
        return tempData;
      }, [])
      setData(tempList);

    } catch (error) {
      checkIsUnauthenticated(error.message);

      console.error(error);
    }
  };

  const receiveMessage = (event) => {
    if (event?.data?.accessToken && !Token()) {
      SetCookies(
        cookies,
        event.data.accessToken,
        event.data.userID,
        event.data.companyID,
        event.data.clientID,
        event.data.username,
        event.data.adminYN,
        event.data.administratorYN,
        event.data.language
      );
    }
    if (!initialLoad.current) {
      fetchApi();
      initialLoad.current = true;
    }
  };

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("message", receiveMessage, false);
      if (Token()) {
        fetchApi()
      } else {
        window.parent.postMessage(getReactParameters(), "*");
      }
      return () => {
        window.removeEventListener("message", receiveMessage, false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const treeList = treelistRef.current?.instance;

  const treeListNullCheck = React.useCallback(() => treeList.current !== null, [treeList]);

  const addMenuItems = (event) => {
    childShowFilterRowRef.current.TreeListContextMenuItemsService(event, treeList)
  }

  const onConfirmAddMenu = React.useCallback(async ({ values, dismissDialog, _data }) => {
    try {
      setIsFormLoading(true);
      const tempData = (_data ?? data);
      const foundDuplicate = tempData.filter(curr => curr.collapsable === null).find(curr => curr.name.toLowerCase() === values.TableName.toLowerCase());

      if (foundDuplicate) {
        setVisibleErrorPopup(true);
        setErrorPopupText("Duplicate Module / Menu Code");
        return;
      }
      const appId = typeof tempAppId.current === "string" ? tempAppId.current?.replaceAll("UA-", "") : tempAppId.current;
      const response = await SaveUdfTable({
        token: Token(),
        body: {
          ExistingFields: null,
          isNew: true,
          TableName: values.TableName,
          Caption: values.Caption,
          MenuType: values.MenuType,
          AppId: appId,
          AssociateGroup: values.AssociateGroup,
          Active_YN: values.Active_YN
        }
      })
      if (response.result && response?.data && response?.data.Saved) {
        setFlashMessageDialog({
          message: formatMessage('SavedSuccessfully'),
          show: true,
          state: "success"
        });
        const responseLoadUdfTable = await GetLoadUdfTable({ token: Token(), tableId: response.data.TableId });
        const responseLoadUdfApp = await GetLoadUdfApp({ token: Token(), AppId: appId });
        if (responseLoadUdfTable.result) {
          const udfTableData = transformerUT(responseLoadUdfTable.data.UdfTable);
          setData(prev => [...(responseLoadUdfApp.result ? produce(prev, (draft) => {
            draft.forEach((item, index) => {
              if (item.id === tempAppId.current) {
                draft[index] = transformerUA(responseLoadUdfApp.data);
              }
            })
          }) : prev), udfTableData]);
        }
        tempAppId.current = null;
        dismissDialog?.();
      } else {
        if (response.message) {
          setVisibleErrorPopup(true);
          setErrorPopupText(response.message || '-');
          return;
        }
      }
    } catch (error) {
      checkIsUnauthenticated(error.message);
      console.error(error);
    } finally {
      setIsFormLoading(false);
    }
  }, [data]);

  const onAddMenu = React.useCallback((event, data) => {
    if (event) {
      tempAppId.current = event.data.id;
    }

    dialog.show({
      render: close => {
        return (
          <AddMenuPopup
            onHiding={close}
            onConfirm={(values) => onConfirmAddMenu({ values, dismissDialog: close, _data: data })}
          />
        );
      }
    })
  }, [dialog, onConfirmAddMenu]);

  const onConfirmAddNewModule = React.useCallback(async (values, dismissDialog) => {
    try {
      setIsFormLoading(true);
      const findDuplicate = data.filter(curr => curr.collapsable !== null).find(curr => curr.name.toLowerCase() === values.Name.toLowerCase());
      if (findDuplicate) {
        setVisibleErrorPopup(true);
        setErrorPopupText("Duplicate Module / Menu Code");
        return;
      }
      const response = await SaveUdfApp({
        token: Token(),
        body: {
          Name: values.Name,
          Caption: values.Caption,
          Active_YN: values.Active_YN,
          RibbonSeq: values.RibbonSeq,
          isNew: true
        }
      });
      if (response.result && response?.data && response?.data.Saved) {
        setFlashMessageDialog({
          message: formatMessage('SavedSuccessfully'),
          show: true,
          state: "success"
        });
        tempAppId.current = response?.data.AppId;
        dismissDialog?.();

        const responseLoadUdfApp = await GetLoadUdfApp({ token: Token(), AppId: response.data.AppId });
        let tempData = [];
        setData(prev => {
          tempData = [...prev, transformerUA(responseLoadUdfApp.data)];
          return tempData;
        });

        dialog.showConfirmation({
          message: "Do you want to create a new Menu now?",
          onPositiveAction: (dismiss) => {
            dismiss();
            tempAppId.current = `UA-${response.data.AppId}`
            onAddMenu(undefined, tempData);
          },
          onNegativeAction: (dismiss) => dismiss(),
        })

      } else {
        setVisibleErrorPopup(true);
        setErrorPopupText(response.message || '-');
      }
    } catch (error) {
      checkIsUnauthenticated(error.message);
      console.error(error);
    } finally {
      setIsFormLoading(false)
    }
  }, [data, dialog, onAddMenu]);

  const onConfirmEditModule = async (values, dismissDialog) => {
    try {
      setIsFormLoading(true);
      const response = await SaveUdfApp({
        token: Token(),
        body: {
          AppId: values.AppId.replaceAll("UA-", ""),
          Name: values.Name,
          RibbonSeq: values.RibbonSeq,
          Active_Yn: values.Active_YN,
          Caption: values.Caption,
          TableIdSeq: values.TableIdSeq,
        }
      })
      if (response.result && response?.data && response?.data.Saved) {
        const responseLoadUdfApp = await GetLoadUdfApp({ token: Token(), AppId: response.data.AppId });
        console.log(responseLoadUdfApp.data);
        if (responseLoadUdfApp.result) {
          const flatternTableData = responseLoadUdfApp.data.UdfTables.flatMap(curr => `UT-${curr.UT_ID}`);
          const dataApp = transformerUA(responseLoadUdfApp.data);
          const dataTable = responseLoadUdfApp.data.UdfTables.map(curr => transformerUT(curr));
          setData(prev => {
            const prevData = prev.filter(curr => !flatternTableData.includes(curr.id));
            return [
              ...produce(prevData, (draft) => {
                return draft.forEach((item, index) => {
                  if (item.id === values.AppId) {
                    draft[index] = dataApp;
                  }
                });
              }),
              ...dataTable,
            ]
          });
        }
        setFlashMessageDialog({
          message: formatMessage('SavedSuccessfully'),
          show: true,
          state: "success"
        });
        dismissDialog?.()
      } else {
        setVisibleErrorPopup(true);
        setErrorPopupText(response.message || '-');
      }
    } catch (error) {
      checkIsUnauthenticated(error.message);

      console.error(error);
    } finally {
      setIsFormLoading(false);
    }
  };

  const getLastSequence = React.useCallback(() => {
    return data.filter(curr => curr.collapsable !== null).sort((a, b) => {
      if (a.sequence > b.sequence) return -1;
      else if (b.sequence > a.sequence) return 1;
      return 0;
    })?.[0]?.sequence
  }, [data])

  const onCreateNewApp = React.useCallback(() => {
    const lastSequence = getLastSequence() + 1 > 999 ? 999 : getLastSequence() + 1;
    dialog.show({
      render: (close) => {
        return (
          <AddNewModulePopup
            lastSequence={lastSequence}
            onHiding={close}
            onConfirm={(values) => onConfirmAddNewModule(values, close)}
          />
        );
      }
    });
  }, [dialog, getLastSequence, onConfirmAddNewModule]);

  const onConfirmEditMenu = async ({ values, dismissDialog, ExistingFields, tableId }) => {
    try {
      setIsFormLoading(true);
      delete values.MenuTypeText;
      const response = await SaveUdfTable({
        token: Token(),
        body: {
          ...values,
          ExistingFields,
        }
      })
      if (response.result && response.data && response.data.Saved) {
        setFlashMessageDialog({
          message: formatMessage('SavedSuccessfully'),
          show: true,
          state: "success"
        });
        dismissDialog?.();
        const responseLoadUdfTable = await GetLoadUdfTable({ token: Token(), tableId });
        if (responseLoadUdfTable.result) {
          const udfTableData = transformerUT(responseLoadUdfTable.data.UdfTable);
          setData(prev => produce(prev, (draft) => {
            draft.forEach((item, index) => {
              if (item.id === udfTableData.id) {
                draft[index] = udfTableData;
              }
            })
          }))
        } else {
          setVisibleErrorPopup(true);
          setErrorPopupText(responseLoadUdfTable.message || '-');
        }
      } else {
        setVisibleErrorPopup(true);
        setErrorPopupText(response.message || '-');
      }

    } catch (error) {
      checkIsUnauthenticated(error.message);

      console.error(error);
    } finally {
      setIsFormLoading(false)
    }
  }

  const onOpenEdit = async (event) => {
    if (event.data.collapsable === null) {
      try {
        setIsFormLoading(true);
        const tableId = event.data.id.replaceAll("UT-", "");
        const response = await GetLoadUdfTable({ token: Token(), tableId });
        if (response.result) {
          const data = transformerUT(response.data.UdfTable);
          dialog.show({
            render: close => {
              return (
                <EditMenuPopup
                  onHiding={close}
                  onConfirm={(values) => onConfirmEditMenu({
                    values,
                    dismissDialog: close,
                    ExistingFields: response.data.ExistingFields,
                    tableId,
                  })}
                  data={data}
                />
              );
            }
          })
        }
      } catch (error) {
        if (checkIsUnauthenticated(error.message)) {
          handleSessionPopUpCallBack();
          return;
        }
        console.error(error);
      } finally {
        setIsFormLoading(false);
      }

    } else {
      try {
        setIsFormLoading(true);
        const response = await GetLoadUdfApp({ token: Token(), AppId: event.data.id.replaceAll("UA-", "") });
        if (response.result) {
          const data = transformerUA(response.data);

          dialog.show({
            render: (close) => {
              return <EditModulePopup
                onHiding={close}
                visible={true}
                onConfirm={(values) => onConfirmEditModule(values, close)}
                data={data}
              />
            }
          });
        }
      } catch (error) {
        if (checkIsUnauthenticated(error.message)) {
          handleSessionPopUpCallBack();
          return;
        }
        console.error(error);
      } finally {
        setIsFormLoading(false);
      }
    }
  };

  const onConfirmDeleteMenu = async ({ id, close, isParent, parentId }) => {
    try {
      setIsFormLoading(true);
      const response = isParent ?
        await DeleteUdfApp({ token: Token(), AppId: id.replaceAll("UA-", "") })
        : await DeleteUdfTable({ token: Token(), tableId: id.replaceAll("UT-", "") });

      if (response.result && response.data) {
        let responseUdfApp
        if (!isParent) {
          responseUdfApp = await GetLoadUdfApp({ token: Token(), AppId: parentId.replaceAll("UA-", "") });
        }
        setData(prev => produce(prev, (draft) => {
          draft.forEach((item, idx) => {
            if (item.id === id && idx !== -1) {
              draft.splice(idx, 1)
            }
            if (!isParent && responseUdfApp?.result && item.id === parentId && idx !== -1) {
              draft[idx] = transformerUA(responseUdfApp.data);
            }
          })
        }))
        setFlashMessageDialog({
          message: formatMessage('DeletedSuccessfully'),
          show: true,
          state: "error"
        });
        close?.();
      } else {
        setVisibleErrorPopup(true);
        setErrorPopupText(response.message || '-');
      }
    } catch (error) {
      checkIsUnauthenticated(error.message);

      console.error(error);
    } finally {
      setIsFormLoading(false);
    }
  };

  const onOpenConfirmationDeleteMenu = (event) => {
    const isParent = event.data.collapsable !== null;
    const parentId = event.data.parentId;

    dialog.show({
      render: close => (
        <DeleteMenuPopup
          title={isParent ? "Delete Module" : "Delete Menu"}
          message={`Are you sure you want to delete this ${isParent ? "module" : "menu"}?`}
          messageWarning={`The existing data in this ${isParent ? "module" : "menu"} will be removed?`}
          onHiding={close}
          onConfirm={() => onConfirmDeleteMenu({ id: event.data.id, close, isParent, parentId })}
        />
      )
    });
  };

  const getPostMessageObj = ({ tabName, itemUrl }) => {
    return {
      source: window.location.host,
      target: apiEndpoints.apiAlayaUrl,
      eval: `parent.addTab('${tabName}', '${itemUrl}')`
    }
  };

  const onUserGroupClick = (event) => {
    window.parent.postMessage(getPostMessageObj({
      tabName: 'User Group',
      itemUrl: event.data.userGroupBtnUrl,
    }), "*");
  };

  const onScreenConfigurationClick = (event) => {
    window.parent.postMessage(getPostMessageObj({
      tabName: 'Screen Configuration',
      itemUrl: event.data.screenConfigurationBtnUrl,
    }), "*");
  };

  const onDetailClick = (event) => {
    window.parent.postMessage(getPostMessageObj({
      tabName: "UDF Detail",
      itemUrl: event.data.moduleDetailEditorBtnUrl,
    }), "*")
  }

  const onHandleShowFilterRow = () => {
    setShowFilterRow(prev => !prev);
    onHandlerClearFilter();
  };

  const onHandlerClearFilter = () => {
    treeList?.clearFilter?.();
  };

  const onRedirectToInfoTable = (event) => {
    // const pathname = "/user-defined-field/detail";
    // const queryString = `?${encodeURIComponent(`id=${event.data.id.replaceAll("UT-", "")}`)}`;
    // props.history.push({
    //   pathname,
    //   search: queryString,
    //   state: { fromListing: true }
    // })
    onDetailClick(event);
  };

  const checkDataSearch = React.useCallback((data, isParent) => {
    if (data.name.match(HasRegexText(searchTextValue))) {
      return true;
    }
    if (data.type.match(HasRegexText(searchTextValue))) {
      return true;
    }
    if (isParent && data.sequence.toString().match(HasRegexText(searchTextValue))) {
      return true;
    }
    if (data.caption.toString().match(HasRegexText(searchTextValue))) {
      return true;
    }
    const createdDateFormated = formatDate(new Date(data.dateCreated), userDateFormat);
    if (createdDateFormated.match(HasRegexText(searchTextValue))) {
      return true;
    }
    const modifiedDateFormated = formatDate(new Date(data.dateModified), userDateFormat);
    if (modifiedDateFormated.match(HasRegexText(searchTextValue))) {
      return true;
    }
    return false;
  }, [searchTextValue, userDateFormat]);

  const _listData = React.useMemo(() => {
    const _data = data.filter(curr => {
      if (searchTextValue === "") return true;
      return curr.collapsable !== null;
    }).reduce((prev, datum) => {
      if (searchTextValue === "") return [...prev, datum];

      const tempChildList = datum.original.UdfTables.filter(curr => {
        const _transformData = transformerUT(curr);
        return checkDataSearch(_transformData);
      }).map(curr => transformerUT(curr));

      if (tempChildList.length > 0) return [...prev, datum, ...tempChildList];

      if (checkDataSearch(datum, true)) return [...prev, datum];

      return prev;
    }, [])
    return _data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchTextValue, userDateFormat]);

  const cellAction = {
    add: {
      enabled: true,
      onClick: onAddMenu,
    },
    edit: {
      enabled: true,
      onClick: onOpenEdit,
    },
    userGroup: {
      enabled: true,
      onClick: onUserGroupClick,
    },
    screenConfiguration: {
      enabled: true,
      onClick: onScreenConfigurationClick,
    },
    delete: {
      enabled: true,
      onClick: onOpenConfirmationDeleteMenu,
    },
    info: {
      enabled: true,
      onClick: onRedirectToInfoTable,
    }
  }

  React.useEffect(() => {
    let timeout;
    if (flashMessageDialog) {
      timeout = setTimeout(() => {
        setFlashMessageDialog(prev => ({ ...prev, show: false }));
      }, 1000)
    }

    return () => {
      if (flashMessageDialog) {
        clearTimeout(timeout)
      }
    }
  }, [flashMessageDialog])

  const flashMessageDialogSize = React.useMemo(() => (
    flashMessageDialog.state === "success" ? "flash-message-success-container" : "flash-message-error-container"
  ), [flashMessageDialog.state])

  const loadState = React.useCallback(() => {
    //load user preferred layout
    if (moduleLayout.UserPreferedLayout != null && moduleLayout.UserPreferedLayout.length > 0) {
      var userlayout = (JSON.parse(moduleLayout.UserPreferedLayout[0].UPL_Layout));
      return userlayout;
    }
  }, [moduleLayout.UserPreferedLayout]);

  const saveLayout = React.useCallback(async (state) => {
    if (moduleLayout.moduleItemID !== 0) {
      await PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleLayout.moduleItemID, DefaultSMIModuleEditor.controlID, DefaultSMIModuleEditor.moduleURL, "storageKey", state);
    }
  }, [moduleLayout.moduleItemID]);

  const restoreLayout = React.useCallback(() => {
    if (moduleLayout.moduleItemID) {
      PostUserLayout(Token(), CompanyID(), ClientID(), UserID(), moduleLayout.moduleItemID, DefaultSMIModuleEditor.controlID, DefaultSMIModuleEditor.moduleURL, "storageKey", " ");
    }

    if (treeListNullCheck()) {
      treeList.state("");
    }
  }, [moduleLayout.moduleItemID, treeList, treeListNullCheck])

  const showMsgHandler = (params, value) => {
    setFlashMessageDialog({ message: params, state: value, show: true });
  }

  return (
    <>
      <ErrorPopUp
        parentCallback={handleSessionPopUpCallBack}
        visible={displaySessionPopUp}
        title={formatMessage('SessionExpired')}
        subTitle={formatMessage('PleaseLoginAgain')}
      />
      <div className={styles.container}>
        <Flash
          className={styles.justifyCenter}
          visible={flashMessageDialog.show}
          message={flashMessageDialog.message}
          severity={flashMessageDialog.state}
          size={flashMessageDialogSize}
        />
        <RootLoadPanel indicatorSrc={loadingBar} visible={isFormLoading} />
        <ErrorPopUp
          parentCallback={() => { setVisibleErrorPopup(false) }}
          visible={visibleErrorPopup}
          title={"Error"}
          subTitle={errorPopupText}
        />
        <div className={styles.searchContainer}>
          <div style={{ marginLeft: -10, marginRight: -20, marginBottom: 32 }}>
            <TextBox2
              placeHolderText="Type key word & press enter to quick search"
              buttonStyle="search-word"
              buttonText="Search"
              value={searchTextValue}
              onTbvalueChange={setSearchTextValue}
              visible={true}
            />
          </div>
        </div>
        <TreeList
          autoExpandAll={false}
          allowColumnResizing={true}
          allowColumnReordering={true}
          columnAutoWidth={true}
          columnHidingEnabled={false}
          columnResizingMode="widget"
          dataSource={_listData}
          keyExpr="id"
          noDataText={formatMessage("Loading")}
          onContextMenuPreparing={addMenuItems}
          parentIdExpr="parentId"
          ref={treelistRef}
          showBorders={true}
          showRowLines={true}
        >
          <FilterRow visible={showFilterRow} />
          <LoadPanel indicatorSrc={loadingBar} enabled />
          <ColumnChooser enabled={false} mode="select" />

          <StateStoring
            enabled
            type="custom"
            customSave={saveLayout}
            customLoad={loadState}
            ignoreColumnOptionNames={DefaultStateStoringIgnore}
            savingTimeout={1000} //default is 2000  
          />
          <Column
            dataField="customId"
            allowResizing={false}
            allowHiding={false}
            showInColumnChooser={false}
            width={180}
            allowSorting={false}
            headerCellRender={renderHeaderActionCell({ onClick: onCreateNewApp })}
            {...data.length ? { cellRender: actionButtonCell(cellAction) } : {}}
          />
          <Column
            dataField="name"
            caption="Code"
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue })}
            width={250}
          />
          <Column
            dataField="caption"
            caption="Name"
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue })}
            width={250}
          />
          <Column
            dataField="type"
            caption="Type"
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue })}
            width={200}
          />
          <Column
            dataField="dateModified"
            caption="Modified On"
            dataType="datetime"
            format={userDateFormat}
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue })}
            width={200}
          />
          <Column
            dataField="dateCreated"
            caption="Created On"
            dataType="datetime"
            format={userDateFormat}
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue })}
            width={200}
          />
          <Column
            dataField="activeYn"
            caption="Active"
            cellRender={checkboxCell()}
            width={120}
          />
          <Column
            dataField="sequence"
            caption="Sequence"
            width={180}
            cellRender={customSearch({ allowSearch: true, searchText: searchTextValue, textHideable: true, })}
          />
        </TreeList>
      </div>
      <TreeListChildShowFilterRow
        displayRestoreLayout={true}
        displayColumnChooser={true}
        displayExportGrid={true}
        ref={childShowFilterRowRef}
        showRestoreMsg={showMsgHandler}
        getChildFilterRow={onHandleShowFilterRow}
        data={data}
        dateFormat={userDateFormat}
        restoreLayout={restoreLayout}
      />
    </>
  );
}
