import "./popup-module-editor.scss";

import classNames from 'classnames';
import React from "react";
import { useController, useForm } from "react-hook-form";

import { Form } from "../app-editor/form";
import Button from "../app-editor/components/button/button";
import DefaultTextBox from "../../../components/text-box/text-box";
import DefaultCheckbox from "../../../components/check-box/check-box";
import DefaultDialog from "../../../components/custom-dialog/default-dialog";
import DefaultNumberBox from "../../../components/number-box/number-box";
import styles from './popup-module-editor.module.scss';

function Input({ name, label, placeholder, errorMessage, required, onAfterChanged, pattern }) {
  const { field, fieldState } = useController({ name, rules: { required, pattern, validate: (value) => !!value.trim() }, });

  const errorText = () => {
    if (fieldState.error.type === 'required' || fieldState.error.type === "validate") {
      return <span className="errorText">This field is required</span>
    };
    if (fieldState.error.type === 'pattern') {
      return (
        <span className="errorText">
          The value must be at least 3 characters, starts with an alphabet and only allow alphanumeric.
        </span>
      );
    };
    return "";
  }

  const onChange = (value) => {
    field.onChange(value);
    onAfterChanged?.(value)
  }

  return (
    <DefaultTextBox
      onTbvalueChange={onChange}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function NumberInput({ name, label, placeholder, errorMessage, disabled, required }) {
  const { field, fieldState } = useController({
    name,
    rules: {
      minLength: 1,
      required
    }
  });

  const errorText = () => {
    if (fieldState.error.type === 'required') {
      return <span className="errorText">This field is required</span>
    };
    return "";
  }

  return (
    <DefaultNumberBox
      onValueChanged={(val) => {
        field.onChange(val);
      }}
      value={field.value}
      name={field.name}
      caption={label}
      placeholder={placeholder}
      stylingMode="outlined"
      displayStar={required}
      isResetStyle
      errorMessage={errorMessage}
      disabled={disabled}
      min={1}
      max={999}
      step={1}
      format="#"
      renderBottom={fieldState.invalid ? errorText() : undefined}
    />
  );
}

function CheckboxField({ name, label }) {
  const { field } = useController({ name });

  return (
    <div style={{ width: "100%" }}>
      <DefaultCheckbox
        onValueChanged={(value) => field.onChange(value)}
        value={field.value}
        name={field.name}
        label={label}
        labelWidth={100}
        flexReverse
        isResetStyle
        align="center"
      />
    </div>
  );
}

const defaultValues = {
  Name: "",
  Active_YN: true,
  Caption: "",
  RibbonSeq: 1,
}

export default function AddNewModulePopup(props) {
  const { onHiding, onConfirm, lastSequence } = props;

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      RibbonSeq: lastSequence ?? 1,
    },
  });
  const { reset } = methods;

  const onSubmit = async (values) => {
    try {
      onConfirm?.(values);
    } catch (error) {
      console.error(error);
    }
  };

  const _onHiding = () => {
    reset(defaultValues);
    onHiding(false);
  };

  return (
    <DefaultDialog
      title="Add New Module"
      width={400}
      height={488}
      onClose={_onHiding}
    >
      <Form style={{ height: '100%' }} methods={methods} onSubmit={onSubmit}>
        <div className={classNames(styles.content, styles.bodyPad)} style={{ position: 'relative', flex: 1 }}>
          <div style={{ position: 'absolute', inset: 0 }}>
            <div style={{ height: '100%', overflow: 'auto', position: 'relative', padding: 24, gap: 16, display: 'flex', flexDirection: 'column' }}>
              <Input
                name="Name"
                label="Module Code"
                required
                errorMessage="This name must be unique and not editable after saving"
                pattern={/^[a-z|A-Z]{1}[a-zA-Z0-9]{2,}$/}
              />
              <Input
                name="Caption"
                label="Module Name"
                required
              />
              <NumberInput
                name="RibbonSeq"
                label="Sequence"
                required
              />
              <CheckboxField name="Active_YN" label="Active" />
            </div>
          </div>
        </div>
        <div className={styles.footerContainer}>
          <Button type="submit">Confirm</Button>
          <Button
            type="button"
            variant="secondaryBlue"
            onClick={() => {
              onHiding(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </DefaultDialog>
  );
}
