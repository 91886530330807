import { Button } from "devextreme-react";
import PropTypes from "prop-types";
import * as React from "react";
import styles from './dialog.module.scss';
import classNames from 'classnames';

export default function DefaultDialog(props) {
  const { width = 500, height = 600 } = props;
  const [alignItems, setAlignItems] = React.useState("center");

  React.useEffect(() => {
    const comparingHeight = (event) => {
      if (event.currentTarget.innerHeight > height) {
        setAlignItems("center");
      } else {
        setAlignItems("flex-start");
      }
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', comparingHeight);
      return () => {
        window.removeEventListener('resize', comparingHeight);
      }
    }
  }, [height]);

  // #region Initial 
  React.useEffect(() => {
    if (window.innerHeight > height) {
      setAlignItems("center");
    } else {
      setAlignItems("flex-start");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // #endregion

  return (
    <div
      className={classNames(styles.dialogContainer, props.className)}
      style={{ alignItems }}
    >
      <div
        className={styles.dialogOutsideClick}
        onClick={props.onClose}
      />
      <div
        style={{
          width,
          maxWidth: "90%",
          height,
        }}
        className={styles.dialogContent}
      >
        {props.title && (
          <div className={styles.titleContainer}>
            {props.titlePosition === 'center' ?
              <Button icon="close" style={{ opacity: 0 }} disabled />
              :
              <></>
            }
            <span className={styles.dialogTitle}>{props.title}</span>
            <Button icon="close" className={styles.dialogCloseBtn} onClick={props.onClose} />
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}

DefaultDialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
  titlePosition: PropTypes.oneOf(['left', 'center'])
}
