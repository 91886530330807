import * as React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#757575", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      ref={ref}
      fill={color}
      {...rest}
    >
      <path d="M208 40H48a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h160a24 24 0 0 0 24-24V64a24 24 0 0 0-24-24Zm8 136a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V64a8 8 0 0 1 8-8h160a8 8 0 0 1 8 8Zm-48 48a8 8 0 0 1-8 8H96a8 8 0 0 1 0-16h64a8 8 0 0 1 8 8Z" />
    </svg>
  );
};

const MonitorIcon = forwardRef(SvgComponent);

export default MonitorIcon;
