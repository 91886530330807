import React from "react";
import TagBox from 'devextreme-react/tag-box';

export const ListingHeaderTagBox = (props) => {

    const _onValueChanged = (value) => {
        console.log(value);
        props.parentCallback(value)
    }

    const _onSelectionChanged = (e) => {
        //get value in array and turn into string 
        const value = e.component.option("value");
    }

    return (
        <>
            <div className="dx-fieldset-text">
              <div className="dx-field">
                <div className="dx-field-label">
                  <div>
                    <span style={{ color: "red" }}>* </span>
                    <span>{props.caption}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className= {"dx-fieldset"}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <TagBox
                          valueExpr              ={props.valueExpr}
                          dataSource             ={props.dataSource}
                          displayExpr            ={props.displayExpr}
                          value                  ={props.defaultValue}
                          placeholder            ="Select a value"
                          className              ="tagBox filter-drop-down"
                          stylingMode            ={'outlined'}
                          showSelectionControls  ={true}
                          showMultiTagOnly       ={true}
                          showDropDownButton     ={true}
                          searchEnabled          ={true}
                          onValueChange          ={_onValueChanged}
                          maxDisplayedTags       ={3}>
                        </TagBox>
                    </div>
                </div>
            </div>
        </>
    )
}
