import * as React from "react";
import { forwardRef } from "react";

const SvgComponent = (props, ref) => {
  const { width = 16, height = 16, color = "#FFFFFF", ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 256 256"
      ref={ref}
      fill={color}
      {...rest}
    >
      <path d="M128 24a104 104 0 1 0 104 104A104.13 104.13 0 0 0 128 24Zm40 112h-32v32a8 8 0 0 1-16 0v-32H88a8 8 0 0 1 0-16h32V88a8 8 0 0 1 16 0v32h32a8 8 0 0 1 0 16Z" />
    </svg>
  );
};

const PlusCircleFillIcon = forwardRef(SvgComponent);

export default PlusCircleFillIcon;
