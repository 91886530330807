const apiEndpoints = {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    apiAlayaUrl: process.env.REACT_APP_API_ALAYA_URL,

    // Item
    advanceSearchItems: `${process.env.REACT_APP_API_BASE_URL}api/Item/GetAdvanceSearchItemByCompanyID?`,
    advanceSearchNotIncludePackageItems: `${process.env.REACT_APP_API_BASE_URL}api/Item/GetAdvanceSearchNotIncludePackageItemByCompanyID?`,
    getItemUomByItemId: `${process.env.REACT_APP_API_BASE_URL}api/ItemUOM/getItemUOMByItemID?`,

    // Package Item
    packageItem: `${process.env.REACT_APP_API_BASE_URL}api/InventoryPackageItem/GetPackageItemByCompanyID?`,
    packageItemDetails: `${process.env.REACT_APP_API_BASE_URL}api/InventoryPackageItem/GetChildPackageItemByParentItemID?`,
    postPackageItem: `${process.env.REACT_APP_API_BASE_URL}api/InventoryPackageItem/PostPackageItem?`,
    getItemUrl: `${process.env.REACT_APP_API_BASE_URL}api/InventoryPackageItem/GetAddNewItemURL?`,
    getUserAccess: `${process.env.REACT_APP_API_BASE_URL}api/Users/GetUserGroupAccessRightsByModuleItem?`,
    getItemByCode: `${process.env.REACT_APP_API_BASE_URL}api/Item/GetPackageSubItemByCodeOrPluOrBarCode?`,

    // User Prefered Layout
    getUserPerferedLayout: `${process.env.REACT_APP_API_BASE_URL}api/Users/GetUserPreferedLayoutByModuleItemID?`,
    postUserPreferedLayout: `${process.env.REACT_APP_API_BASE_URL}api/Users/PostUserPreferedLayout?`,

    // Cost-Mapping
    getCostMapping: `${process.env.REACT_APP_API_BASE_URL}api/InventoryCostMap/GetCostMapByCompanyID?`,
    postCostMapping: `${process.env.REACT_APP_API_BASE_URL}api/InventoryCostMap/PostCostMap?`,
    deleteCostMapping: `${process.env.REACT_APP_API_BASE_URL}api/InventoryCostMap/DeleteCostMap?`,

    // Purchase Invoice
    getPurchaseInvoiceHDR: `${process.env.REACT_APP_API_BASE_URL}api/PurchaseInvoice/GetPurchaseInvoiceHDRByDateType?`,
    cancelPurchaseInvoiceHDR: `${process.env.REACT_APP_API_BASE_URL}api/PurchaseInvoice/CancelPurchaseInvoice?`,

    // User Define Field
    getListAllUdfApps: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/ListAllUdfApps`,
    getLoadUdfApp: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/LoadUdfApp`,
    postSaveUdfApp: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/SaveUdfApp`,
    getDeleteUdfApp: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/DeleteUdfApp`,
    getListAllUdfTables: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/ListAllUdfTables`,
    getLoadUdfTable: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/LoadUdfTable`,
    getDeleteUdfTable: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/DeleteUdfTable`,
    postSaveUdfTable: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/SaveUdfTable`,
    putSyncUdfToDbSchema: `${process.env.REACT_APP_API_BASE_URL}api/UserDefinedField/SyncUdfToDbSchema`,

    // User
    postGetUser: `${process.env.REACT_APP_API_BASE_URL}api/Users/GetUser`,
}

export default apiEndpoints;