export const DefaultPager =  {
    allowedPageSizes_listing: [10,20,30,40],
    allowedPageSizes_details: [5,10,15,20],
    defaultPageSize_listing: 10,
    defaultPageSize_details: 5
}

export const DefaultStateStoringIgnore =  [
  "sortIndex",
  "sortOrder",
  "filterValue"
]
