import React, { useCallback, useState } from 'react';
import Form from 'devextreme-react/form';
import { Item } from 'devextreme-react/select-box';
import { Button } from 'devextreme-react/button';

//language
import { formatMessage } from 'devextreme/localization';
import ReactTooltip from 'react-tooltip';

//components
import { 
    Singleselectiondropdowntable, 
    CalenderSelectionDropdown, 
    Singleselectiondropdown,
    TextBox2 
} from '../../../components/index';

//cookies
import { SearchText } from '../../../utils/default-cookies';
import ListingHeader3 from './listing-header-3';
import './listing-header-4.scss';

// default value
import { DefaultPIDateTypeSource, DefaultPIDateTypeValue } from '../../../utils/default-date-type';
import { DefaultDocStatusSource, InitialSelectedDocStatus } from '../../../utils/default-doc-status';

const ListingHeader4 = ( props ) => {

    const [searchValue, setSearchValue] = useState(SearchText()); 
    const [tagValue, setTagValue] = useState(InitialSelectedDocStatus); 
    const [dateTypeValue, setDateTypeValue] = useState(InitialSelectedDocStatus); 

    // company
    const onSelectionChanged = useCallback((selection,displayProp,valueProp) => {
        props.onSelectValueChange(selection[valueProp]);
    }, [props.onSelectValueChange]);

    // doc status
    const onTagChanged = useCallback((value) => {
        setTagValue(value)
        props.onTagValueChange(value)
    },[props.onTagValueChange]);

    const onDateTypeChanged = (value) => {
        setDateTypeValue(value)
        props.onDateTypeValueChange(value)
    }
    
    // search
    const onTbvalueChange = useCallback((value) => {
        setSearchValue(value);
        props.onSearchValueChange(value);
    }, [props.onSearchValueChange]);   

    const onDocActionBtnClick = () => {
        props.onDocActionBtnClick()
    }

    return(
        <>
        <Form  colCount={8}>
            <Item colSpan={2}>
                <Singleselectiondropdowntable 
                        dataSource          = {props.dataSource} 
                        defaultValue        = {props.defaultValue}
                        defaultName         = {props.defaultName}
                        placeholder         = {props.placeholder}
                        caption             = {props.captionDropdown}
                        valueProp           = {props.valueProp}
                        codeProp            = {props.codeProp}
                        nameProp            = {props.nameProp}
                        displayProp         = {props.displayProp}
                        onSelectionChanged  = {onSelectionChanged} 
                />
            </Item>
            
            <Item colSpan={4}></Item>

            <Item colSpan={2}>
                <ListingHeader3
                    caption         = {props.captionTagbox}
                    dataSource      = {DefaultDocStatusSource}
                    defaultValue    = {tagValue}
                    textField       = {'dx-fieldset-text'}
                    inputField      = {'dx-fieldset'}
                    valueExpr       = {'displayValue'}
                    displayExpr     = {'displayValue'}
                    parentCallback  = {onTagChanged} />
            </Item>

            <Item colSpan={2}>
                <Singleselectiondropdown
                    caption         = {props.captionDropdown2}
                    dataSource      = {DefaultPIDateTypeSource} 
                    defaultValue    = {DefaultPIDateTypeValue}
                    valueExpr       = {'value'}
                    displayExpr     = {'display'}
                    placeholder     = {props.placeholder} 
                    parentCallback  = {onDateTypeChanged}/>
                </Item>

            <Item colSpan={2}>
                <CalenderSelectionDropdown
                    caption     = {'Date'}
                    id          = {"calendar-dropdown-box"}
                    className   = {'filter-drop-down'}                    />
            </Item>

            <Item colSpan={1}>
                <div className="dx-fieldset">
                    <div className="dx-field">
                        <div className="dx-field-value-2" >
                            <Button 
                                icon        ={"glyphicon icon-filter-icon"}
                                type        = "default"
                                stylingMode = {"contained"}
                                text        = {props.buttonText4}
                                className   = {"filter-btn"}
                                onClick     = {props.onFilterClick} />
                        </div> 
                    </div>
                </div>
            </Item>

            <Item colSpan={3}></Item>

            <Item colSpan={8}>
                <hr className="purchase-invoice-line-separator"/>
            </Item>

            <Item colSpan={4}>
                <TextBox2
                    placeHolderText     = {props.placeHolderText}
                    buttonStyle         = {'search-word'}
                    buttonText          = {props.buttonText}
                    value               = {searchValue}
                    onTbvalueChange     = {onTbvalueChange}
                    visible             = {true}/>
            </Item>
            <Item colSpan={4}>
           
                <div className="dx-fieldset" >
                <div className="dx-field" >
                <div className="dx-field-value" >
                    <div data-tip = {formatMessage("PrintDocumentSendWhatsappOrSendEmail")}  data-for="docActionBtn" className="containerBtn">
                        <Button
                            id                  = {'docActionBtn'}
                            className           = {"doc-action-btn"}
                            text                = {props.buttonText3}
                            icon                = {"glyphicon icon-document-action-icon"}
                            type                = {"default"}
                            stylingMode         = {"contained"}
                            hoverStateEnabled   = {true}
                            activeStateEnabled  = {false}
                            focusStateEnabled   = {false}
                            onClick             = {props.onDocActionBtnClick}>
                        </Button>
                            <ReactTooltip id="docActionBtn" place="bottom" effect="solid"  arrowColor="#474751" />
                    </div>
                    <div className="containerBtn">
                        <Button
                            id                  = {"analysisBtn"}
                            className           = {"analysis-btn"}
                            text                = {props.buttonText2}
                            icon                = {"glyphicon icon-analysis-icon"}
                            type                = {"default"}
                            stylingMode         = {"contained"}
                            hoverStateEnabled   = {true}
                            activeStateEnabled  = {false}
                            focusStateEnabled   = {false}
                            onClick             = {props.onAnalysisBtnClick}/>
                    </div>
                </div>
                </div>
                </div>  
            </Item>
        </Form>
        </>
    )
}

export default ListingHeader4;