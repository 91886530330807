import { useWebStudio } from "./web-studio";
import { useEffect, useMemo, useState } from "react";
import { Freeze } from "./freeze";
import Button from "./button/button";

import DefaultNumberBox from "../../../../components/number-box/number-box";
import DefaultCheckbox from "../../../../components/check-box/check-box";
import DefaultSelectBox from "../../../../components/select-box/select-box";
import widgetProperty, {
  TypeSelectionOption,
  uniquableFields,
  WIDGET_PROPERTY_TITLE,
  widgetLabelText,
} from "./widget-properties";
import SelectionPopup from "./selection-popup/selection-popup";
import DefaultTextBox from "../../../../components/text-box/text-box";
import styles from "./style.module.scss";
import Tabs from "./tab/tabs";
import { GearIcon, MonitorIcon, PlusCircleIcon } from "../../../../assets";
import { WIDGET_COMPONENTS, WIDGET_NEW_FIELDS, WIDGETS } from "./widget.item";
import useDialog from "../../../../components/custom-dialog/use-dialog";

function Properties(props) {
  const { showDeleteField, showRemoveFromView } = props;
  const { state, onChangeProperties, onRemoveWidget, activeTab } =
    useWebStudio();
  const dialog = useDialog();
  if (!state) return null;

  const propType = state?.type;

  const isRequiredable =
    propType !== "checkboxField" &&
    propType !== "button" &&
    propType !== "tabs";
  const isSelection = propType === "selectionField";
  const isUniqueable = uniquableFields.includes(propType);
  const isTab = propType === "tabs" && activeTab;

  const TabConfiguration = () => {
    if (!isTab) return null;
    const currentTab = state.tabs.find((tab) => tab.id === activeTab);

    const _onChangeProps = (val, key) => {
      return state.tabs.map((tab) => {
        if (tab.id !== activeTab) return tab;
        return { ...tab, [key]: val };
      });
    };

    const _onRemoveTab = (key) => {
      return state.tabs.filter((tab) => tab.id !== key);
    };

    return (
      <>
        <DefaultTextBox
          caption="Title"
          value={currentTab?.title}
          onTbvalueChange={(val) => {
            onChangeProperties({
              ...state,
              tabs: _onChangeProps(val, "title"),
            });
          }}
          stylingMode="outlined"
          labelClassName="white-label"
          hideColon
        />
        <DefaultNumberBox
          caption="Width"
          value={currentTab?.width}
          onValueChanged={(val) => {
            onChangeProperties({
              ...state,
              tabs: _onChangeProps(val, "width"),
            });
          }}
          min={10}
          stylingMode="outlined"
          labelClassName="white-label"
          hideColon
        />
        <div className={styles.buttonContainer}>
          <Button
            children="Remove Tab"
            className={styles.button}
            variant="solidRed"
            onClick={() => {
              onChangeProperties({
                ...state,
                tabs: _onRemoveTab(activeTab),
              });
            }}
          />
        </div>
      </>
    );
  };

  const selectionOptions = (key) => {
    if (key === "selectionType") {
      return TypeSelectionOption;
    }
    if (key === "defaultValue") {
      return state?.options || [];
    }
    if (key === "variant") {
      return ["Primary", "Secondary"];
    }
    return [];
  };

  const title = (() => {
    if (state) {
      return WIDGET_PROPERTY_TITLE[propType];
    }

    return null;
  })();


  return (
    <div className={styles.propertyContainer}>
      <div className={styles.titleContainer}>
        <h6 style={{ color: "white", fontSize: 18, fontWeight: "bold" }}>
          {title}
        </h6>
      </div>
      {isRequiredable && (
        <div className={styles.checkboxContainer}>
          <DefaultCheckbox
            label="Required"
            value={state.required}
            onValueChanged={(val) => {
              onChangeProperties({
                ...state,
                required: val,
              });
            }}
            flexReverse
            align="center"
            isResetStyle
            labelWidth={100}
            checkColor="#4CC85B"
            labelClassName="white-label"
          />
        </div>
      )}
      {isUniqueable && (
        <div className={`${styles.checkboxContainer} ${styles.checkboxPadBottom}`}>
          <DefaultCheckbox
            label="Unique"
            value={state.unique}
            onValueChanged={(val) => {
              onChangeProperties({
                ...state,
                unique: val,
              });
            }}
            flexReverse
            align="center"
            isResetStyle
            labelWidth={100}
            checkColor="#4CC85B"
            labelClassName="white-label"
          />
        </div>
      )}
      <TabConfiguration />
      {isSelection && (
        <div className={styles.buttonContainer}>
          <Button
            children="Edit Values"
            className={styles.button}
            onClick={() => {
              dialog.show({
                render: (close) => {
                  return <SelectionPopup
                    onHiding={close}
                    visible={true}
                    items={state.options}
                    onItemsChange={(value) => {
                      onChangeProperties({ ...state, options: value });
                    }
                    }
                  />
                }
              })
            }}
          />
        </div>
      )}
      <div className={styles.inputContainer}>
        {Object.keys(widgetProperty[propType]).map((key) => {
          let min = 0;
          let max = undefined;
          let step = 1;

          if ((propType === 'integerField' || propType === 'decimalField') && key !== 'decimalPoint') {
            if ('decimalPoint' in state && typeof state.decimalPoint !== 'undefined') {
              const decimalPoint = state['decimalPoint'];
              const decimalStep = '0.' + "0".repeat(decimalPoint ? decimalPoint - 1 : 0) + (decimalPoint ? '1' : '');
              step = parseFloat(decimalStep);
            }

            if (key === 'maximumValue') {
              min = state.minimumValue + 1;
            }

            if (key === 'defaultValue') {
              min = state.minimumValue;
              max = state.maximumValue;
            }
          }

          switch (widgetProperty[propType][key]) {
            case "textField":
              return (
                <DefaultTextBox
                  caption={widgetLabelText[key]}
                  value={state[key]}
                  onTbvalueChange={(val) => {
                    onChangeProperties({
                      ...state,
                      [key]: val,
                    });
                  }}
                  stylingMode="outlined"
                  labelClassName="white-label"
                  hideColon
                />
              );
            case "numberField":
              return (
                <DefaultNumberBox
                  caption={widgetLabelText[key]}
                  value={state[key]}
                  onChange={(val) => {
                    onChangeProperties({
                      ...state,
                      [key]: val,
                    });
                  }}
                  stylingMode="outlined"
                  labelClassName="white-label"
                  hideColon
                  min={min}
                  max={max}
                  step={step}
                />
              );
            case "selectionField":
              return (
                <DefaultSelectBox
                  caption={widgetLabelText[key]}
                  value={state[key]}
                  onValueChanged={(val) => {
                    onChangeProperties({
                      ...state,
                      [key]: val,
                    });
                  }}
                  stylingMode="outlined"
                  labelClassName="white-label"
                  items={selectionOptions(key)}
                  hideColon
                />
              );
            default:
              return <></>;
          }
        })}
      </div>
      {showRemoveFromView && (
        <div className={styles.buttonContainer}>
          <Button
            children="Remove From View"
            variant="secondary"
            className={styles.button}
            onClick={() => {
              onRemoveWidget(state.id);
            }}
          />
        </div>
      )}
      {showDeleteField && (
        <div className={styles.buttonContainer}>
          <Button
            children="Delete Field"
            className={styles.button}
            variant="solidRed"
            onClick={() => {
              onRemoveWidget(state.id);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default function Widgets({ isDetail, fieldList, isListView = false, showViewTab = false, showRemoveFromView = true, showDeleteField = true }) {
  const { state } = useWebStudio();
  const isFocus = !!state;
  const tabItems = useMemo(
    () => [
      {
        key: "add",
        text: "Add",
        icon: <PlusCircleIcon />,
      },
      ...(showViewTab ? [{
        key: "view",
        text: "View",
        icon: <MonitorIcon width={24} height={24} color="#FFFFFF" />,
        disabled: true
      },] : []),
      {
        key: "properties",
        text: "Properties",
        icon: <GearIcon />,
        disabled: !isFocus,
      },
    ],
    [isFocus, showViewTab]
  );

  const [tab, setTab] = useState(tabItems[0]);

  useEffect(() => {
    if (!state) {
      setTab(tabItems[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div className={styles.tabContainer}>
      <Tabs items={tabItems} currentTab={tab} onChangeTab={setTab} />

      <Freeze freeze={tab.key !== "add"}>
        <aside
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            padding: 24,
          }}
        >
          {isDetail && (
            <>
              <h3 style={{ fontSize: 18, fontWeight: "bold", color: "white" }}>
                Components
              </h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 8,
                }}
              >
                {WIDGET_COMPONENTS.map((key) => {
                  const value = WIDGETS[key];
                  return (
                    <div
                      key={key}
                      data-type={key}
                      data-name={value.name}
                      data-placeholder={value.placeholder}
                      data-label={value.label}
                      data-size={value.size}
                      data-maximum_value={value.maximum_value}
                      data-minimum_value={value.minimum_value}
                      data-decimal_point={value.decimal_point}
                      data-label_width={value.label_width}
                      data-options={value.options}
                      data-required={value.required}
                      data-childrenable={value.childrenable}
                      className="droppable grid-stack-item"
                      gs-h={value.h}
                      gs-w={value.w}
                      gs-min-h={value.minH}
                      gs-min-w={value.minW}
                      gs-max-h={value.maxH}
                      gs-max-w={value.maxW}
                      style={{ display: "flex", flexBasis: "48%" }}
                    >
                      <div className="droppable-icon-container">
                        {value.icon}
                      </div>
                      <span>{value.label}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {!isListView && (
            <>
              <h3 style={{ fontSize: 18, fontWeight: "bold", color: "white" }}>
                New Field
              </h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 8,
                }}
              >
                {WIDGET_NEW_FIELDS.map((key) => {
                  const value = WIDGETS[key];
                  return (
                    <div
                      key={key}
                      data-type={key}
                      data-name={value.name}
                      data-placeholder={value.placeholder}
                      data-label={value.label}
                      data-size={value.size}
                      data-maximum_value={value.maximum_value}
                      data-minimum_value={value.minimum_value}
                      data-decimal_point={value.decimal_point}
                      data-label_width={value.label_width}
                      data-options={value.options}
                      data-required={value.required}
                      data-childrenable={value.childrenable}
                      className="droppable grid-stack-item"
                      gs-h={value.h}
                      gs-w={value.w}
                      gs-min-h={value.minH}
                      gs-min-w={value.minW}
                      gs-max-h={value.maxH}
                      gs-max-w={value.maxW}
                      style={{ display: "flex", flexBasis: "48%" }}
                    >
                      <div className="droppable-icon-container">
                        {value.icon}
                      </div>
                      <span>{value.label}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {fieldList && (
            <>
              <h3 style={{ fontSize: 18, fontWeight: "bold", color: "white" }}>
                Fields List
              </h3>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  gap: 8,
                }}
              >
                {["textField"].map((key) => {
                  const value = WIDGETS[key];
                  return (
                    <div
                      key={key}
                      data-type={key}
                      data-name={value.name}
                      data-placeholder={value.placeholder}
                      data-label={value.label}
                      data-size={value.size}
                      data-maximum_value={value.maximum_value}
                      data-minimum_value={value.minimum_value}
                      data-decimal_point={value.decimal_point}
                      data-label_width={value.label_width}
                      data-options={value.options}
                      data-required={value.required}
                      data-childrenable={value.childrenable}
                      className="droppable grid-stack-item"
                      gs-h={value.h}
                      gs-w={2}
                      style={{ display: "flex", width: "100%" }}
                    >
                      <div className="droppable-icon-container">
                        {value.icon}
                      </div>
                      <span>{value.label}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </aside>
      </Freeze>
      <Freeze freeze={tab.key !== "properties"}>
        <Properties
          showRemoveFromView={showRemoveFromView}
          showDeleteField={showDeleteField}
        />
      </Freeze>
    </div>
  );
}