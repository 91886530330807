import React from 'react';
//material UI
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Fade from '@material-ui/core/Fade';

import './flash.scss'


class Flash extends React.Component{  

    constructor(props) {
        super(props);
        this.state = {
            severity: '',
            container: ''
        }
        this.closeFlashMessage = this.closeFlashMessage.bind(this);
    }

    closeFlashMessage(){
        this.props.parentCallback();
    }
    
    render(){
        return (
            <div className={`flash-message-fieldset ${this.props.className ?? ''}`}>
                <div className={(this.props.container)}>                        
                    <Fade in={this.props.visible}>
                        <Alert
                            className   ={`flash-message ${this.props.alertClassName ?? ""}`}
                            style       ={this.props.alertStyle}
                            variant     ="filled" 
                            severity    ={(this.props.severity)}
                            icon        ={(this.props.severity === "error") ? <ClearIcon/> : <CheckCircleIcon fontSize="large"/>}     
                            action      ={
                                        <IconButton 
                                            aria-label="close"
                                            onClick={this.closeFlashMessage}>
                                            <div className="line"/>
                                            <CloseIcon/>
                                        </IconButton>}>
                            {this.props.message}
                        </Alert>
                    </Fade>
                </div>
            </div>
        )
    }
}


export default Flash;


export const ClearIcon = () => {
    //import custom icon
    return <div className="icon-error-icon" />
}

export const TickIcon = () => {
    return <div className="icon-tick-white" />
}